import React from 'react'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import { makeStyles } from '@material-ui/core/styles';
import { fullMobileWidth, boldCenteredText } from '../styles/helpers'
import SposiLoading from '../common_components/SposiLoading'




const Refresh = (props) => {

    return (
        <SposiLoading show={true}/>
    );
}

export default Refresh