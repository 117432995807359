import React, { useState, useContext, useEffect } from 'react'
import Topbar from '../common_components/Topbar'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { SharedContext } from '../utils/Session';
import ActionsTableMerchant from '../common_components/ActionsTableMerchant';
import { setUserInfo, getUserInfo } from '../utils/utils';
import { ROUTES } from '../constants';
import { centeredText, buttonStyle } from '../styles/helpers';
import Button from '@material-ui/core/Button'
import { withBmarkenAPI } from '../utils/Api';
import { withFirebase } from '../utils/Firebase';
import BottombarMerchant from '../common_components/BottombarMerchant'

const Statistiche = (props) => {

    return (
        <div>
			<Topbar history={props.history} goBack={() => props.history.push(ROUTES.base)}/>
			<div style={centeredText}>
            <div>
                <Button onClick={() => props.history.push({ pathname: ROUTES.stats_users})} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Statistiche utenti
                </Button>
                <br/>
                <Button onClick={() => props.history.push({ pathname: ROUTES.stats_prizes})} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Statistiche premi
                </Button>
                <br/>
                <Button onClick={() => props.history.push({ pathname: ROUTES.stats_merchants})} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Statistiche operatori
                </Button>
            </div>
            
			</div>
            <BottombarMerchant from={props.location.pathname} />
		</div>
    )
}

export default  withFirebase(withBmarkenAPI(Statistiche))