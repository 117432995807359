import React from 'react'
import Button from '@material-ui/core/Button';
import { fullMobileWidth } from '../styles/helpers'
import SposiIcon from '../common_components/SposiIcon'

const ActionButtons = (props) => {

    const visitBtnColor = props.selected === "VISIT" ? "#dc005a" : "#8b8b8b"
    const purchaseBtnColor = props.selected === "PURCHASE" ? "#dc005a" : "#8b8b8b"
    const reviewBtnColor = props.selected === "REVIEW" ? "#dc005a" : "#8b8b8b"

    const commonButtonStyle = {
        textTransform: 'inherit',
        color: '#fff',
        width: '100%',
        marginTop: '5px',
        justifyContent: 'flex-start'
    }

    const iconTextStyle = {
        marginLeft: '4px'
    }

    return(
        <div style={fullMobileWidth}>
            <div>
                <Button variant="contained" style={{...commonButtonStyle, backgroundColor: visitBtnColor}} onClick={() => props.onClickCallback("VISIT")}>
                    <SposiIcon iconName='visita' active={props.selected === "VISIT"}/>
                    <div style={iconTextStyle}>
                        Ottieni fino a {props.maxVisitReward} punti con una VISITA
                    </div>
                </Button>
            </div>
            <div>
                <Button variant="contained" style={{...commonButtonStyle, backgroundColor: purchaseBtnColor}} onClick={() => props.onClickCallback("PURCHASE")}>
                    <SposiIcon iconName='acquisto' active={props.selected === "PURCHASE"} />
                    <div style={iconTextStyle}>
                        Ottieni fino a {props.maxPurcahseReward} punti con un ACQUISTO
                    </div>
                </Button>
            </div>
            <div>
                
                <Button variant="contained" style={{...commonButtonStyle, backgroundColor: reviewBtnColor}} onClick={() => props.onClickCallback("REVIEW")}>
                    <SposiIcon iconName='recensione' active={props.selected === "REVIEW"} />
                    <div style={iconTextStyle}>
                        Ottieni fino a {props.maxReviewReward} punti con una RECENSIONE
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default ActionButtons;