import React from "react";

import { makeStyles } from "@material-ui/core";

import Button from "./Button";

const useStyles = makeStyles({
  card: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    border: "1px solid white",
    borderRadius: 10,
    color: "#fff",
    fontSize: 16,
    margin: "20px auto",
    background: "white",
    overflow: "hidden",
    maxWidth: 500,
  },
  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    minHeight: 150,
    padding: 10,
    position: "relative",
  },
  contentHeight: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    minHeight: "300px",
    padding: 10,
    position: "relative",
  },
  title: {
    background: "#e30059",
    padding: 2,
    fontSize: 14,
  },
  overlay: {
    background: "black",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0.25,
  },
});

export default function Card({ bg, title, contentHeight, children }) {
  const classes = useStyles();

  return (
    <div
      className={classes.card}
      style={
        bg
          ? {
              backgroundImage: `url(${bg})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }
          : {}
      }
    >
      {title && <div className={classes.title}>{title}</div>}
      <div className={!contentHeight ? classes.content : classes.contentHeight}>
        {bg && <div className={classes.overlay}></div>}

        {children}
      </div>
    </div>
  );
}
