export const fullMobileWidth = {
  margin: "auto",
  width: "97%",
  maxWidth: "600px",
  marginBottom: "8px",
};
export const boldCenteredText = {
  textAlign: "center",
  color: "#8b8b8b",
  fontWeight: "bold",
  fontSize: "14px",
  padding: "5px",
};
export const buttonStyle = { width: "150px", fontWeight: "bold" };
export const buttonStyleFull = { width: "auto", fontWeight: "bold" };

export const centeredText = {
  textAlign: "center",
  fontSize: "19px",
  padding: "5px",
};
export const centeredBoldParagraph = {
  textAlign: "center",
  fontWeight: "bold",
  fontSize: "19px",
  padding: "5px",
  marginTop: "2px",
  marginBottom: "2px",
  lineHeight: "24px",
};
export const grayParagraph = {
  textAlign: "center",
  color: "#8b8b8b",
  fontSize: "19px",
  padding: "5px",
  marginTop: "2px",
  marginBottom: "2px",
};
