import React from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import LogoHeader from "../common_components/LogoHeader";
import { fullMobileWidth, boldCenteredText } from "../styles/helpers";
import SposiIcon from "../common_components/SposiIcon";
import BackGroundComponent from "../common_components/BackGroundComponent";
import Grid from "@material-ui/core/Grid";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import {
  centeredText,
  centeredBoldParagraph,
  buttonStyle,
} from "../styles/helpers";
import QRCodeContainer from "../common_components/QRCodeContainer";
import Button from "@material-ui/core/Button";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { SharedContext } from "../utils/Session";
import {
  translateError,
  getDistanceFromLatLonInKm,
  getIDsFromCode,
  parseBmeIntToInt,
  getUserInfo,
} from "../utils/utils";
import moment from "moment";
import QrReader from "react-qr-reader";
import { ROUTES } from "../constants";
import PrenotazioneAppuntamenti from "./PrenotazioneAppuntamenti";
import PrenotazioneEventi from "./PrenotazioneEventi";
import AgendaAppuntamenti from "./AgendaAppuntamenti";
import Card from "../common_components/Card";
import { Link } from "react-router-dom";
var QRCode = require("qrcode.react");

function IconLink({ id, children, onClick }) {
  return (
    <Link
      style={{
        marginTop: "12px",
        textDecoration: "none",
        color: "#666",
        fontSize: 15,
      }}
    >
      <SposiIcon
        forceHeight="70px"
        iconName={id}
        active={true}
        onClick={() => onClick()}
      />
      <div>{children}</div>
    </Link>
  );
}

function expandFair(fair) {
  try {
    const cs = JSON.parse(fair.custom_fields);
    fair.urlHome = cs?.urlHome;
    fair.startingDate = dateToInt(cs?.startingDate);
  } catch (e) {}
  return fair;
}
function sortFair(a, b) {
  return a.startingDate - b.startingDate;
}
function dateToInt(dateString) {
  if (!dateString) return 0;
  var parts = dateString.split("-");
  var day = parts[0];
  var month = parts[1];
  var year = parts[2];
  return parseInt(year + month + day, 10);
}

class NewFiera extends React.PureComponent {
  static contextType = SharedContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activeView: "main",
      qrcode: "",
      selectedFair: null,
      activeFairs: [],
      scanEnabled: true,
      reward: 20,
      rewardingAction: null,
      reward: 0,
      showFairWarning: false,
    };
  }

  showGPSError = (error) => {
    this.setState({ loading: false });
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.context.dispatchMessage(
          "Permesso di accesso alle coordinate GPS negate dallo user",
          "error"
        );
        break;
      case error.POSITION_UNAVAILABLE:
        this.context.dispatchMessage(
          "Impossibile recuperare le coordinate GPS",
          "error"
        );
        break;
      case error.TIMEOUT:
        this.context.dispatchMessage(
          "Richiesta di coordinate GPS non andata a buon fine, ritenta",
          "error"
        );
        break;
      case error.UNKNOWN_ERROR:
        this.context.dispatchMessage(
          "Errore nel recupero delle coordinate GPS, ritenta",
          "error"
        );
        break;
    }
  };

  /*checkGPSBeforeScanner = (position) => {
    let cf;
    const fair = this.state.selectedFair;
    if (fair.custom_fields !== "") {
      cf = JSON.parse(fair.custom_fields);
    } else {
      this.context.dispatchMessage(
        "La fiera non ha associato delle coordinate, contattare l'assistenza per risolvere il problema",
        "error"
      );
      return;
    }

    //console.log(cf, " -- ", position.coords)

    const distance = getDistanceFromLatLonInKm(
      cf.lat,
      cf.lng,
      position.coords.latitude,
      position.coords.longitude
    );
    //console.log("Distance: ", distance)
    if (distance > 4) {
      this.context.dispatchMessage(
        "Questa funzione è disponibile solo in prossimità della fiera",
        "error"
      );
      this.setState({ loading: false });
      return;
    }

    this.setState({ activeView: "scanner", loading: false });
  };*/

  componentDidMount() {
    this.setState({ loading: true });
    this.props.bmarkenAPI
      .getContentsPublic("FAIR")
      .then((f) => {
        this.setState({ loading: false });
        if (f !== null) {
          let factive = f.filter((item) => item.active);
          let finactive = f.filter((item) => !item.active);
          factive = factive.map((item) => expandFair(item)).sort(sortFair);
          finactive = finactive.map((item) => expandFair(item)).sort(sortFair);
          //const l = factive.length;
          //if (l != 1) {
          //mostro lista fiere
          this.setState({
            activeFairs: factive,
            inactiveFairs: finactive,
            activeView: "fairList",
          });
          //} else {
          //apro la pagina dettaglio dell'unica fiera
          //console.log("Una sola fiera ", f[0]);

          //this.showFairPage(factive[0], factive);
          //}

          /*//WORKAROUND - seleziono l'unica fiera disponibile
                const l = f.length
                let selected = null
                for(let i=0;i<l;i++){
                    if(f[i].id === "5eb48009-c735-405f-a532-f968e2317853"){
                        selected = f[i]
                        console.log("selected: ",selected)
                        break;
                    }
                }
                const userID = this.props.bmarkenAPI.getTokenInfo().bme_id
                const qrcode = selected != null ? "ACCESS_FAIR_"+selected.id+"_"+userID : "NO_FAIR_FOUND"

                this.setState({fairs: f, selectedFair: selected, qrcode: qrcode})
                if(selected == null){
                    this.context.dispatchMessage("Fiera non trovata", 'error')
                }
                //FINE WORKAROUND*/
        } else {
          this.setState({ activeFairs: [] });
          //this.context.dispatchMessage("Non sono presenti fiere disponibili", 'error')
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  }

  checkFairDate = (fair) => {
    if (fair.custom_fields !== "") {
      const cf = JSON.parse(fair.custom_fields);
      if (cf.startingDate !== undefined) {
        let start = moment(cf.startingDate, "DD-MM-YYYY");
        let now = moment();
        this.setState({ showFairWarning: now.isBefore(start) });
      } else {
        this.context.dispatchMessage(
          "La fiera non ha una data di inizio, contattare l'assistenza",
          "error"
        );
      }
    } else {
      this.context.dispatchMessage(
        "La fiera non ha una data di inizio, contattare l'assistenza",
        "error"
      );
    }
  };

  showFairPage = (selected, fairs) => {
    const userID = this.props.bmarkenAPI.getTokenInfo().bme_id;
    const qrcode =
      selected != null
        ? "ACCESS_FAIR_" + selected.id + "_" + userID
        : "NO_FAIR_FOUND";

    this.checkFairDate(selected);

    this.setState({
      selectedFair: selected,
      activeFairs: fairs,
      qrcode: qrcode,
      activeView: "fair",
    });
  };

  goToPage = (value) => {
    this.setState({ activeView: value });
  };

  showPass = () => {
    this.setState({ activeView: "pass" });
  };

  showReservations = () => {
    this.setState({ activeView: "reservations" });
  };

  showEvents = () => {
    this.setState({ activeView: "events" });
  };

  showAgenda = () => {
    this.setState({ activeView: "agenda" });
  };

  openScanner = () => {
    if (this.state.showFairWarning) {
      this.context.dispatchMessage(
        "Questa funzione non è ancora abilitata",
        "error"
      );
      return;
    }

    /* rimosso check coordinate
        if (navigator.geolocation) {
            this.setState({
                loading:true
            })
            navigator.geolocation.getCurrentPosition(this.checkGPSBeforeScanner, this.showGPSError);
        } else {
            this.context.dispatchMessage("Impossibile recuperare le coordinate GPS, il browser non supporta questa funziona. Cambiare browser e riprovare", 'error')
            return
        }*/

    this.setState({ activeView: "scanner", loading: false });

    //DEBUG: poi questa riga la devo togliere perchè la logica è in checkGPSBeforeScanner
    //this.setState({activeView:"scanner",loading: false})
  };

  handleError = () => {
    this.context.dispatchMessage(
      "Scanner in errore. Controllare che il browser in uso non stia bloccando la fotocamera e ritentare",
      "error"
    );
  };

  handleScan = (code) => {
        if (!this.state.scanEnabled) return;

    if (code) {
      this.setState({
        scanEnabled: false,
        loading: true,
      });

      //scansiono solo qrcode di tipo "MERCHANT_<id_merchant>" e creo interazioni di tipo VISITA_FIERA qui
      const ids = getIDsFromCode(code, "MERCHANT_", 1);
      if (ids === null) {
        //errore codice scannerizzato non è un id valido
        this.setState({
          scanEnabled: true,
          loading: false,
        });
        this.context.dispatchMessage(
          "Il codice scannerizzato non appartiene ad un negozio",
          "error"
        );
        return;
      }

      const idMerchant = ids[0];
      this.props.bmarkenAPI
        .createRewardingAction(
          "FAIR",
          idMerchant,
          "",
          0,
          "",
          moment().format("YYYY-MM-DD"),
          this.state.selectedFair.id,
          () => this.props.firebase.doRefreshToken()
        )
        .then((ra) => {
          this.setState({
            //loading: false,
            rewardingAction: ra,
            //scanEnabled: true,
            //activeView: 'result',
            reward: ra.reward,
          });

          return this.props.bmarkenAPI.getMerchant(idMerchant, () =>
            this.props.firebase.doRefreshToken()
          );
        })
        .then((m) => {
          this.setState({
            loading: false,
            merchantVisited: m != null ? m.name : "",
            scanEnabled: true,
            activeView: "result",
          });
        })
        .catch((error) => {
          this.setState({ loading: false, scanEnabled: true });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  };

  openLink = (url) => {
    if (url == undefined || url == "") {
      this.context.dispatchMessage(
        "La risorsa sarà disponibile a breve",
        "error"
      );
      return;
    }

    window.open(url, "_blank");
  };

  viewController = (activeView, isLogged) => {
    switch (activeView) {
      case "pass":
        return this.passView();
      case "agenda":
        return this.agendaView();
      case "reservations":
        return this.reservationView();
      case "events":
        return this.eventsView();
      case "fair":
        return this.fairView();
      case "result":
        return this.resultView();
      case "fairList":
        return this.fairListView(isLogged);
      case "scanner":
        return this.scannerView();
      default:
        return this.fairListView(isLogged);
    }
  };

  fairListView = (isLogged) => (
    <div>
      <Topbar
        history={this.props.history}
        from={this.props.path}
        goBack={() => this.props.history.push({ pathname: ROUTES.base })}
      />

      {this.state.activeFairs.map((f, i) => (
        <img
          onClick={() => {
            if (isLogged) {
              this.showFairPage(f, this.state.activeFairs);
            } else if (f.urlHome) {
              this.openLink(f.urlHome);
            }
          }}
          key={f.id}
          style={{
            marginBottom: "20px",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          alt="logoFiera"
          src={f.url}
        />
      ))}
      {this.state.inactiveFairs?.length ? (
        <>
          <p>Fiere concluse</p>
          {this.state.inactiveFairs?.map((f, i) => (
            <img
              onClick={() => {
                if (f.urlHome) this.openLink(f.urlHome);
              }}
              key={f.id}
              style={{
                marginBottom: "20px",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              alt="logoFiera"
              src={f.url}
            />
          ))}
        </>
      ) : null}
      {!this.state.activeFairs?.length &&
        !this.state.inactiveFairs?.length(
          <div style={{ textAlign: "center" }}>
            <LogoHeader />
            <div>
              Non sono ancora state programmate nuove fiere. Ricontrolla a breve
              per aggiornamenti
            </div>
            <Button
              style={{ marginTop: "50px", ...buttonStyle }}
              variant="outlined"
              color="primary"
              onClick={() => this.props.history.push({ pathname: ROUTES.base })}
            >
              Torna indietro
            </Button>
          </div>
        )}
    </div>
  );

  LabelDot = ({ text }) => {
    return (
      <>
        <div
          style={{
            backgroundColor: "#9CA1A1",
            height: 6,
            width: 6,
            borderRadius: 6,
            margin: "15px auto 0",
          }}
        ></div>
        <div
          style={{
            ...centeredBoldParagraph,
            fontSize: 14,
            color: "#9CA1A1",
            marginBottom: "1px",
          }}
        >
          {text}
        </div>
      </>
    );
  };

  fairView = () => {
    const fair = this.state.selectedFair;

    let cf = {
      urlMap: undefined,
      urlExpositors: undefined,
      urlDirection: undefined,
    };
    if (fair.custom_fields !== "") cf = JSON.parse(fair.custom_fields);

    return (
      <div style={fullMobileWidth}>
        <Topbar
          history={this.props.history}
          from={this.props.path}
          goBack={() => {
            this.state.activeFairs?.length != 1
              ? this.setState({ activeView: "fairListView " })
              : this.props.history.push({ pathname: ROUTES.base });
          }}
        />
        <div>
          <p
            style={{
              ...centeredBoldParagraph,
              color: "#dc005a",
              marginBottom: "2px",
            }}
          >
            Benvenuto a
          </p>
          {this.state.selectedFair != null ? (
            <img
              style={{
                marginBottom: "20px",
                maxWidth: "100%",
                maxHeight: "100%",
              }}
              alt="logoFiera"
              src={this.state.selectedFair.url}
            />
          ) : null}

          <p
            style={{
              ...centeredBoldParagraph,
              fontSize: 14,
              color: "#9CA1A1",
              marginBottom: "1px",
            }}
          >
            Segli una di queste funzioni
          </p>

          {this.state.showFairWarning ? (
            <p
              style={{
                ...centeredBoldParagraph,
                fontSize: 14,
                color: "#dc005a",
                marginBottom: "2px",
              }}
            >
              La fiera non è ancora cominciata. Alcune funzioni potrebbero non
              essere abilitate
            </p>
          ) : null}

          <Card>
            <div className="twoElementsGrid">
              <IconLink id="free_pass" onClick={() => this.showPass()}>
                Pass gratuito
              </IconLink>
              <IconLink id="visit_merch" onClick={() => this.openScanner()}>
                Punti visita espositore
              </IconLink>
              <IconLink
                id="reservations"
                onClick={() => this.showReservations()}
              >
                Appuntamento in fiera
              </IconLink>
              <IconLink id="event" onClick={() => this.showEvents()}>
                Prenota un evento
              </IconLink>
              <IconLink id="agenda" onClick={() => this.showAgenda()}>
                Agenda appuntamenti
              </IconLink>
              <IconLink
                id="merch_list"
                onClick={() => this.openLink(cf.urlExpositors)}
              >
                Elenco espositori
              </IconLink>
              <IconLink id="stand_map" onClick={() => this.openLink(cf.urlMap)}>
                Mappa degli stand
              </IconLink>
              <IconLink
                id="indications"
                onClick={() => this.openLink(cf.urlDirection)}
              >
                Come arrivare in fiera
              </IconLink>
            </div>
          </Card>

          {/*
            OLD BUTTON MENU
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            color="primary"
            variant="contained"
            onClick={() => this.showPass()}
          >
            Pass gratuito
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            color="primary"
            variant="contained"
            onClick={() => this.showAgenda()}
          >
            Agenda appuntamenti
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            color="primary"
            variant="contained"
            onClick={() => this.showReservations()}
          >
            Prenota un appuntamento
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            color="primary"
            variant="contained"
            onClick={() => this.showEvents()}
          >
            Prenota un evento
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            variant="outlined"
            color="primary"
            onClick={() => this.openScanner()}
          >
            Visita un espositore
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            variant="outlined"
            color="primary"
            onClick={() => this.openLink(cf.urlExpositors)}
          >
            Elenco espositori
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            variant="outlined"
            color="primary"
            onClick={() => this.openLink(cf.urlMap)}
          >
            Mappa della fiera
          </Button>
          <br />
          <Button
            style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
            variant="outlined"
            color="primary"
            onClick={() => this.openLink(cf.urlDirection)}
          >
            Come raggiungere la fiera
          </Button> */}
        </div>
      </div>
    );
  };

  passView = () => (
    <div>
      <Topbar
        history={this.props.history}
        from={this.props.path}
        goBack={() => this.setState({ activeView: "fair" })}
      />
      <div>
        {this.state.selectedFair != null ? (
          <img
            style={{
              marginBottom: "20px",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            alt="logoFiera"
            src={this.state.selectedFair.url}
          />
        ) : null}

        <p
          style={{
            ...centeredBoldParagraph,
            color: "#dc005a",
            marginBottom: "2px",
          }}
        >
          Segui queste istruzioni per accedere
          <br /> gratuitamente alla fiera
        </p>
        <div>
          <this.LabelDot
            text={
              <div>
                Ricorda nome utente e password <br />
                usati per la registrazione
              </div>
            }
          ></this.LabelDot>
        </div>
        <div>
          <this.LabelDot
            text={
              <div>
                Al momento dell’ingresso in fiera riapri
                <br />
                il sito ed entra in questa pagina
              </div>
            }
          ></this.LabelDot>
        </div>
        <div>
          <this.LabelDot
            text={
              <div>
                Mostra questo codice alla reception
                <br />
                per accedere gratuitamente
              </div>
            }
          ></this.LabelDot>
        </div>
        <br />
        <QRCode
          value={this.state.qrcode}
          size={200}
          bgColor="white"
          fgColor="black"
        />
        <br />
        <br />
        <p
          style={{
            ...centeredBoldParagraph,
            fontSize: 15,
            color: "#dc005a",
            marginBottom: "10px",
          }}
        >
          ATTENZIONE: NON FARE UNO SCREENSHOT <br />
          PERCHE’ IL CODICE NON VERRA’ <br /> RICONOSCIUTO DAL SISTEMA
        </p>
        <p
          style={{
            ...centeredBoldParagraph,
            fontSize: 14,
            color: "#9CA1A1",
            marginBottom: "10px",
          }}
        >
          Il pass di ingresso è valido per la coppia di futuri
          <br /> sposi e loro accompagnatori: è sufficiente
          <br /> una sola registrazione per tutto il gruppo
        </p>
        <p
          style={{
            ...centeredBoldParagraph,
            fontSize: 14,
            color: "#9CA1A1",
            marginBottom: "10px",
          }}
        >
          Una volta registrato potrai guadagnare
          <br />
          punti per la visita degli espositori
        </p>
        <p
          style={{
            ...centeredBoldParagraph,
            fontSize: 14,
            color: "#9CA1A1",
            marginBottom: "10px",
          }}
        >
          Ripeti questa procedura per tutte le fiere
          <br />
          che vorrai visitare in futuro:
          <br />
          non dovrai registrarti nuovamente.
        </p>
      </div>
    </div>
  );

  reservationView = () => (
    <PrenotazioneAppuntamenti
      selectedFair={this.state.selectedFair}
      goTo={(val) => this.goToPage(val)}
    />
  );

  eventsView = () => (
    <PrenotazioneEventi
      selectedFair={this.state.selectedFair}
      goTo={(val) => this.goToPage(val)}
    />
  );

  agendaView = () => (
    <AgendaAppuntamenti
      selectedFair={this.state.selectedFair}
      goTo={(val) => this.goToPage(val)}
    />
  );

  resultView = () => (
    <div className="main-content">
      <Topbar
        history={this.props.history}
        from={this.props.path}
        goBack={() => this.setState({ activeView: "fair" })}
      />
      <div>
        <div style={centeredText}>
          <div
            style={{
              ...centeredBoldParagraph,
              color: "#dc005a",
              marginBottom: "2px",
            }}
          >
            <div>Complimenti: hai ottenuto</div>
            <div className="rowTitlePoint">
              {parseBmeIntToInt(this.state.reward)} PUNTI
            </div>
            <div>per aver visitato</div>
            <div className="rowTitlePoint">{this.state.merchantVisited}</div>
          </div>

          <p
            style={{
              ...centeredBoldParagraph,
              fontSize: 14,
              color: "#9CA1A1",
              marginBottom: "1px",
            }}
          >
            Controlla i tuoi punti e guarda
            <br />
            quanto è vicino il tuo premio
          </p>
          <div>
            <Button
              onClick={() =>
                this.props.history.push({ pathname: ROUTES.punti })
              }
              style={{
                ...buttonStyle,
                marginTop: "25px",
                marginBottom: "20px",
              }}
              variant="outlined"
              color="primary"
            >
              Bilancio punti
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  scannerView = () => (
    <div>
      <Topbar
        history={this.props.history}
        from={this.props.path}
        goBack={() => this.setState({ activeView: "fair" })}
      />
      <div>
        <div>
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "100%" }}
          />
        </div>
        <p style={{ ...centeredText, fontSize: 18, color: "#9CA1A1" }}>
          Inquadra il QR code presente nello stand
          <br />
          che ti interessa e che hai visitato per
        </p>
        <p style={{ ...centeredText, color: "#dc005a", fontSize: 22 }}>
          ottenere 20 punti
        </p>
        <p style={{ ...centeredText, fontSize: 18, color: "#9CA1A1" }}>
          Puoi registrare fino a un massimo di 30
          <br />
          visite tra i tuoi espositori preferiti
        </p>
      </div>
    </div>
  );

  render() {
    const isLogged = getUserInfo()?.user_id;
    //console.log("................", this.state.activeFairs, this.state.inactiveFairs)
    return (
      <div style={centeredText}>
        {this.viewController(this.state.activeView, isLogged)}
        <LoadingOverlay show={this.state.loading} />
        {isLogged &&
        <Bottombar from={this.props.location.pathname} />
        }
      </div>
    );
  }
}

export default withBmarkenAPI(withFirebase(NewFiera));
