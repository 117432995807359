import Accesso from "./Accesso";
import Acquisto from "./Acquisto";
import Convenzioni from "./Convenzioni";
import Faq from "./Faq";
import Fiera from "./Fiera";
import Fornitori from "./Fornitori";
import FornitoriPublic from "./FornitoriPublic";
import GestioneFiere from "./GestioneFiere";
import Home from "./Home";
import HomeOperatore from "./HomeOperatore";
import HomeSuperadmin from "./HomeSuperadmin";
import Interazioni from "./Interazioni";
import Login from "./Login";
import LoginOperatori from "./LoginOperatori";
import Negozio from "./Negozio";
import Notfound from "./Notfound";
import Premi from "./Premi";
import Profilo from "./Profilo";
import Punti from "./Punti";
import Recensione from "./Recensione";
import Refresh from "./Refresh";
import Registrazione from "./Registrazione";
import Regolamento from "./Regolamento";
import ResetPassword from "./ResetPassword";
import Ricerca from "./Ricerca";
import Scanner from "./Scanner";
import Visita from "./Visita";
import InfoOperatori from "./InfoOperatori";
import Redirect from "./Redirect";
import Privacy from "./Privacy";
import TerminiServizio from "./TerminiServizio";
import GestioneRecensioni from "./GestioneRecensioni";
import Assistenza from "./Assistenza";
import AssistenzaMerchant from "./AssistenzaMerchant";
import ComeFunziona from "./ComeFunziona";
import Statistiche from "./Statistiche";
import StatisticheUtenti from "./StatisticheUtenti";
import StatisticheOperatori from "./StatisticheOperatori";
import StatistichePremi from "./StatistichePremi";
import NewFiera from "./NewFiera";
import EmailContact from "./EmailContact";

export default {
  Accesso,
  Acquisto,
  Convenzioni,
  Faq,
  Fiera,
  Fornitori,
  FornitoriPublic,
  GestioneFiere,
  Home,
  HomeOperatore,
  HomeSuperadmin,
  Interazioni,
  Login,
  LoginOperatori,
  Negozio,
  Notfound,
  Premi,
  Profilo,
  Punti,
  Recensione,
  Refresh,
  Registrazione,
  Regolamento,
  ResetPassword,
  Ricerca,
  Scanner,
  Visita,
  InfoOperatori,
  GestioneRecensioni,
  Redirect,
  TerminiServizio,
  Privacy,
  Assistenza,
  AssistenzaMerchant,
  ComeFunziona,
  Statistiche,
  StatisticheUtenti,
  StatisticheOperatori,
  StatistichePremi,
  NewFiera,
  EmailContact,
};
