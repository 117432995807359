import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ROUTES } from '../constants'
import SposiIcon from './SposiIcon'

const bottomRoutes = [
  { path: ROUTES.visita, text: 'visita'},
  { path: ROUTES.acquisto, text: 'acquisto'},
  { path: ROUTES.recensione, text: 'recensione'},
  { path: ROUTES.punti, text: 'punti'},
  { path: ROUTES.premi, text: 'premi'},
]

const Bottombar = (props) => [
  <div key={'bottombar-separator'} style={{width: '100%', height: '64px', marginTop: '25px'}}></div>,
  <AppBar key={'bottombar'} position="fixed" color="primary" style={{ top: 'auto', bottom: 0, background: '#f2f2f2', paddingTop: '3px', paddingBottom:'5px', zIndex: '2' }}>
    <Toolbar style={{ justifyContent: 'space-evenly'}}>
      {bottomRoutes.map((el, index) => (
        <Link key={index} to={el.path} style={{textDecoration: 'none', width: '60px'}}>
          <div style={{ flexDirection: 'column', textAlign: 'center'}}>
            <SposiIcon active={el.path === props.from} iconName={el.text} />
            <div style={{textAlign: 'center', color: '#707173', fontSize: '11px'}}>{el.text}</div>
          </div>
        </Link>
      ))}
    </Toolbar>
  </AppBar>
]

export default Bottombar;