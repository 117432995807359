import amazon from './premi-amazon.jpg'
import auto_cerimonia from './Omaggio_AutoCerimonia.jpg'
import blue_torino from './Omaggio_BlueTorino.jpg'
import cera_una_volta from './Omaggio_CeraUnaVolta.jpg'
import maggiolone from './Omaggio_Maggiolone.jpg'
import massaggio_corpo from './Omaggio_MassaggioCorpo.jpg'
import massaggio_viso from './Omaggio_MassaggioViso.jpg'
import servizio_foto from './Omaggio_servizio-foto.jpg'
import servizio_foto_2 from './Omaggio_servizio-foto2.jpg'
import prize_default from './premi-default.png'

export default { amazon, prize_default, auto_cerimonia, blue_torino, cera_una_volta, maggiolone, massaggio_corpo, massaggio_viso, servizio_foto, servizio_foto_2}