import React from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import { makeStyles } from "@material-ui/core/styles";
import { fullMobileWidth, boldCenteredText } from "../styles/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Regolamento = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Topbar history={props.history} from={props.path} />
      <div>
        <div
          style={{
            ...boldCenteredText,
            ...fullMobileWidth,
            fontWeight: "normal",
            color: "#DC005A",
          }}
        >
          <div>REGOLAMENTO</div>
        </div>

        <div className={classes.root}>
          <div className="termini-servizio-text">
            <p>
              <span className="termini-servizio-subtitle"> </span>
              <strong>OPERAZIONE A PREMI DENOMINATA:</strong>
              <strong>
                <br />
                SPOSI CLUB: ORGANIZZA LE TUE NOZZE E VINCI!
              </strong>
            </p>
            <p>
              La Emmebie Srl con sede legale in Torino (TO Corso Moncalieri
              506/28 - P.IVA 08325390014 al fine di incentivare la vendita e la
              conoscenza dei prodotti da essa commercializzati e  distribuiti,
              intende indire la sotto descritta operazione a premi  denominata:
            </p>
            <p>
              <strong>Sposi Club: organizza le tue nozze e vinci!</strong>
            </p>
            <p>
              <strong>Durata</strong>: Dal 05 ottobre 2020 al 30 settembre 2021
              (termine ultima richiesta 30 settembre 2021 ore 24.00)
              <br />
              <strong>Destinatari</strong>: futuri sposi che celebreranno in
              Italia le loro nozze, con rito religioso o civile, tra il
              5/10/2020 ed il 31/12/2022.
              <br />
              <strong>Ambito territoriale</strong>: Italia
              <br />
              <strong>Pubblicità</strong>: L&rsquo;operazione a premi verrà
              pubblicizzata sul web, sui social,  in fiere di settore, sulla
              carta stampata. La Società promotrice si riserva comunque di
              utilizzare ogni altro mezzo di comunicazione che appaia idoneo a
              portare a conoscenza il contenuto della manifestazione a premio ai
              destinatari della stessa.
              <br />
              <strong>Modalità di svolgimento</strong>: i destinatari sopra
              indicati attraverso il sito www.sposiclub.it , svolgendo le 
              azioni previste quali visitare, comprare, recensire  le aziende e
              le fiere convenzionate a sposi club, potranno raccogliere dei
              punti che, una volta raggiunti i  target previsti,  consentiranno
              lo scaricamento immediato di un &ldquo;voucher-premio&rdquo; a
              scelta. Questo voucher-premio consentirà il riscatto del prodotto
              o servizio ad esso connesso, presso le aziende che lo
              consegneranno/erogheranno materialmente.
            </p>
            <p>
              Si precisa che saranno considerati validi esclusivamente i
              &ldquo;voucher-premio&rdquo; ottenuti entro le ore 24.00 del 30
              Settembre 2021. 
            </p>
            <p>
              Il premio, dovrà essere riscattato entro 90 giorni
              dall&rsquo;ottenimento del &ldquo;voucher-premio&rdquo; e comunque
              non oltre il 31 dicembre 2021. Dovrà essere richiesto e ottenuto,
              nei modi e alle condizioni indicati sul voucher stesso e nel
              presente regolamento, in base alle procedure indicate dai relativi
              fornitori. 
            </p>
            <p>
              Sarà facoltà della società promotrice la possibilità di richiedere
              al partecipante di esibire la documentazione a dimostrazione della
              veridicità delle attività da questi segnalate per
              l&rsquo;ottenimento dei punti come, ad esempio, fattura o ricevuta
              di acquisto presso le aziende convenzionate a Sposi Club. In caso
              di detti controlli, qualora la Promotrice riscontrasse
              comportamento non consono a quanto sopra richiesto, il
              voucher-premio non verrà riconosciuto e verrà reso nullo ed
              inesigibile. 
            </p>
            <p>
              <strong>Premi e relativo valore IVA esclusa:</strong>
            </p>
            <p>
              Si prevede di distribuire un totale montepremi di Euro 2.500,00
              iva esclusa, salvo conguaglio a fine manifestazione. La previsione
              di distribuzione dei premi deriva da un&rsquo;ipotesi di utilizzo
              del sito sposiclub.it e dalla particolare tipologia dei
              destinatari dell&rsquo;operazione a premi (futuri sposi).
            </p>
            <p>
              <strong>Premi previsti:</strong>
            </p>
            <p>
              •           voucher del valore di Euro 25 ottenere un Buono
              Amazon  
            </p>
            <p>
              <strong>Note Particolari:       </strong> 
            </p>
            <p>
              La società si riserva di sostituire il voucher premio indicato con
              altro di analogo valore o di pari caratteristiche in caso di
              esaurimento o impossibilità di reperire lo stesso sul mercato.
            </p>
            <p>
              La partecipazione alla presente manifestazione a premi comporta
              per il partecipante l&rsquo;accettazione incondizionata e totale
              delle regole e delle clausole contenute nel presente regolamento
              senza limitazione alcuna. La partecipazione a questa
              manifestazione a premi è libera e completamente gratuita. 
            </p>
            <p>
              La Promotrice potrà revocare o modificare le modalità di
              esecuzione della presente operazione a premi per giusta causa, ai
              sensi e nei termini di cui l&rsquo;art. 1990 cod. civ. dandone
              preventivamente comunicazione ai promissari nella stessa forma
              della omessa o in forma equivalente.
            </p>
            <p>
              La promotrice non si assume alcuna responsabilità relativamente
              alla documentazione inviata per la richiesta del premio non
              pervenuta, o pervenuta fuori tempo, per cause ad essa non
              imputabili. 
            </p>
            <p>
              Il regolamento completo sarà disponibile sul sito internet
              www.sposiclub.it  
            </p>
            <p>
              La Promotrice dichiara di rendere l&rsquo;IVA indetraibile sulle
              fatture di acquisto e nel caso non fosse possibile effettuare il
              versamento dell&rsquo;Imposta Sostitutiva del 20%. 
            </p>
            <p>
              <strong>Trattamento dei dati personali: </strong>
            </p>
            <p>
              Ai sensi del D.Lgs. 196/2003, La informiamo che l&rsquo;utente,
              cliccando all&rsquo;atto dell&rsquo;iscrizione sulla specifica
              casella,  dichiara il proprio consenso al trattamento dei dati
              personali per finalità di marketing dirette da parte del Circuito,
              o degli esercenti a cui appartengono le promo e gli strumenti di
              fidelizzazione utilizzati, o da terze parti.
            </p>
            <p>
               Titolare del trattamento è la società Emmebie srl. Avente sede
              legale in Corso Moncalieri 506/28 a Torino – Tel. 011.6610626-636.
              Luogo di trattamento dei dati connessi ai servizi editoriali e web
              è la predetta sede della società Emmebie srl. Il trattamento è
              curato da personale incaricato, oppure da eventuali incaricati di
              occasionali operazioni di manutenzione del sito. Responsabile del
              trattamento è il Sig. Mincica Giuseppe. Responsabile della
              Protezione Dati (RPD) è il Sig Enrico Collino.
            </p>
            <p>
               L&rsquo;utente ha il diritto di accedere ai suoi dati, per
              chiederne la modifica o la cancellazione o opporsi in tutto o in
              parte, al loro trattamento a norma dell&rsquo;art. 7 del Dls.
              196/2003. Per esercitare i suoi diritti potrà rivolgersi scrivendo
              alla Società Emmebie srl ai dati indicati.
            </p>
            <p>
              Per qualsiasi controversia in ordine alla presente manifestazione
              a premi, della quale non si è investito il Ministero dello
              Sviluppo Economico, sarà competente il Foro di Torino.
            </p>
            <p>
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Regolamento;
