import React from 'react'
import { centeredBoldParagraph, buttonStyle } from '../styles/helpers'
import Button from '@material-ui/core/Button'

var QRCode = require('qrcode.react');

const QRCodePrize = (props) => {

    return(
        <div>
            <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>
                Per utilizzare il buono fai leggere questo codice dall'app del fornitore
            </p>
            <br/>    
            <QRCode
                value={props.qrcode}
                size={200}
                bgColor='white'
                fgColor='black'/>
            <br/>
            Il codice è scaduto scaduto? 
            <div>
                <Button onClick={() => props.onRefreshCode()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Refresh
                </Button>
            </div>

        </div>
    )
}

export default QRCodePrize;