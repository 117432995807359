import React, { useContext, useState } from "react";
import Topbar from "../common_components/Topbar";
import { withBmarkenAPI } from "../utils/Api";
import { withFirebase } from "../utils/Firebase";
import SelectFromOptions from "../common_components/SelectFromOptions";
import {
  Button,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import MerchantList from "../common_components/MerchantList";
import { fullMobileWidth } from "../styles/helpers";
import moment from "moment";
import { translateError } from "../utils/utils";
import { SharedContext } from "../utils/Session";
import { Context } from "../utils/Session/sharedContext";

const byDate = (a, b) => new Date(a.day) - new Date(b.day);

const PrenotazioneAppuntamenti = (props) => {
  const history = useHistory();
  const { dispatchMessage } = useContext(SharedContext);
  const { categories } = useContext(Context);

  const [view, setView] = useState("category");
  const [date, setDate] = React.useState("");
  const [time, setTime] = React.useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  //const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [items, setItems] = useState([]);
  const [confs, setConfs] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [notes, setNotes] = useState("");

  const handleChange = (event) => {
    setDate(event.target.value);
  };

  const searchMerchants = () => {
    if (!category) {
      dispatchMessage("Occorre selezionare una categoria", "error");
      return;
    }
    if (!props?.selectedFair?.id) return;
    setSubmitLoading(true);

    props.bmarkenAPI
      .searchBusinessesByContentPublic(props.selectedFair.id, category.id)
      .then((resp) => {
        if (!resp.length) dispatchMessage("Nessun espositore trovato");
        else {
          setItems(resp);
          setView("merchant");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const handleSelect = (merch) => {
    if (!props?.selectedFair?.id) return;
    setSelectedMerchant(merch);
    setSubmitLoading(true);

    props.bmarkenAPI
      .getBusinessContentSlots(merch.id, props.selectedFair.id, () =>
        props.firebase.doRefreshToken()
      )
      .then((resp) => {
        if (!resp.length) dispatchMessage("Nessuna configurazione trovata");
        else {
          setConfs(resp);
          setView("period");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const submitReservation = (e) => {
    e.preventDefault();
    if (!props?.selectedFair?.id) return;
    setSubmitLoading(true);

    props.bmarkenAPI
      .postBusinessEcomOrders(
        selectedMerchant.id,
        props.selectedFair.id,
        date,
        time.inner_slot_id,
        notes,
        () => this.props.firebase.doRefreshToken()
      )
      .then((resp) => {
        console.log(resp);
        props.goTo("agenda");
        dispatchMessage(
          `Hai effettuato un appuntamento con ${
            selectedMerchant.name
          } il giorno ${date
            .split("-")
            .reverse()
            .join("-")} alle ore ${time.start.substring(
            0,
            2
          )}:${time.start.substring(2)}`,
          "info"
        );
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  return (
    <div style={fullMobileWidth}>
      <div>
        <Topbar history={history} goBack={() => props.goTo("fair")} />
      </div>
      <div>
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          alt="logoFiera"
          src={props.selectedFair.url}
        />
      </div>
      {view === "category" && (
        <React.Fragment>
          <p>Cosa cerchi in fiera?</p>
          <div>
            <SelectFromOptions
              value={category.id}
              placeholder={"Seleziona una categoria"}
              label={"Categoria"}
              onChange={(e) => setCategory(e)}
              options={categories}
            />

            <Button
              style={{ marginTop: "15px", width: "300px", fontWeight: "bold" }}
              color="primary"
              variant="contained"
              onClick={searchMerchants}
            >
              Visualizza gli espositori
            </Button>
          </div>
        </React.Fragment>
      )}
      {view === "merchant" && (
        <div style={fullMobileWidth}>
          <p>Con chi vuoi fissare un appuntamento?</p>
          <MerchantList
            button={true}
            items={items}
            onClick={handleSelect}
            //list
          />
        </div>
      )}
      {view === "period" && (
        <div style={fullMobileWidth}>
          <p>
            Quando vuoi l’appuntamento? <br /> Indica giorno e orario di tua
            preferenza.
          </p>
          <form onSubmit={submitReservation}>
            <FormControl component="fieldset" required>
              <p>Seleziona un giorno</p>
              <RadioGroup
                aria-label="date"
                name="date"
                value={date}
                onChange={handleChange}
                row
              >
                {confs.sort(byDate).map((date) => (
                  <FormControlLabel
                    value={moment(date.day).format("YYYY-MM-DD")}
                    control={<Radio />}
                    label={moment(date.day).format("DD-MM-YYYY")}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {date && (
              <React.Fragment>
                <p>Seleziona un orario</p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fill, minmax(80px, 1fr))",
                    gridGap: "5px",
                  }}
                >
                  {confs
                    .find((c) => c.day === date)
                    .slots.map((t) => (
                      <Button
                        style={{ marginTop: 10 }}
                        onClick={() => setTime(t)}
                        color="primary"
                        variant={
                          time.start === t.start ? "contained" : "outlined"
                        }
                      >
                        {`${t.start.substring(0, 2)}:${t.start.substring(2)}`}
                      </Button>
                    ))}
                </div>
                <p>Segnala una tua richiesta</p>
                <TextField
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </React.Fragment>
            )}
            {time && (
              <Box mt={5}>
                <Button type="submit" color="primary" variant="contained">
                  Conferma l'appuntamento
                </Button>
              </Box>
            )}
          </form>
        </div>
      )}
      {/* {view === "preview" && (
        <div
          style={{
            ...fullMobileWidth,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography gutterBottom variant="body">
            Hai un appuntamento con:
          </Typography>
          <Typography gutterBottom color="primary" variant="body">
            Merchant name
          </Typography>
          <Typography gutterBottom variant="body">
            Sabato 14 ottobre 2023
          </Typography>
          <Typography gutterBottom variant="body">
            Orario 11:30
          </Typography>
          <Box mt={5} display="flex" flexDirection="column" alignItems="center">
            <Button
              color="primary"
              variant="contained"
              style={{ width: "300px" }}
              onClick={() => props.goTo("agenda")}
            >
              Vai all'agenda appuntamenti
            </Button>
            <Button
              style={{ marginTop: 10, width: "300px" }}
              color="primary"
              variant="contained"
              onClick={() => props.goTo("fair")}
            >
              Nuovo appuntamento
            </Button>
          </Box>
        </div>
      )}*/}
    </div>
  );
};

export default withFirebase(withBmarkenAPI(PrenotazioneAppuntamenti));
