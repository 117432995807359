import React from "react";
import {
  centeredText,
  centeredBoldParagraph,
  buttonStyle,
} from "../styles/helpers";
import Button from "@material-ui/core/Button";
import { parseBmeIntToInt } from "../utils/utils";
import { ROUTES } from "../constants";

class ActionSuccessPage extends React.Component {
  goToPointsPage = () => {
    this.props.history.push({ pathname: ROUTES.punti });
  };

  render() {
    return (
      <div>
        <div style={centeredText}>
          <p
            style={{
              ...centeredBoldParagraph,
              color: "#dc005a",
              marginBottom: "2px",
            }}
          >
            <div>Complimenti: hai ottenuto</div>
            <div className="rowTitlePoint">
              {parseBmeIntToInt(this.props.reward)} PUNTI da:
            </div>
          </p>

          <p style={{ ...centeredBoldParagraph, color: "#9CA1A1" }}>
            {this.props.merchant.name.toUpperCase()}
          </p>
          <p
            style={{ ...centeredBoldParagraph, fontSize: 12, color: "#9CA1A1" }}
          >
            {this.props.merchant.category}
          </p>
          <p
            style={{
              ...centeredBoldParagraph,
              fontSize: 14,
              color: "#9CA1A1",
              marginBottom: "1px",
            }}
          >
            Controlla i tuoi punti e guarda
            <br />
            quanto è vicino il tuo premio
          </p>
          <div>
            <Button
              onClick={() => this.goToPointsPage()}
              style={{
                ...buttonStyle,
                marginTop: "25px",
                marginBottom: "20px",
              }}
              variant="outlined"
              color="primary"
            >
              Bilancio punti
            </Button>
          </div>

          {this.props.selected === "PURCHASE" ? (
            <p
              style={{
                ...centeredBoldParagraph,
                fontSize: 10,
                color: "#9CA1A1",
                marginBottom: "1px",
              }}
            >
              N.B. Il fornitore da cui hai acquistato riceverà una notifica di
              questa segnalazione: potrà, in caso di segnalazione non veritiera,
              eliminare i tuoi punti.
              <br />
              <br />
              Per avere i punti, sono validi solo acquisti per il matrimonio e
              di importo superiore ai 300 €.
              <br />
              <br />
              Ricorda che puoi segnalare un massimo di 15 acquisti senza
              conferma del tuo fornitore.
            </p>
          ) : null}

          {this.props.selected === "VISIT" ? (
            <p
              style={{
                ...centeredBoldParagraph,
                fontSize: 10,
                color: "#9CA1A1",
                marginBottom: "1px",
              }}
            >
              N.B. Il fornitore che hai visitato riceverà una notifica di questa
              segnalazione: potrà, in caso di segnalazione non veritiera,
              eliminare i tuoi punti. <br />
              <br />
              Puoi segnalare un massimo di 25 visite in totale senza conferma
              del fornitore.
            </p>
          ) : null}

          {this.props.selected === "REVIEW" ? (
            <p
              style={{
                ...centeredBoldParagraph,
                fontSize: 10,
                color: "#9CA1A1",
                marginBottom: "1px",
              }}
            >
              N.B. La tua recensione dovrà essere approvata dalla Redazione
              prima di essere pubblicata. Il fornitore che hai recensito
              riceverà una notifica di questa segnalazione.
              <br />
              <br />
              Ricorda che puoi effettuare un massimo di 10 recensioni.
            </p>
          ) : null}

          {this.props.selected == "VISIT" ||
          this.props.selected == "PURCHASE" ? (
            <div>
              <p
                style={{
                  ...centeredBoldParagraph,
                  color: "#dc005a",
                  marginBottom: "25px",
                  marginTop: "2px",
                }}
              >
                Vuoi raddoppiare i tuoi punti?
              </p>
              <p
                style={{
                  ...centeredBoldParagraph,
                  fontSize: 14,
                  color: "#9CA1A1",
                  marginBottom: "1px",
                }}
              >
                Scansiona il codice del fornitore
                <br />
                con la tua fotocamera cliccando sul
                <br />
                pulsante qui sotto.
              </p>
              <div>
                <Button
                  onClick={() => this.props.openScanner()}
                  style={{
                    ...buttonStyle,
                    marginTop: "25px",
                    marginBottom: "20px",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Scansiona
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default ActionSuccessPage;
