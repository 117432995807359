import React from "react";

import Utils from "@date-io/date-fns";

import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  TimePicker as MuiTimePicker,
} from "@material-ui/pickers";

export function TimePicker({ label, value, onChange, ...props }) {
  return (
    <MuiPickersUtilsProvider
      utils={Utils}
      locale={require("date-fns/locale/it").default}
    >
      <MuiTimePicker
        ampm={false}
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        format="HH:mm"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export function DatePicker({ label, value, onChange, ...props }) {
  return (
    <MuiPickersUtilsProvider
      utils={Utils}
      locale={require("date-fns/locale/it").default}
    >
      <MuiDatePicker
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        format="d MMMM yyyy"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
