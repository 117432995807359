import React from 'react'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import { makeStyles } from '@material-ui/core/styles';
import { fullMobileWidth, boldCenteredText } from '../styles/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Privacy = (props) => {
    const classes = useStyles();

    return (
    <div>
        <Topbar history={props.history} from={props.path} />
        <div>
            <div style={{ ...boldCenteredText, ...fullMobileWidth, fontWeight: 'normal', color: '#DC005A'}}>
                <div>Privacy</div>
            </div>

            <div className={classes.root}>
              <div className="termini-servizio-text">
                <p>
            <span className="termini-servizio-subtitle">Norme che regolano la Privacy</span>
            </p><p>
Il presente servizio ti consente di ricevere e utilizzare coupon promozionali, giftcard e altri tipi di voucher promozionali e aderire a programmi di fidelizzazione degli Esercenti ai quali hai esplicitamente manifestato interesse. Potrai accettare o meno le promozioni che ti verranno proposte e aderire o meno ai programmi di fidelizzazione a cui sei interessato.
</p><p>
Ti suggeriamo di registrarti e autenticarti tramite un tuo account social in modo da non dover memorizzare e doverti ricordare ulteriori password, ma potrai scegliere di registrarti e autenticarti fornendo una tua mail e creandoti una password per accedere al servizio.
</p><p>
Nel caso di registrazione attraverso un social utilizzeremo solo i dati essenziali che ci fornirà il tuo social e li conserveremo solo fino a quando non saranno più necessari per fornirti il servizio.
</p><p>
Trovi tutte le informazioni dettagliate sul trattamento, i tuoi diritti e i contatti a cui rivolgerti al link sotto indicato. Utilizzando questo Servizio acconsenti al trattamento dei tuoi dati personali al fine di consentire la fornitura del servizio.
</p><p>
<span className="termini-servizio-subtitle">Consenso al trattamento dei dati personali</span>
</p><p>
Ai sensi del D.Lgs. 196/2003, La informiamo che l’utente, cliccando all’atto dell’iscrizione sulla specifica casella, dichiara il proprio consenso al trattamento dei dati personali per finalità di marketing dirette da parte del Circuito, o degli esercenti a cui appartengono le promo e gli strumenti di fidelizzazione utilizzati, o da terze parti.
</p><p>
Titolare del trattamento è la società Emmebie srl. Avente sede legale in Corso Moncalieri 506/28 a Torino – Tel. 011.6610626-636. Luogo di trattamento dei dati connessi ai servizi editoriali e web è la predetta sede della società Emmebie srl. Il trattamento è curato da personale incaricato, oppure da eventuali incaricati di occasionali operazioni di manutenzione del sito. Responsabile del trattamento è il Sig. Mincica Giuseppe. Responsabile della Protezione Dati (RPD) è il Sig Enrico Collino.
</p><p>
L’utente ha il diritto di accedere ai suoi dati, per chiederne la modifica o la cancellazione o opporsi in tutto o in parte, al loro trattamento a norma dell’art. 7 del Dls. 196/2003. Per esercitare i suoi diritti potrà rivolgersi scrivendo alla Società Emmebie srl ai dati indicati.
</p><p>
Per qualsiasi controversia in ordine alla presente manifestazione a premi, della quale non si è investito il Ministero dello Sviluppo Economico, sarà competente il Foro di Torino.
</p><p>
Informativa completa disponibile sul sito https://www.emmebie.it/policy-privacy/
</p>
</div>
            </div>
            
        </div>
    </div>
    );
}

export default Privacy