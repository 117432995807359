import React from "react"
import dompurify from 'dompurify'

dompurify.addHook('afterSanitizeAttributes', function(node) {
    if ('target' in node) {
        node.setAttribute('target','_blank');
        node.setAttribute('rel', 'noopener noreferrer');
    }
})

function SanitizedHtmlContent(props) {
    const {content, ...innerProps} = props
    return (<div {...innerProps} dangerouslySetInnerHTML={{__html: dompurify.sanitize(content)}} />)
}

export default SanitizedHtmlContent
