import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import SocialIcons from "../common_components/SocialIcons";
import LogoHeader from "../common_components/LogoHeader";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { validateEmail } from "../constants";
import {
  fullMobileWidth,
  boldCenteredText,
  buttonStyle,
} from "../styles/helpers";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import { translateError } from "../utils/utils";
import { ROUTES } from "../constants";
import { SharedContext } from "../utils/Session";

class Login extends React.Component {
  static contextType = SharedContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      user: "",
      password: "",
      userError: false,
      passwordError: false,
      submitLoading: false,
    };
  }

  submitFields = () => {
    this.props.firebase.doSignOut();

    const passwordIsValid = this.state.password.length >= 8;
    const userIsValid = validateEmail(this.state.user);
    if (!passwordIsValid) {
      this.setState({
        passwordError: true,
      });
    }

    if (!userIsValid) {
      this.setState({
        userError: true,
      });
    }

    if (passwordIsValid && userIsValid) {
      this.setState({
        submitLoading: true,
      });

      this.props.firebase
        .doSignInWithEmailAndPassword(this.state.user, this.state.password)
        .then(() => {
          console.log("login eseguita...");
        })
        /*.then(() => {
                //recupero il token 
                return this.props.firebase.doRefreshToken(false)
            })
            .then((token) => {
                //chiamo signup Bmarken
                return this.props.bmarkenAPI.signup(token)
            })
            .then((data) => {
                //tutto ok: refresho il token e lo salvo
                return this.props.firebase.doRefreshToken(true)
            })
            .then((updatedToken) => {
                this.props.bmarkenAPI.saveToken(updatedToken)
                this.props.history.push({ pathname: ROUTES.base });
            })*/
        .catch((error) => {
          //NB: se ci sono errori per sicurezza sloggo user
          this.props.firebase.doSignOut();
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
          this.setState({
            submitLoading: false,
          });
        });
    }
  };

  componentDidMount() {
    /*this.setState({submitLoading: true})

        console.log("controllo se c'è stato un redirect...")
        this.props.firebase.getRedirectResult()
        .then(result => {
            const user = result.user;
            console.log("USER from redirect: ", user)
            if(user === null){
                console.log("...Nessun redirect")
                this.setState({submitLoading: false})
                throw new Error("NO-REDIRECT")
            }

            this.setState({submitLoading: false})
        })
        .catch(error => {
            //NB: se ci sono errori per sicurezza sloggo user
            if(error.message !== "NO-REDIRECT"){
                this.props.firebase.doSignOut()
                error = translateError(error)
                this.context.dispatchMessage(error.message, 'error')
                this.setState({submitLoading: false})
            }
        })*/
  }

  onGoogleClick = () => {
    this.setState({
      submitLoading: true,
    });
    this.props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        console.log(socialAuthUser);
      })
      .catch((error) => {
        this.props.firebase.doSignOut();
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
        this.setState({
          submitLoading: false,
        });
      });
  };

  onFacebookClick = () => {
    this.setState({
      submitLoading: true,
    });
    this.props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        console.log(socialAuthUser);
      })
      .catch((error) => {
        this.props.firebase.doSignOut();
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
        this.setState({
          submitLoading: false,
        });
      });
  };

  render() {
    if (localStorage.getItem("ListenerError")) {
      this.context.dispatchMessage(
        localStorage.getItem("ListenerError"),
        "error"
      );
      localStorage.removeItem("ListenerError");
    }

    return (
      <div style={{ ...boldCenteredText }}>
        <LogoHeader />
        <div style={{ marginBottom: "25px" }}>Accedi con un tuo account</div>
        <SocialIcons
          onGoogleClick={this.onGoogleClick}
          onFacebookClick={this.onFacebookClick}
        />
        <div style={{ marginBottom: "25px", marginTop: "25px" }}>
          oppure inserisci i tuoi dati di accesso
        </div>
        <div>
          <TextField
            id="outlined-email-input"
            label="La tua email"
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="outlined"
            style={fullMobileWidth}
            onChange={(event) => this.setState({ user: event.target.value })}
            error={this.state.userError}
            onClick={() => this.setState({ userError: false })}
          />
        </div>
        {this.state.userError && (
          <div
            style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
          >
            Inserire un indirizzo email valido
          </div>
        )}
        <div>
          <TextField
            id="outlined-password-input"
            label="La tua password"
            autoComplete="current-password"
            margin="normal"
            variant="outlined"
            type="password"
            style={fullMobileWidth}
            onChange={(event) =>
              this.setState({ password: event.target.value })
            }
            error={this.state.passwordError}
            onClick={() => this.setState({ passwordError: false })}
          />
        </div>
        {this.state.passwordError && (
          <div style={{ fontSize: "11px", color: "#f44435" }}>
            La password deve essere lunga almeno 8 caratteri
          </div>
        )}
        <div style={{ marginBottom: "5px", marginTop: "25px" }}>
          Password dimenticata?
        </div>
        <div
          onClick={() => this.props.history.push(ROUTES.password)}
          style={{
            marginBottom: "35px",
            fontWeight: "initial",
            textDecoration: "underline",
            fontSize: "15px",
          }}
        >
          Resetta la tua password
        </div>
        <Button
          onClick={this.submitFields}
          style={buttonStyle}
          variant="outlined"
          color="primary"
        >
          Invia
        </Button>
        <LoadingOverlay show={this.state.submitLoading} />
      </div>
    );
  }
}

export default withFirebase(withBmarkenAPI(Login));
