import React, {useContext} from 'react'
import WarningIcon from '@material-ui/icons/Warning';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import sposiClubTheme from '../styles'
import { fullMobileWidth } from '../styles/helpers'
import { SharedContext } from '../utils/Session';

const MessageComponent = (props) => {
	const { messages, setMessages } = useContext(SharedContext);

	const handleClose = (message) => {
		
		const newMessages = messages.filter(el => el.text !== message)
		setMessages(newMessages)
	}

	const handleCloseAll = (_event, reason) => {
		if (reason === 'timeout') setMessages([])
	}
	
	const Message = ({message}) =>
		<div style={{display: 'flex', alignItems: 'center'}}>
			{message.type === 'error' ? <WarningIcon /> : <InfoIcon />}
			<div style={{marginLeft: '8px', maxWidth: '380px'}}>{message.text}</div>
		</div>
		
	return (
		<Snackbar
			open={messages.length > 0}
			style={{width: '96%'}}
			//autoHideDuration={6000}
			onClose={handleCloseAll}
		>
		<div style={fullMobileWidth}>
			{messages.map((message, index) => {
			return (
			<SnackbarContent
				style={{
					backgroundColor: message.type === 'error' ? sposiClubTheme.palette.primary.main : sposiClubTheme.palette.secondary.main,
					marginBottom: '5px'
				}}
				message={<Message message={message} />}
				key={index}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={() => handleClose(message.text)}
					>
					<CloseIcon />
				</IconButton>,
				]}
			/>
			)})}
		</div>
		</Snackbar>
	)
}

export default MessageComponent