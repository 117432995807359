import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import { fullMobileWidth } from "../styles/helpers";
import { DatePicker, TimePicker } from "../ui/Pickers";
import { getUserInfo, translateError } from "../utils/utils";
import { SharedContext } from "../utils/Session";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Topbar from "../common_components/Topbar";
import { ROUTES } from "../constants";

const EmailContact = (props) => {
  const history = useHistory();
  const [accept, setAccept] = useState(false);
  const [user, setUser] = useState(null);
  const [values, setValues] = useState({
    message: "",
    date: null,
    time: null,
  });
  const { dispatchMessage } = useContext(SharedContext);
  const decodeUrl = window.location.pathname.split("/");
  const backToMerchant = ROUTES.fornitori + "/fornitore/" + decodeUrl[2];

  useEffect(() => {
    props.bmarkenAPI
      .getUser(getUserInfo().user_id, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then((u) => {
        u.custom_fields = JSON.parse(u.custom_fields);
        setUser(u);
      })
      .catch((error) => {
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  }, []);

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (i?.target) {
        i.target.setCustomValidity("");
      }
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const submit = (e) => {
    e.preventDefault();

    const bsId = decodeUrl[2];

    const hasDate = values.date && values.time;

    const date = hasDate && moment(values.date).format("YYYY-MM-DD");
    const time = hasDate && moment(values.time).format("HH:mm");
    const dateTime = hasDate && `${date}T${time}`;

    props.bmarkenAPI
      .postContactEmail(values.message, dateTime, bsId, () =>
        this.props.firebase.doRefreshToken()
      )
      .then(() => {
        dispatchMessage(
          "Grazie per aver usato il nostro servizio. Riceverai quanto prima una risposta dal fornitore contattato.",
          "info"
        );
      })
      .then(() => {
        history.push({
          pathname: backToMerchant,
        });
      })
      .catch((error) => {
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  return (
    <div style={{ ...fullMobileWidth, marginTop: 30 }}>
      {user && (
        <React.Fragment>
          <div>
            <Topbar
              history={history}
              /*goBack={() =>
                history.push({
                  pathname: ROUTES.base,
                })
              }*/
              goBack={() =>
                history.push({
                  pathname: backToMerchant,
                })
              }
            />
          </div>
          <Box mt={2} mb={2}>
            <Typography variant="h6" color="primary">
              RICHIESTA DI CONTATTO
            </Typography>
            <Typography variant="body1">
              Richiedi con questo modulo informazioni, preventivi o appuntamento
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant="h5" color="primary">
              {`${user.complete_name} ${user.last_name}`}
            </Typography>
            <Typography variant="body1">
              {moment(user.custom_fields.wedding_date).format("DD-MM-YYYY")}
            </Typography>
            <Typography variant="body1">{user.email}</Typography>
            <Typography variant="body1">{user.mobile}</Typography>
          </Box>
          <form onSubmit={submit}>
            <Box mb={2}>
              <Typography variant="body1">Descrivi le tue esigenze:</Typography>
              <TextField
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                margin="normal"
                value={values.message}
                onChange={handleValue("message")}
                required
                onInvalid={(e) => {
                  e.target.setCustomValidity(
                    "Descrivi le tue esigenze nel campo di testo"
                  );
                }}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="body1">
                Desidero un appuntamento* per
              </Typography>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <DatePicker
                  label="Data"
                  value={values.date}
                  onChange={handleValue("date")}
                  minDate={new Date()}
                  clearable
                  error={values.time && !values.date}
                  helperText={
                    values.time &&
                    !values.date &&
                    "È obbligatorio indicare la data"
                  }
                />
                <TimePicker
                  label="Ora"
                  value={values.time}
                  onChange={handleValue("time")}
                  clearable
                  error={values.date && !values.time}
                  helperText={
                    values.date &&
                    !values.time &&
                    "È obbligatorio indicare l'orario"
                  }
                />
              </div>
            </Box>
            <Box mb={2}>
              <Typography variant="body2">
                (*) La richiesta di appuntamento non è da considerare confermata
                fino alla risposta del fornitore
              </Typography>
              <Typography variant="body2">
                Con l'invio di questo form stai accettando di conferire i dati
                sopra indicati al fornitore e al loro trattamento. Leggi
                l'informativa sulla privacy completa.
              </Typography>
            </Box>
            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={accept}
                    onChange={(e) => {
                      setAccept(e.target.checked);
                      e.target.setCustomValidity("");
                    }}
                    color="primary"
                    required
                    onInvalid={(e) => {
                      e.target.setCustomValidity(
                        "Per procedere occorre acconsentire al trattamento e al conferimento dei dati"
                      );
                    }}
                  />
                }
                label="Accetto il trattamento e il conferimento dei dati"
              />
            </Box>
            <Box mt={5}>
              <Button
                color="primary"
                variant="outlined"
                style={{ display: "block", margin: "auto" }}
                type="submit"
                required
                disabled={
                  (!values.date && values.time) || (values.date && !values.time)
                }
              >
                Invia la richiesta
              </Button>
            </Box>
          </form>
        </React.Fragment>
      )}
    </div>
  );
};

export default withFirebase(withBmarkenAPI(EmailContact));
