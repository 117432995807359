import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Topbar from "../common_components/Topbar";
import { checkUnauthError, getUserInfo, translateError } from "../utils/utils";
import BackGroundComponent from "../common_components/BackGroundComponent";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import SposiIcon from "../common_components/SposiIcon";
import LogoHeader from "../common_components/LogoHeader";
import { SharedContext } from "../utils/Session";
import Button from "../common_components/Button";
import Card from "../common_components/Card";
import { Typography } from "@material-ui/core";

import bgBoxes from "../styles/images/img_merchant.png";
import bgSposi from "../styles/images/image-004.jpg";
import bgFiera from "../styles/images/img_fiere.png";
import bgPrizes from "../styles/images/img_prizes.png";
import { useState } from "react";
import { fullMobileWidth } from "../styles/helpers";

const IconLink = ({ id, children }) => (
  <Link
    to={`/${id}`}
    style={{
      marginTop: "12px",
      textDecoration: "none",
      color: "#666",
      fontSize: 15,
    }}
  >
    <SposiIcon forceHeight="70px" iconName={id} active={true} />
    <div>{children}</div>
  </Link>
);

const Home = (props) => {
  const { dispatchMessage, setUser } = useContext(SharedContext);
  const [userLogged, setUserLogged] = useState("");

  //chiamata inutile giusto per controllare che la sessione non sia scaduta
  useEffect(() => {
    props.bmarkenAPI
      .getUserLogged(() => props.firebase.doRefreshToken())
      .catch((error) => {
        if (checkUnauthError(error.http_code, props.firebase, props.bmarkenAPI))
          return;
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
    props.bmarkenAPI
      .getUser(getUserInfo().user_id, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then((u) => {
        u.custom_fields = JSON.parse(u.custom_fields);
        setUserLogged(u);
      })
      .catch((error) => {
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  }, []);

  const LinkWithIcon = (props) => {
    const linkTo =
      props.iconName !== "map" ? props.iconName : "ricerca-fornitori";
    return (
      <div
        key="icon-link"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "0px",
          cursor: "pointer",
        }}
      >
        <Link to={`/${linkTo}`} style={{ marginTop: "12px" }}>
          <SposiIcon
            forceHeight="55px"
            iconName={props.iconName}
            active={true}
          />
        </Link>
        <Link to={`/${linkTo}`} style={{ textDecoration: "none" }}>
          <div
            style={{
              marginLeft: "15px",
              color: "#fff",
              padding: "3px 15px",
              marginTop: "13px",
              textAlign: "left",
              width: "200px",
              border: "1px solid #ffffff",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                textTransform: "uppercase",
                color: "#fff",
                textAlign: "center",
              }}
            >
              {props.mainText}
            </div>
            <div
              style={{ marginTop: "0px", color: "#fff", textAlign: "center" }}
            >
              {props.subText}
            </div>
          </div>
        </Link>
      </div>
    );
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Topbar history={props.history} from={props.path} key={"topBar"} />
      <BackGroundComponent background={"home"} />
      <div
        key={"content"}
        style={{
          textAlign: "center",
          fontWeight: "600",
          marginBottom: "20px",
          padding: 10,
        }}
      >
        <LogoHeader /*white={true}*/ />
        {/*<div key="title" style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#fff'}}>Organizza le tue nozze e vinci!</div>*/}

        {userLogged && (
          <Typography variant="h6" color="primary">
            {`Ciao ${userLogged.complete_name} ${userLogged.last_name}`}
          </Typography>
        )}

        <Card
          bg={bgFiera}
          title="VISITA LE FIERE CONVENZIONATE"
          contentHeight={true}
        >
          <Button component={Link} to="/fiera">
            UTILITY E PASS GRATUITO
          </Button>
        </Card>

        {/* OLD <Card bg={bgFiera} title="VISITA LE FIERE CONVENZIONATE">
          <Button component={Link} to="/fiera">
            Utility e pass gratuito
          </Button>
        </Card>*/}

        <Card title="ACCUMULA I TUOI PUNTI">
          <div className="smallGrid">
            <IconLink id="visita">
              VISITA
              <br />
              20 punti
            </IconLink>
            <IconLink id="acquisto">
              ACQUISTO
              <br />
              200 punti
            </IconLink>
            <IconLink id="recensione">
              RECENSIONE
              <br />
              50 punti
            </IconLink>
            <IconLink id="fiera">
              FIERA
              <br />
              600 punti
            </IconLink>
          </div>

          <p>Registra un’azione e vinci il tuo premio!</p>

          <div
          /*style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridGap: 10,
              width: "100%",
            }}*/
          >
            {/*<div>*/}
            <Button component={Link} to="/premi" bgColor>
              Elenco dei premi
            </Button>
            {/*</div>*/}
            {/*<div>*/}
            <Button component={Link} to="/punti" bgColor>
              I tuoi punti
            </Button>
            {/*</div>*/}
          </div>
        </Card>

        <Card bg={bgBoxes} title="SCOPRI I FORNITORI DELLA TUA ZONA">
          <Button href="/ricerca-fornitori">Inizia la ricerca</Button>
        </Card>

        <Card bg={bgPrizes} title="VINCI PREMI SICURI">
          <Button href="https://www.sposiclub.it/static/come-funziona.html">
            Come funziona SposiClub
          </Button>
        </Card>

        <div style={{ ...fullMobileWidth, marginTop: 50 }}>
          <p
            style={{
              fontSize: 12,
              marginBottom: 30,
              border: "1px solid grey",
              padding: 10,
            }}
          >
            Emmebie srl - Corso Moncalieri, 506/28 - 10133 TORINO - ITALY
            <br />
            P.IVA 08325390014 -{" "}
            <a
              style={{ fontSize: 12 }}
              target="legal"
              href="https://www.emmebie.it/policy-privacy/"
            >
              {/*Legal & privacy*/} Privacy Policy
            </a>
          </p>
        </div>

        {/*<Card bg={bgBoxes} title="Cerca i fornitori convenzionati">
          <Button href="/ricerca-fornitori">ELENCO FORNITORI</Button>

          <Button href="https://www.guidasposi.it/">Cerca per categoria</Button>
          </Card>*/}
        {/*
        <Card bg={bgSposi} title="Scopri nuove ispirazioni">
          <Button href="https://www.guidasposi.it/blog/">Visita il wedding blog</Button>
        </Card>
        */}
      </div>
    </div>
  );
};

export default withFirebase(withBmarkenAPI(Home));
