export const ROUTES = {
  base: "/",
  visita: "/visita",
  fiera: "/fiera",
  acquisto: "/acquisto",
  recensione: "/recensione",
  punti: "/punti",
  premi: "/premi",
  faq: "/faq",
  convenzioni: "/convenzioni",
  login: "/login",
  registrazione: "/registrazione",
  regolamento: "/regolamento",
  termini_servizio: "/termini-servizio",
  privacy_policy: "/privacy-policy",
  accesso: "/accesso",
  logout: "/logout",
  fornitori: "/ricerca-fornitori",
  fornitori_public: "/elenco-fornitori",
  profilo: "/profilo",
  operatore: "/operatore",
  password: "/reset-password",
  scansiona: "/scansiona",
  interazioni: "/interazioni",
  faq_merchant: "/faq-operatori",
  gestione_fiere: "/gestione-fiere",
  gestione_recensioni: "/gestione_recensioni",
  convenzioni: "/convenzioni",
  assistenza: "/assistenza",
  assistenza_merchant: "/assistenza_merchant",
  come_funziona: "/come-funziona",
  stats: "/statistiche",
  stats_users: "/statistiche/utenti",
  stats_prizes: "/statistiche/premi",
  stats_merchants: "/statistiche/operatori",
  contact_form: "/form-contatto",
};

export const validateEmail = (email) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const LOCALITEMKEY = "mockAuth";

const _PROVINCIE = [
  { label: "Alessandria", value: "AL", region: "Piemonte" },
  { label: "Asti", value: "AT", region: "Piemonte" },
  { label: "Biella", value: "BI", region: "Piemonte" },
  { label: "Cuneo", value: "CN", region: "Piemonte" },
  { label: "Novara", value: "NO", region: "Piemonte" },
  { label: "Torino", value: "TO", region: "Piemonte" },
  { label: "Verbano-Cusio-Ossola", value: "VB", region: "Piemonte" },
  { label: "Vercelli", value: "VC", region: "Piemonte" },

  { label: "Arezzo", value: "AR", region: "Toscana" },
  { label: "Firenze", value: "FI", region: "Toscana" },
  { label: "Grosseto", value: "GR", region: "Toscana" },
  { label: "Livorno", value: "LI", region: "Toscana" },
  { label: "Lucca", value: "LU", region: "Toscana" },
  { label: "Massa-Carrara", value: "MS", region: "Toscana" },
  { label: "Pisa", value: "PI", region: "Toscana" },
  { label: "Pistoia", value: "PT", region: "Toscana" },
  { label: "Prato", value: "PO", region: "Toscana" },
  { label: "Siena", value: "SI", region: "Toscana" },

  { label: "Bologna", value: "BO", region: "Emilia Romagna" },
  { label: "Ferrara", value: "FE", region: "Emilia Romagna" },
  { label: "Forlì-Cesena", value: "FC", region: "Emilia Romagna" },
  { label: "Modena", value: "MO", region: "Emilia Romagna" },
  { label: "Parma", value: "PR", region: "Emilia Romagna" },
  { label: "Piacenza", value: "PC", region: "Emilia Romagna" },
  { label: "Ravenna", value: "RA", region: "Emilia Romagna" },
  { label: "Reggio Emilia", value: "RE", region: "Emilia Romagna" },
  { label: "Rimini", value: "RN", region: "Emilia Romagna" },

  { label: "Genova", value: "GE", region: "Liguria" },
  { label: "Imperia", value: "IM", region: "Liguria" },
  { label: "La Spezia", value: "SP", region: "Liguria" },
  { label: "Savona", value: "SV", region: "Liguria" },

  { label: "Bergamo", value: "BG", region: "Lombardia" },
  { label: "Brescia", value: "BR", region: "Lombardia" },
  { label: "Como", value: "CO", region: "Lombardia" },
  { label: "Cremona", value: "CR", region: "Lombardia" },
  { label: "Lecco", value: "LC", region: "Lombardia" },
  { label: "Lodi", value: "LO", region: "Lombardia" },
  { label: "Mantova", value: "MN", region: "Lombardia" },
  { label: "Milano", value: "MI", region: "Lombardia" },
  { label: "Monza Brianza", value: "MB", region: "Lombardia" },
  { label: "Pavia", value: "PV", region: "Lombardia" },
  { label: "Sondrio", value: "SO", region: "Lombardia" },
  { label: "Varese", value: "VA", region: "Lombardia" },
];

export const PROVINCIE = _PROVINCIE.sort((a, b) =>
  a.label.localeCompare(b.label)
);
