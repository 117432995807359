import React from "react";
import {
  fullMobileWidth,
  boldCenteredText,
  buttonStyle,
} from "../styles/helpers";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DatePicker from "../common_components/DatePicker";

class ActionForms extends React.Component {
  render() {
    if (this.props.selected === "PURCHASE") {
      return (
        <div>
          <div style={{ ...fullMobileWidth, ...boldCenteredText }}>
            <div style={fullMobileWidth}>
              <DatePicker
                value={this.props.purchaseDate}
                label="Data dell'acquisto"
                onChange={(value) =>
                  this.props.onDateChange("purchaseDate", value)
                }
              />
            </div>
            <div>
              <TextField
                id="outlined-purchase-value-input"
                //label="Importo acquisto in €"
                label="Descrizione dell'acquisto"
                type="text"
                //name="purchaseValue"
                name="review"
                autoComplete=""
                margin="normal"
                variant="outlined"
                style={fullMobileWidth}
                //value={this.props.purchaseValue}
                value={this.props.review}
                onChange={(e) => this.props.onTextChange(e)}
              />
            </div>
            <div>
              <Button
                onClick={() => this.props.onSubmit()}
                style={{
                  ...buttonStyle,
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
                variant="outlined"
                color="primary"
              >
                Conferma
              </Button>
            </div>
          </div>
        </div>
      );
    } else if (this.props.selected === "VISIT") {
      return (
        <div>
          <div style={{ ...fullMobileWidth, ...boldCenteredText }}>
            <div style={fullMobileWidth}>
              <DatePicker
                value={this.props.visitDate}
                label="Data della visita"
                onChange={(value) =>
                  this.props.onDateChange("visitDate", value)
                }
              />
            </div>
            <div>
              <TextField
                id="outlined-visit-reason-input"
                label="Motivo della visita"
                type="text"
                name="visitReason"
                autoComplete=""
                margin="normal"
                variant="outlined"
                style={fullMobileWidth}
                value={this.props.visitReason}
                onChange={this.props.onTextChange}
              />
            </div>
            <div>
              <Button
                onClick={() => this.props.onSubmit()}
                style={{
                  ...buttonStyle,
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
                variant="outlined"
                color="primary"
              >
                Conferma
              </Button>
            </div>
          </div>
        </div>
      );
    } else if (this.props.selected === "REVIEW") {
      return (
        <div>
          <div style={{ ...fullMobileWidth, ...boldCenteredText }}>
            <div>
              <TextField
                id="outlined-review-input"
                placeholder="Racconta perché ti è piaciuto o perché hai scelto questo fornitore"
                type="text"
                name="review"
                autoComplete="Inserisci qui il testo della tua recensione:
                                i punti saranno confermati solo
                                dopo il controllo della nostra redazione"
                margin="normal"
                variant="outlined"
                style={fullMobileWidth}
                value={this.props.review}
                onChange={this.props.onTextChange}
                multiline={true}
                rows={4}
              />
            </div>
            <div>
              <Button
                onClick={() => this.props.onSubmit()}
                style={{
                  ...buttonStyle,
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
                variant="outlined"
                color="primary"
              >
                Conferma
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return <div>Interazione non gestita</div>;
    }
  }
}

export default ActionForms;
