import React from 'react'
import { centeredBoldParagraph, buttonStyle, fullMobileWidth } from '../styles/helpers'
import Button from '@material-ui/core/Button'

var QRCode = require('qrcode.react');

const QRCodeContainer = (props) => {

    const fairCode = props.fairCode
    const code = props.code
    if(fairCode){
        return(
            <div>
                <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>
                    Fai leggere questo codice<br/>dall'app del fornitore
                </p>
                <p style={{...centeredBoldParagraph, fontSize: 14, color:"#9CA1A1", marginBottom: '1px'}}>
                    Riceverai 20 punti per questa<br/>
                    conferma della tua visita
                </p>
                <br/>    
                <QRCode
                    value={"fair:"+fairCode}
                    size={200}
                    bgColor='white'
                    fgColor='black'/>
            </div>
        )
    }else if(code){
        return(
            <div>
                <div style={{color:"#dc005a", marginBottom: '25px', marginTop: '25px'}}>
                    Fai leggere questo codice dall'app del fornitore
                </div>
                <div style={{fontSize: 14, color:"#9CA1A1", marginBottom: '25px'}}>
                    Raddoppierai i tuoi punti per questa tua attività
                </div>
                <div>
                    <QRCode
                        value={"confirm:"+code}
                        size={200}
                        bgColor='white'
                        fgColor='black'
                    />
                </div>
                <p style={{fontSize: 14, color:"#9CA1A1", marginBottom: '1px'}}>
                    Il codice è scaduto scaduto?
                </p>
                <div>
                    <Button onClick={() => props.onRefreshCode()} style={{...buttonStyle, marginTop: '25px'}} variant="outlined" color="primary">
                    Refresh
                    </Button>
                </div>
            </div>
        )
    }else{
        return(
            <div>
                <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>
                    Spiacenti non è stato possibile<br/>generare il codice di questa azione
                </p>
                <p style={{...centeredBoldParagraph, fontSize: 14, color:"#9CA1A1", marginBottom: '1px'}}>
                    Contatta l'assistenza o riprova
                </p>
            </div>
        )
    }
    
}

export default QRCodeContainer;