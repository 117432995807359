import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './utils/Firebase';
import BmarkenAPI, { BmarkenAPIContext } from './utils/Api';
import { SharedContextProvider } from "./utils/Session";
import TagManager from 'react-gtm-module'

if (window.location.pathname.startsWith('/operatore')) {
    window.location.href = "https://sposiclubdemo.bmarken.com/manager/auth/login/";
}
const gtmId = process.env.REACT_APP_GTM_ID
const gtmAuth = process.env.REACT_APP_GTM_AUTH
const gtmEnv = process.env.REACT_APP_GTM_PREVIEW

const tagManagerArgs = {
    gtmId: gtmId/*,
    auth: gtmAuth,
    preview: gtmEnv*/
}


TagManager.initialize(tagManagerArgs)


ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <BmarkenAPIContext.Provider value={new BmarkenAPI()}>
                <App />
        </BmarkenAPIContext.Provider>
    </FirebaseContext.Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
