import React from 'react'
import LogoHeader from '../common_components/LogoHeader'
import Button from '@material-ui/core/Button'
import { buttonStyle } from '../styles/helpers'
import { ROUTES } from '../constants';
import { Redirect } from "react-router-dom"

const Notfound = (props) => {

  if(props.user && props.user!==null){
    return (
      <div style={{textAlign: 'center'}}>
        <LogoHeader />
        <div>Siamo spiacenti, l'url richiesto non è collegato a nessun contenuto</div>
        <Button
          style={{marginTop: '50px', ...buttonStyle}}
          variant="outlined"
          color="primary"
          onClick={()=>props.history.goBack()}
        >
          Torna indietro
        </Button>
      </div>
    )
  }else{
    return(<Redirect to={ROUTES.base} />)
  }
  
}

export default Notfound