import React from 'react'
import ReactDOM from 'react-dom'
import mapboxgl from 'mapbox-gl'
import { fullMobileWidth } from '../styles/helpers'
import { getDistanceFromLatLonInKm } from '../utils/utils'
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchMapMarker from '../common_components/SearchMapMarker'
import {translateError, parseBmeIntToInt} from '../utils/utils'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { SharedContext } from '../utils/Session';

mapboxgl.accessToken = 'pk.eyJ1Ijoic21haW5pbmkiLCJhIjoiY2p5OWpkMHp6MDRmejNubzYzZzU1ZXBkZyJ9.2nBUYurbhT0BCIB_g2cSfA';


// cache dei marker
var markers = {};
var markersOnScreen = {};

class SearchMap extends React.Component {

  static contextType = SharedContext

  createCluster = (id, title, coords) => {
    const elCluster = document.createElement('div')
    elCluster.className = "marker-cluster"
    const elCount = document.createElement('div')
    elCount.className = "centerCircle"
    elCluster.appendChild(elCount)
    const updateMarkers = this.updateMarkers
    elCluster.onclick = (e) => {
      const tthis = this
      try {
        tthis.state.map.getSource('operatori').getClusterExpansionZoom(id, function (err, zoom) {
          if (err) {
            console.log(err)
            return;
          }
          tthis.state.map.easeTo({
          //tthis.state.map.jumpTo({
            center: coords,
            zoom: zoom
          })
        })
      } catch(e) {
        console.log(e)
      }

    }
    var txt = document.createTextNode(title)
    elCount.appendChild(txt)
    return elCluster
  }

  createMarker = (id, title) => {
    var elMarker = document.createElement('div');
    elMarker.className = "marker";
    elMarker.tabIndex = 1;
    elMarker.onclick = () => {
      this.props.onSelectMerchantId(id)
    }
  
    var elMarkerbox = document.createElement('div');
    elMarkerbox.className = "markerbox";
    elMarker.appendChild(elMarkerbox);
  
    if (title && title.length > 20) {
      title = title.substring(0, 20) + "..."
    }
    var elTitle = document.createElement('h3');
    var txt = document.createTextNode(title)
    elTitle.appendChild(txt);
    elMarkerbox.appendChild(elTitle);
  
    var elPin = document.createElement('div');
    elPin.className = "markerpin";
    elMarker.appendChild(elPin);
  
    return elMarker;
  }
  
  updateMarkers = (map) => {
    var newMarkers = {};
    // estrae le features marker nel tile visualizzato
    const features = map.querySourceFeatures("operatori", { layers: ['clusters', 'operatori'] });
    for (var i = 0; i < features.length; i++) {
      var props = features[i].properties;
      var coords = features[i].geometry.coordinates;
      let iid, id, marker
      if (props.cluster) {
        iid = props.cluster_id
        id = "c:" + iid
        marker = markers[id];
        if (!marker) {
          marker = new mapboxgl.Marker({
              element: this.createCluster(iid, props.point_count, coords, map), 
              anchor: 'center'
          }).setLngLat(coords);
        }
      } else {
        iid = props.id
        id = "m:" + iid
        marker = markers[id];
        if (!marker) {
          marker = new mapboxgl.Marker({
            element: this.createMarker(iid, props.title), 
            anchor: 'bottom'
          }).setLngLat(coords);
        }
      }
      markers[id] = marker
      newMarkers[id] = marker;
      
      if (!markersOnScreen[id])
        marker.addTo(map);
    }
    // for every marker we've added previously, remove those that are no longer visible
    let mid
    for (mid in markersOnScreen) {
      if (!newMarkers[mid])
        markersOnScreen[mid].remove();
    }
    markersOnScreen = newMarkers;
  }

  initFromLocation = () => {
    const tthis = this
    this.props.bmarkenAPI.getAllMerchantGeoPointsPublic()
    .then(data => {

      // map
      const map = new mapboxgl.Map({
        container: this.mapContainer,
        style: 'mapbox://styles/mapbox/streets-v9',
        center: [this.props.lng, this.props.lat],
        zoom: 14,
      });
      map.addControl(new mapboxgl.NavigationControl());
      this.setState({map: map})

      // source
      map.on('load', function(e) {

        map.addSource("operatori", { 
          type: "geojson", 
          data: data,
          cluster: true,
          clusterMaxZoom: 10,
        })
        
        map.addLayer({
          id: "operatori",
          type: "circle",
          source: "operatori",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-radius": 1,
            "circle-color": "#000000"
          }
        })

        map.on('data', () => {
          tthis.updateMarkers(map)
        })

        map.on('move', () => tthis.updateMarkers(map));
        map.on('moveend', () => tthis.updateMarkers(map));
      })
    })
    .catch(error => {
      //this.setState({loading: false})
      error = translateError(error)
      this.context.dispatchMessage(error.message, 'error')
    })
  }

  initFromList = () => {
    const tthis = this
    let geodata = {
      type: "FeatureCollection",
      features: []
    }
    this.props.merchants.map(merchant => 
      geodata.features.push({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [merchant?.location?.lng, merchant?.location?.lat],
        },
        properties: {
          id: merchant.id,
          title: merchant.name,
        }
      })
    )

    // map
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v9',
      center: [this.props.lng, this.props.lat],
      zoom: 14,
    });
    map.addControl(new mapboxgl.NavigationControl());
    this.setState({map: map})

    // source
    map.on('load', function(e) {

      map.addSource("operatori", { 
        type: "geojson", 
        data: geodata,
        cluster: true,
        clusterMaxZoom: 10,
      })
      
      map.addLayer({
        id: "operatori",
        type: "circle",
        source: "operatori",
        filter: ["!", ["has", "point_count"]],
        paint: {
          "circle-radius": 1,
          "circle-color": "#000000"
        }
      })

      map.on('data', () => {
        tthis.updateMarkers(map)
      })

      map.on('move', () => tthis.updateMarkers(map));
      map.on('moveend', () => tthis.updateMarkers(map));
    })
  }

    
    componentDidMount() {
      if (this.props.merchants && this.props.merchants.length) {
        this.initFromList()
      } else {
        this.initFromLocation()
      }
    }


    componentWillUnmount() {
      if (this.state.map)
        this.state.map.remove();
      this.setState({map: null})
      markers = {};
      markersOnScreen = {};
    }

    render() {
      const style = {
        position: 'relative',
        width: '100%',
        height: '500px',
        ...fullMobileWidth
      };

  
      return (
      <div style={style} ref={el => this.mapContainer = el}/>
      );
    }
  }

  export default withFirebase(withBmarkenAPI(SearchMap))