import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import sposiTheme from '../styles'

const LoadingOverlay = (props) => {
  if (!props.show) return null
  return (
    <div id="loaderOverlay" style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      top: '0',
      left: '0',
      backgroundColor: 'rgba(95, 95, 95, 0.42)',
      zIndex: '500'
    }}>
      <CircularProgress size={50} style={{
        color: sposiTheme.palette.primary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -25,
        marginLeft: -25,
      }} />
    </div>
  )
}

export default LoadingOverlay