import React, { Component } from 'react'
import Downshift from 'downshift'
import { throttle } from "throttle-debounce";
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { SharedContext } from "../utils/Session";


  function renderSuggestion(suggestionProps) {
    const { suggestion, index, itemProps, highlightedIndex, selectedItem, categories } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem && selectedItem.name.indexOf(suggestion.name) > -1;
    //let category = suggestion.categories.length > 0 ? suggestion.categories[0] : ""
    let category = suggestion.category || ""
    if (categories?.length) {
      category = categories.find((cat) => cat.id === category)?.label;
    }
    if(category != ""){
      category = "("+category+")"
    }
    return (
      <MenuItem
        {...itemProps}
        key={suggestion.id}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {suggestion.name} <span style={{fontSize:13,marginLeft:5}}>{category}</span>
      </MenuItem>
    );
  }
  renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.object,
    /*suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,*/
  };


  
  
class Autocomplete extends Component {
    static contextType = SharedContext;
    state = {
      currValue: '',
      results: [],
    }

    onInputValueChange = inputValue => {
      this.setState({
        currValue: inputValue,
      })
    }

    valueLabelToString = item => {
      return item ? item.name : "";
    }

    onStateChange = throttle(1000, ({ inputValue }) => {
        if (typeof inputValue !== 'undefined' && inputValue.length > 2) {
            this.setState({
              currValue: inputValue,
            })

            this.props.search(inputValue)
            .then(res => {
              if(res && res!==null){
                this.setState({
                  results: res,
                })
              }  
            })
            .catch(err => {
              console.error(err)
            })

        }else if(typeof inputValue !== 'undefined'){
          this.setState({
            results: [],
            currValue: inputValue,
          })
        }
    })

    render() {
      const { currValue } = this.state
      const { categories } = this.context;
      return (
        <Downshift
          onStateChange={this.onStateChange}
          onChange={this.props.onChange}
          onInputValueChange={this.onInputValueChange}
          itemToString={this.valueLabelToString}
        >
          {({
            getInputProps,
            getItemProps,
            getLabelProps,
            getMenuProps,
            selectedItem,
            highlightedIndex,
            isOpen,
            clearSelection,
          }) => {
            return (
            <div style={this.props.inputStyle}>
                <TextField
                    {...getInputProps(
                      {
                        onBlur: (e) => {if (!e.target.id) clearSelection()}
                      }
                    )}
                    label={this.props.inputLabel}
                    variant={this.props.inputVariant}
                    margin={this.props.inputMargin}
                    style={this.props.inputStyle}
                    
                />
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper square>
                    {
                      this.state.results.map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({ item: suggestion }),
                          highlightedIndex,
                          selectedItem,
                          categories,
                        })
                      )
                    }
                  </Paper>
                ) : null}
              </div>

            </div>
          )}}
        </Downshift>
      )
    }
  }

  export default Autocomplete