import React, { useState, useContext, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import LoadingOverlay from '../common_components/LoadingOverlay'
import { fullMobileWidth, boldCenteredText, buttonStyle } from '../styles/helpers'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { translateError } from '../utils/utils'
import Topbar from '../common_components/Topbar'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { SharedContext } from '../utils/Session';
import ConfirmPopUp from '../common_components/ConfirmPopUp'
import moment from 'moment'

const GestioneRecensioni = (props) => {

    const [view, setView] = useState("main")
    const [reviews, setReviews] = useState([])
    const [loading, setLoading] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [idAction, setIdAction] = useState("")
    const [statusAction, setStatusAction] = useState("")
    const { dispatchMessage } = useContext(SharedContext);

    
    useEffect(() => {
        getReviews()
    }, []);

    const getReviews = () => {
        setLoading(true)
        props.bmarkenAPI.getReviews("",() => props.firebase.doRefreshToken())
        .then(r => {
            setLoading(false)
            if(r !== null)
                setReviews(r)
            else
                setReviews([])
        })
        .catch(error => {
            setLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const onSubmit = () => {
        setLoading(true)

        props.bmarkenAPI.updateActionStatus(idAction, statusAction,() => {
            return props.firebase.doRefreshToken(true)
        })
        .then(() => {
            getReviews()
            setLoading(false)
            if(statusAction === "APPROVED")
                dispatchMessage("Recensione confermata con successo", 'info')
            else
                dispatchMessage("Recensione cancellata con successo", 'info')

        })
        .catch(error => {
            setLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const askConfirm = (id, status) => {
        setIdAction(id)
        setStatusAction(status)
        setOpenConfirm(true)
    }

    const renderStatus = (status) => {
        let color;
        switch(status) {
            case 'CANCELED':
              color = 'red'
              break;
            case 'PENDING':
              color = 'gold'
              break;
            default:
              color = 'green'
          }
        return <div style={{color, fontSize: '14px'}}>{status}</div>
    }

    const mainView = () => {
        return (
            <div style={{...boldCenteredText}}>
                <main >
                    <List>
                    {
                        reviews.map(r => {
                            return(
                                <Card key={r.id} style={{margin:'20px'}}>
                                    <CardHeader
                                        action={renderStatus(r.status)}
                                    />
                                    <CardContent>
                                        {[
                                            { label: "Utente:", content: r.user_email },
                                            { label: "Data:", content: moment(r.created_at).format('DD-MM-YY') },
                                            { label: "Negozio:", content: r.business_name },
                                            { label: "Commento:", content: r.comment },
                                        ].map(el => (
                                            <div style={{display: 'flex', justifyContent: 'center', fontSize: '13px', textAlign: 'left', marginBottom: '10px'}}>
                                                <div style={{width: '85px', fontWeight: 'bold'}}>{el.label}</div>
                                                <div style={{width: '250px'}}>{el.content}</div>
                                            </div>
                                        ))}
                                    </CardContent>
                                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px'}}>
                                        <Button disabled={r.status=="CANCELED"} size="small" variant="outlined" color="primary" onClick={() => askConfirm(r.id, "CANCELED")}>
                                            Elimina
                                        </Button>
                                        <Button disabled={r.status=="APPROVED"} size="small" variant="outlined" color="primary" onClick={() => askConfirm(r.id, "APPROVED") }>
                                            Accetta
                                        </Button>
                                    </div>
                                </Card>
                            )
                        })
                    }
                    </List>
                </main>
                <ConfirmPopUp
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    onConfirm={() => onSubmit()}
                    text="Sei sicuro?"
                    title="Conferma"
                />
                <LoadingOverlay show={loading} />
            </div>
        )
    }

    const viewController = (view) => {
        switch(view){
            default: return mainView();
        }
    }

    const goBack = () => {
        if(view === "main") props.history.goBack()
        else if(view === "create" || view === "update") setView("main")
    }

    return (
        <div>
            <Topbar goBack={() => goBack()}/>
            <div style={fullMobileWidth}>
                {viewController(view)}
            </div>
        </div>
    )
}

export default withFirebase(withBmarkenAPI(GestioneRecensioni))