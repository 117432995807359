import React from 'react'
import landingBG from '../styles/images/landing.jpg'
import SIlogo from '../styles/images/SposiClub_SI.svg'
import { fullMobileWidth } from '../styles/helpers'

const SposiLoading = (props) => {
  const [counter, setCounter] = React.useState(0);

  React.useEffect(() => {
    if (counter < 50) {
      setTimeout(()=> setCounter(counter + 1), 20)
    } else {
      setTimeout(()=> setCounter(0), 20)
    }
  }, [counter])

  if (!props.show) return null
  return (
    <div>
      <div id="loaderOverlay" style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        backgroundImage: `url(${landingBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: '499'
      }} />
      <div id="loaderOverlay" style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: '0',
        left: '0',
        backgroundColor: '#ffffffcc',
        zIndex: '500'
      }}>
        <div className="centerVertAndHor">
          <div style={{...fullMobileWidth, padding: '15px', opacity: counter * 0.02}}>
            <img
              style={{width: '100%', maxWidth: '350px', cursor: 'pointer'}}
              alt={`sposiclub-si-logo`}
              src={SIlogo}
              onClick={props.onClick}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SposiLoading