import React, { useContext, useEffect, useState } from "react";
import Topbar from "../common_components/Topbar";
//import { ROUTES } from "../constants";
import { withBmarkenAPI } from "../utils/Api";
import { withFirebase } from "../utils/Firebase";
import { useHistory } from "react-router-dom";
import { fullMobileWidth } from "../styles/helpers";
import { translateError } from "../utils/utils";
import { SharedContext } from "../utils/Session";
import EventList from "../common_components/EventList";
import ConfirmPopUp from "../common_components/ConfirmPopUp";
import moment from "moment";
//import ReservationPreview from "../common_components/ReservationPreview";
import LoadingOverlay from "../common_components/LoadingOverlay";

const PrenotazioneEventi = (props) => {
  const history = useHistory();
  const { dispatchMessage } = useContext(SharedContext);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [events, setEvents] = useState([]);
  //const [view, setView] = useState("");
  const [eventSelected, setEventSelected] = useState([]);

  const today = new Date();
  const tomorrow = new Date(today);
  const theDayAfterTomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  theDayAfterTomorrow.setDate(theDayAfterTomorrow.getDate() + 2);

  const loadEvents = () => {
    setSubmitLoading(true);
    props.bmarkenAPI
      .getEventsByCampaign(props.selectedFair.event_campaign_id, () =>
        props.firebase.doRefreshToken()
      )
      .then((resp) => {
        if (!resp) {
          resp = [];
        } else {
          setEvents(resp);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  useEffect(() => {
    loadEvents();
  }, []);

  const confirmEvent = () => {
    setSubmitLoading(true);
    props.bmarkenAPI
      .postEventConfirm(eventSelected.id, () => props.firebase.doRefreshToken())
      .then((resp) => {
        if (!resp) {
          resp = [];
        } else {
          //setEventSelected(resp);
          setSubmitLoading(false);
          props.goTo("agenda");
          dispatchMessage(
            `Hai effettuato una prenotazione per l'evento ${
              eventSelected.name
            } il giorno ${moment(eventSelected.start_date).format(
              "DD-MM-YYYY"
            )} alle ore ${eventSelected.start_hour}`,
            "info"
          );
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const handleSelectEvent = (event) => {
    setOpenConfirm(true);
    setEventSelected(event);
  };

  return (
    <div style={fullMobileWidth}>
      <div>
        <Topbar history={history} goBack={() => props.goTo("fair")} />
      </div>
      <div>
        <img
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          alt="logoFiera"
          src={props.selectedFair.url}
        />
      </div>
      <div style={fullMobileWidth}>
        <EventList
          events={events}
          loading={submitLoading}
          onClick={handleSelectEvent}
        />
      </div>

      <ConfirmPopUp
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={confirmEvent}
        text="Confermi di volerti prenotare per l'evento selezionato?"
        title="Conferma prenotazione evento"
      />

      {/* {view !== "preview" && (
        <div style={fullMobileWidth}>
          <EventList
            events={events}
            loading={submitLoading}
            onClick={handleSelectEvent}
          />
        </div>
      )} */}

      {/*  {view === "preview" && (
        <ReservationPreview
          title="Hai una prenotazione per"
          name={eventSelected.name}
          date={moment(eventSelected.start_date).format("DD-MM-YYYY")}
          time={`${eventSelected.start_hour} - ${eventSelected.end_hour}`}
          goTo={(val) => props.goTo(val)}
        />
      )}*/}
      <LoadingOverlay show={submitLoading} />
    </div>
  );
};

export default withFirebase(withBmarkenAPI(PrenotazioneEventi));
