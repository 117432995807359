import React from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import { ROUTES } from "../constants";
import SanitizedHtmlContent from "../common_components/SanitizedHtmlContent";
import { centeredBoldParagraph, centeredText } from "../styles/helpers";
import {
  translateError,
  parseBmeIntToInt,
  parseBmeIntToFloat,
} from "../utils/utils";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import { SharedContext } from "../utils/Session";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { buttonStyle } from "../styles/helpers";
import premiIMG from "../styles/images/premi";
import QRCodePrize from "../common_components/QRCodePrize";
import LoadingOverlay from "../common_components/LoadingOverlay";
import ConfirmPopUp from "../common_components/ConfirmPopUp";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import moment from "moment";

const cellStyle = {
  fontSize: "18px",
  padding: "1px",
  borderBottom: "0px",
};

const grayTextStyle = {
  fontSize: "18px",
  color: "#8b8b8b",
};

const redTextStyle = {
  color: "#dc005a",
  fontSize: "18px",
};

class Premi extends React.Component {
  static contextType = SharedContext;

  constructor(props) {
    super(props);
    this.state = {
      card: null,
      prizes: [],
      myprizes: [],
      mapPrizeInfo: {},
      selectedPrize: null,
      myprizeid: null,
      permid: null,
      openConfirm: false,
      diff: 0,
      activeView: "main",
      enableGetPrize: false,
      showMore: {},
    };
  }

  showMore = (id) => {
    let sm = this.state.showMore;
    let s = sm[id];
    if (s !== undefined && s !== null) {
      s = !s;
    } else {
      s = true;
    }
    sm[id] = s;
    this.setState({ showMore: sm });
  };

  getPrizes = () => {
    this.setState({ loading: true });

    // elenco prodotti
    const userID = this.props.bmarkenAPI.getTokenInfo().bme_id;
    this.props.bmarkenAPI
      .getProducts(userID, () => this.props.firebase.doRefreshToken())
      .then((products) => {
        let card = null;
        let myprizes = [];

        products.map((product, index) => {
          if (product.default) {
            card = product;
          } else {
            myprizes.push(product);
          }
        });

        this.setState({
          points: card !== null ? parseBmeIntToInt(card.available_value) : 0,
          card: card,
          myprizes: myprizes,
        });

        if (card != null) {
          this.props.bmarkenAPI
            .getProductCampaignPrizes(
              card.campaign_id,
              card.id,
              this.props.firebase.doRefreshToken
            )
            .then((prizes) => {
              if (prizes !== null) {
                const l = prizes.length;
                const amzPrize = prizes.pop();
                prizes.unshift(amzPrize);
                let mapPrizeInfo = {};
                for (let i = 0; i < l; i++) {
                  const p = {
                    info: prizes[i].prize_info,
                    image: prizes[i].prize_campaign.image_url,
                  };
                  mapPrizeInfo[prizes[i].prize_campaign.id] = p;
                }
                this.setState({
                  prizes: prizes,
                  mapPrizeInfo: mapPrizeInfo,
                  loading: false,
                });
              } else {
                this.setState({ prizes: [], loading: false });
              }
            })
            .catch((error) => {
              this.setState({ loading: false });
              error = translateError(error);
              this.context.dispatchMessage(error.message, "error");
            });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  componentDidMount() {
    this.getPrizes();
  }

  renderStatus = (status, date, dbu) => {
    let color;
    let title;
    const isEnabled = this.checkIfEnabled(date, dbu);

    if (status == 0 && isEnabled) {
      color = "green";
      title = "UTILIZZABILE";
    } else if (status == 0 && !isEnabled) {
      color = "orange";
      title = "IN ATTESA VALIDAZIONE";
    } else if (status == 3) {
      color = "red";
      title = "UTILIZZATO";
    } else {
      color = "red";
      title = "NON UTILIZZABILE";
    }

    return <div style={{ color, fontSize: "14px" }}>{title}</div>;
  };

  checkIfEnabled = (date, daysBeforeUse) => {
    const created = moment(date);
    const today = moment();
    return today.diff(created, "days") >= daysBeforeUse;
  };

  elencoMieiPremi = () => {
    return (
      this.state.myprizes.length && (
        <main>
          <List>
            {this.state.myprizes.map((myprize) => {
              const prizeInfo = this.state.mapPrizeInfo[myprize.campaign_id]
                .info;
              const image = this.state.mapPrizeInfo[myprize.campaign_id].image;
              const urlBusiness =
                "/ricerca-fornitori/fornitore/" + myprize.business_id;
              const productType = myprize.subtype;

              //controllo stato del premio e scelgo label
              let disabled = true;
              let label = "UTILIZZA";
              let funct = this.selectMyPrizeQrcode;
              if (productType === "AMAZON_COUPON") {
                label = "RISCATTA";
                if (true) {
                  //DEBUG
                  disabled = false;
                  if (myprize.status == 3) {
                    label = "VEDI";
                    funct = this.showAmazonGiftcard;
                  } else {
                    disabled = !this.checkIfEnabled(
                      myprize.created_at,
                      myprize.days_before_use
                    );
                    funct = this.burnCoupon;
                  }
                } else {
                  funct = this.burnCoupon;
                }
              } else {
                disabled =
                  !this.checkIfEnabled(
                    myprize.created_at,
                    myprize.days_before_use
                  ) || myprize.status == 3;
                if (
                  myprize.campaign_name == "Abbonamento BlueTorino per un anno"
                ) {
                  label = "Vedi";
                  funct = this.showBlueTorinoCode;
                }
              }

              return (
                <Card key={myprize.id} style={{ margin: "20px" }}>
                  <CardHeader
                    style={{ display: "block" }}
                    action={this.renderStatus(
                      myprize.status,
                      myprize.created_at,
                      myprize.days_before_use
                    )}
                  />
                  <CardContent>
                    <div style={{ width: "290px", margin: "0 auto" }}>
                      <div
                        title={myprize.name}
                        className="img-responsive img-thumbnail ratio-4-3"
                        style={{
                          cursor: "pointer",
                          backgroundImage: "url(" + this.getImg(image) + ")",
                        }}
                      ></div>
                    </div>
                    <div style={{ margin: "15px 0" }}>
                      <div
                        className="firstLetterUppercase"
                        style={{ ...redTextStyle, padding: "0px" }}
                      >
                        {myprize.campaign_name}
                      </div>
                    </div>

                    {[
                      {
                        label: "Ricevuto in data:",
                        content: moment(myprize.created_at).format("DD-MM-YY"),
                      },
                      {
                        label: "Utilizzabile dal:",
                        content: moment(myprize.created_at)
                          .add(8, "days")
                          .format("DD-MM-YY"),
                      },
                    ].map((el) => {
                      return (
                        <div
                          key={myprize.id + el.label}
                          style={{
                            display: "inline-block",
                            justifyContent: "center",
                            fontSize: "13px",
                            textAlign: "left",
                            marginBottom: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <span style={{ width: "110px", fontWeight: "bold" }}>
                            {el.label}
                          </span>
                          <span style={{ width: "250px", margin: "0 5px" }}>
                            {el.content}
                          </span>
                        </div>
                      );
                    })}

                    <div style={{ fontSize: "14px" }}>
                      <a target="_blank" href={urlBusiness}>
                        {myprize.business_name}
                      </a>
                    </div>

                    <div>
                      <a
                        style={{
                          fontSize: "14px",
                          color: "gray",
                          cursor: "pointer",
                        }}
                        onClick={() => this.showMore(myprize.id)}
                      >
                        Come utilizzare il premio
                      </a>
                      <div
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          marginTop: "8px",
                        }}
                      >
                        {this.state.showMore[myprize.id] ? (
                          <div>{prizeInfo.instructions}</div>
                        ) : null}
                      </div>
                    </div>
                  </CardContent>
                  {/*<div style={{display: 'block', justifyContent: 'space-between', padding: '10px'}}>
									{
										productType === "AMAZON_COUPON"
										?(
											<Button disabled={!this.checkIfEnabled(myprize.created_at) || myprize.status == 3} size="small" variant="outlined" color="primary" onClick={() => this.burnCoupon(myprize)}>
												Riscatta
											</Button>
										):(
											<Button disabled={!this.checkIfEnabled(myprize.created_at) || myprize.status == 3} size="small" variant="outlined" color="primary" onClick={() => this.selectMyPrizeQrcode(myprize)}>
												Utilizza
											</Button>
										)
									}

									{
										productType === "AMAZON_COUPON" && myprize.status == 3
										?(
											<Button disabled={!this.checkIfEnabled(myprize.created_at) || myprize.status == 3} size="small" variant="outlined" color="primary" onClick={() => this.showAmazonGiftcard(myprize.id)}>
												Vedi
											</Button>
										):null
									}
								</div>*/}

                  <div
                    style={{
                      display: "block",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Button
                      disabled={disabled}
                      size="small"
                      variant="outlined"
                      color="primary"
                      onClick={() => funct(myprize)}
                    >
                      {label}
                    </Button>
                  </div>
                </Card>
              );
            })}
          </List>
        </main>
      )
    );
  };

  getImg = (img) => {
    //console.log("img-")
    //console.log(img)
    img = img && img != "" ? premiIMG[img] : premiIMG.prize_default;
    //console.log(img)
    return img;
  };

  elencoPremi = () => {
    return (
      <div className="elencoPremi" style={centeredText}>
        {this.state.myprizes && this.state.myprizes.length > 0 ? (
          <Button
            onClick={() => this.setState({ activeView: "UserPrizes" })}
            variant="outlined"
            color="primary"
            style={{ ...buttonStyle, marginBottom: "20px" }}
            variant="contained"
          >
            I tuoi premi riscattati
          </Button>
        ) : null}
        <div
          style={{
            ...centeredBoldParagraph,
            color: "#dc005a",
            marginBottom: "2px",
          }}
        >
          Hai a disposizione {this.state.points} punti:
        </div>
        <div style={grayTextStyle}>scegli un premio tra i seguenti</div>
        <div>
          <GridList
            className="premi-list-bordered"
            style={{
              width: "100%",
              maxWidth: "500px",
              margin: "0 auto",
              padding: 10,
            }}
            cols={2}
          >
            {this.state.prizes.map((prize) => {
              return (
                <GridListTile
                  key={prize.prize_info.id}
                  onClick={() => this.selectPrize(prize)}
                  style={{ height: "100%" }}
                >
                  <Paper
                    style={{ padding: 0, margin: 15, display: "flex" }}
                    square={true}
                  >
                    <div
                      title={prize.name}
                      className="img-responsive img-thumbnail ratio-4-3"
                      style={{
                        cursor: "pointer",
                        backgroundImage:
                          "url(" +
                          this.getImg(prize.prize_campaign.image_url) +
                          ")",
                      }}
                    ></div>
                    {/* 
								<img
									width="100%" height="150px"
									src={this.getImg(prize.prize_campaign.image_url)}
									style={{cursor: "pointer"}}
									alt={prize.name}
								/>
								*/}
                  </Paper>
                  <div className="box-list-premio-desc">
                    <div
                      className="firstLetterUppercase"
                      style={{ ...redTextStyle, padding: "0px" }}
                    >
                      {prize.prize_info.name}
                    </div>
                    <div style={{ ...grayTextStyle, fontSize: "15px" }}>
                      {prize.prize_info.description}
                    </div>
                  </div>
                  <div className="redCircle" style={{ marginTop: 5 }}>
                    <div
                      className="centerCircle"
                      style={{ color: "#ffffff", fontSize: "16px" }}
                    >
                      {parseBmeIntToInt(prize.prize_info.threshold)}
                      <br />
                      punti
                    </div>
                  </div>
                </GridListTile>
              );
            })}
          </GridList>
        </div>
      </div>
    );
  };

  viewBTCode = () => {
    return (
      <div style={centeredText}>
        <Paper style={{ padding: 14, marginBottom: 20 }} elevation={10}>
          <div style={{ ...redTextStyle, margin: "4px" }}>
            Codice Promozionale BlueTorino
          </div>
          <div style={{ ...grayTextStyle, marginBottom: "10px" }}>
            {this.state.infoGiftcard.code}
          </div>
          <br />
          <Button
            onClick={() => this.goBack()}
            style={{ ...buttonStyle, margin: 20 }}
            variant="outlined"
            color="primary"
          >
            Indietro
          </Button>
        </Paper>
      </div>
    );
  };

  viewGiftcard = () => {
    return (
      <div style={centeredText}>
        <Paper style={{ padding: 14, marginBottom: 20 }} elevation={10}>
          <div style={{ ...redTextStyle, margin: "4px" }}>Giftcard Amazon</div>
          <div style={{ ...grayTextStyle, marginBottom: "10px" }}>
            Valore (Euro): {parseBmeIntToFloat(this.state.infoGiftcard.amount)}
            <br />
            Claim Code: {this.state.infoGiftcard.gcClaimCode}
          </div>
          <br />
          <Button
            onClick={() => this.goBack()}
            style={{ ...buttonStyle, margin: 20 }}
            variant="outlined"
            color="primary"
          >
            Indietro
          </Button>
        </Paper>
      </div>
    );
  };

  sceltaPremio = () => {
    const urlBusiness =
      "/ricerca-fornitori/fornitore/" +
      this.state.selectedPrize.prize_campaign.business_id;
    return (
      <div style={centeredText}>
        <Paper style={{ padding: 14, marginBottom: 20 }} elevation={10}>
          <div
            title={this.state.selectedPrize.name}
            className="img-responsive img-thumbnail ratio-4-3"
            style={{
              backgroundImage:
                "url(" +
                this.getImg(this.state.selectedPrize.prize_campaign.image_url) +
                ")",
            }}
          ></div>

          <div style={{ ...redTextStyle, margin: "4px" }}>
            {this.state.selectedPrize.prize_info.name}
          </div>
          <div style={{ ...grayTextStyle, marginBottom: "10px" }}>
            {this.state.selectedPrize.prize_info.description}
          </div>
          <div style={{ fontSize: "12px" }}>
            <SanitizedHtmlContent
              className="htmlContent"
              content={this.state.selectedPrize.prize_info.extended_description}
            />
          </div>

          <br />
          <div style={{ fontSize: "14px" }}>
            <a target="_blank" href={urlBusiness}>
              {this.state.selectedPrize.prize_campaign.business_name}
            </a>
          </div>
        </Paper>
        {this.state.enableGetPrize ? (
          <div>
            <div style={{ ...redTextStyle, padding: "10px" }}>
              Controlla di aver scelto il premio giusto e che i punti da scalare
              siano corretti
            </div>
            <div style={{ display: "inline-block" }}>
              <Table style={{ width: "300px" }}>
                <TableBody>
                  <TableRow>
                    <TableCell style={cellStyle}>
                      Punti a disposizione:
                    </TableCell>
                    <TableCell style={cellStyle}>{this.state.points}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={cellStyle}>
                      Valore del premio scelto:
                    </TableCell>
                    <TableCell style={cellStyle}>
                      -
                      {parseBmeIntToInt(
                        this.state.selectedPrize.prize_info.threshold
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={cellStyle}>Punti rimanenti:</TableCell>
                    <TableCell style={cellStyle}>
                      {this.state.points -
                        parseBmeIntToInt(
                          this.state.selectedPrize.prize_info.threshold
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <div style={{ ...grayTextStyle, display: "inline-block" }}>
              Cliccando il tasto conferma riceverai il premio che potrà essere
              utilizzato dopo aver atteso ALMENO 7 GIORNI
            </div>
            <Button
              onClick={this.askConfirmation}
              style={{ ...buttonStyle, margin: 20 }}
              variant="outlined"
              color="primary"
            >
              Conferma
            </Button>
          </div>
        ) : (
          <div>
            <div style={{ ...redTextStyle, padding: "10px" }}>
              Non hai ancora abbastanza punti!
            </div>
            <div style={{ ...grayTextStyle, display: "inline-block" }}>
              Mancano ancora {this.state.diff} punti per scegliere questo premio
            </div>
            <Button
              onClick={() =>
                this.setState({
                  selectedPrize: null,
                  diff: 0,
                  activeView: "main",
                  enableGetPrize: false,
                })
              }
              style={{ ...buttonStyle, margin: 20 }}
              variant="outlined"
              color="primary"
            >
              Indietro
            </Button>
          </div>
        )}

        <ConfirmPopUp
          open={this.state.openConfirm}
          onClose={() => this.setState({ openConfirm: false })}
          onConfirm={() => this.choosePrize()}
          text="Confermi la richiesta del premio?"
          title="Conferma"
        />
      </div>
    );
  };

  qrCodeView = () => (
    <QRCodePrize
      qrcode={this.state.permid}
      onRefreshCode={this.showMyPrizeQrcode}
    />
  );

  showAmazonGiftcard = (coupon) => {
    this.setState({ loading: true });
    this.props.bmarkenAPI
      .getAmazonGiftcardInfo(coupon.id, () =>
        this.props.firebase.doRefreshToken()
      )
      .then((infoGiftcard) => {
        this.setState({
          infoGiftcard: infoGiftcard,
          activeView: "AmazonGiftcard",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  showBlueTorinoCode = (coupon) => {
    this.setState({ loading: true });
    this.props.bmarkenAPI
      .getBlueTorinoCode(coupon.id, () => this.props.firebase.doRefreshToken())
      .then((infoGiftcard) => {
        this.setState({
          infoGiftcard: infoGiftcard,
          activeView: "BlueTorino",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  viewController = () => {
    if (this.state.activeView === "ViewPrize") {
      return this.sceltaPremio();
    }
    if (this.state.activeView === "QRCode") {
      return this.qrCodeView();
    }
    if (this.state.activeView === "UserPrizes") {
      return this.elencoMieiPremi();
    }
    if (this.state.activeView === "AmazonGiftcard") {
      return this.viewGiftcard();
    }
    if (this.state.activeView === "BlueTorino") {
      return this.viewBTCode();
    }
    return this.elencoPremi();
  };

  goBack = () => {
    if (this.state.activeView === "QRCode") {
      this.setState({ permid: null, activeView: "main" });
    } else if (this.state.activeView === "ViewPrize") {
      this.setState({ selectedPrize: null, activeView: "main" });
    } else if (this.state.activeView === "UserPrizes") {
      this.setState({ activeView: "main" });
    } else if (this.state.activeView === "AmazonGiftcard") {
      this.setState({ activeView: "main", infoGiftcard: null });
    } else {
      this.props.history.goBack();
    }
  };

  selectPrize = (prize) => {
    const diff =
      parseBmeIntToInt(prize.prize_info.threshold) - this.state.points;
    let enabled = false;
    if (diff <= 0) {
      enabled = true;
    }

    //DEBUG - finche amazon non è abilitato se il premio è di amazon mostro un messaggio - ABILITATO IN TEST
    /*console.log(prize)
		if(prize.prize_info.name == "Una giftcard Amazon"){
			this.context.dispatchMessage("Questo premio sarà disponibile a breve!", 'error')
			return
		}*/

    this.setState({
      selectedPrize: prize,
      diff: diff,
      activeView: "ViewPrize",
      enableGetPrize: enabled,
    });

    /*
		const diff = parseBmeIntToInt(prize.prize_info.threshold) - this.state.points
		if (diff <= 0) {
			this.setState({selectedPrize: prize, activeView:"ViewPrize"})
		} else {
			const txt = "Mancano ancora " + diff + " punti per scegliere questo premio"
			this.context.dispatchMessage(txt, 'error')
		}*/
  };

  selectMyPrizeQrcode = (myprize) => {
    this.setState({ myprizeid: myprize.id });
    this.showMyPrizeQrcodeFromId(myprize.id);
  };

  burnCoupon = (coupon) => {
    this.setState({ loading: true });
    this.props.bmarkenAPI
      .burnAmazonCoupon(coupon.id, () => this.props.firebase.doRefreshToken())
      .then((infoGiftcard) => {
        this.setState({
          infoGiftcard: infoGiftcard,
          activeView: "AmazonGiftcard",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  showMyPrizeQrcodeFromId = (myprizeid) => {
    this.setState({ loading: true });
    this.props.bmarkenAPI
      .getProductQrcode(myprizeid, () => this.props.firebase.doRefreshToken())
      .then((perm) => {
        this.setState({
          permid: perm.id,
          activeView: "QRCode",
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  showMyPrizeQrcode = () => {
    this.showMyPrizeQrcodeFromId(this.state.myprizeid);
  };

  askConfirmation = () => {
    this.setState({
      openConfirm: true,
    });
  };

  choosePrize = () => {
    this.setState({ loading: true, openConfirm: false });
    const cardId = this.state.card.id;
    const prizeId = this.state.selectedPrize.prize_info.id;

    this.props.bmarkenAPI
      .issueProductPrize(cardId, prizeId, this.props.firebase.doRefreshToken)
      .then(() => {
        // premio convertito con successo, aggiorno i dati della carta
        this.props.bmarkenAPI
          .getUserProductDefault(this.props.firebase.doRefreshToken)
          .then((card) => {
            console.log(card);
            const vpoints =
              card !== null ? parseBmeIntToInt(card.available_value) : 0;
            this.setState({
              points: vpoints,
              card: card,
            });
            console.log(this.state.selectedPrize);
            const txt1 =
              'Complimenti, nella sezione I TUOI PREMI RISCATTATI è disponibile il tuo nuovo premio: "' +
              this.state.selectedPrize.prize_info.name +
              '"';
            const txt2 =
              vpoints > 0 ? ". Hai ancora " + vpoints + " punti" : "";
            this.context.dispatchMessage(txt1 + txt2, "notify");
            //this.props.history.push({ pathname: ROUTES.base});
            this.setState({
              loading: false,
              selectedPrize: null,
              diff: 0,
              activeView: "main",
              enableGetPrize: false,
            });
            this.getPrizes();
          })
          .catch((error) => {
            this.setState({ loading: false });
            error = translateError(error);
            this.context.dispatchMessage(error.message, "error");
          });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  render() {
    return (
      <div style={centeredText}>
        <Topbar history={this.props.history} goBack={() => this.goBack()} />
        {this.viewController(this.state.activeView)}
        <LoadingOverlay show={this.state.loading} />
        <Bottombar from={this.props.location.pathname} />
      </div>
    );
  }
}

export default withFirebase(withBmarkenAPI(Premi));
