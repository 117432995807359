import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
//import { useMediaQuery } from "@material-ui/core";
import { useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";

const ImagesCarousel = (props) => {
  const [sliderRef] = useKeenSlider({
    mode: "free-snap",
    loop: true,
    slides: {
      origin: "center",
      perView: props?.images?.length === 1 ? 1 : 1.2,
      spacing: 0,
    },
  });
  const [open, setOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);

  //const breakPoints = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const handleImageClick = (img) => {
    setOpen(true);
    setImagePreview(img);
  };

  const handleClose = () => {
    setOpen(false);
    setImagePreview(null);
  };

  return (
    <React.Fragment>
      <div ref={sliderRef} className="keen-slider">
        {props.images
          ?.sort((a, b) => {
            if (a.media_subtype === "cover") return -1;
            if (b.media_subtype === "cover") return 1;
            return 0;
          })
          .map((img) => (
            <div
              className="keen-slider__slide"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                //height: breakPoints ? "300px" : "200px",
                maxHeight: "300px",
              }}
            >
              <img
                src={img.url}
                style={{ width: "100%" }}
                onClick={() => handleImageClick(img.url)}
              />
            </div>
          ))}
      </div>
      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth // added
        BackdropProps={{ style: { backgroundColor: "rgba(0, 0, 0, 0.7)" } }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            margin: 5,
          },
        }}
      >
        <DialogContent style={{ padding: 0 }}>
          {imagePreview && (
            <img src={imagePreview} alt="Preview" width="100%" />
          )}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ImagesCarousel;
