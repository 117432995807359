import React, { useState, useContext } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { SharedContext } from "../utils/Session";
import moment from "moment";
import {
  fullMobileWidth,
  centeredBoldParagraph,
  buttonStyle,
  centeredText,
} from "../styles/helpers";
import {
  parseBmeIntToInt,
  parseBmeIntToFloat,
  translateAction,
  traslateStatus,
  translateError,
} from "../utils/utils";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import LoadingOverlay from "./LoadingOverlay";
import { withBmarkenAPI } from "../utils/Api";
import { withFirebase } from "../utils/Firebase";

const ActionsTable = (props) => {
  const [view, setView] = useState("main");
  const [ra, setRa] = useState(null);
  const [user, setUser] = useState(null);
  const [title, setTitle] = useState(props.title);
  const [submitLoading, setSubmitLoading] = useState(false);
  const merchantStatsView = props.merchantStatsView;
  const { dispatchMessage } = useContext(SharedContext);

  const showDetails = (ra) => {
    if (ra.type === "GIFT") return;
    if (ra.type === "PROFILE50") return;

    setSubmitLoading(true);
    props.bmarkenAPI
      .getUser(ra.user_id, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then((u) => {
        setUser(u);

        if (props.updateUserPhoto && props.updateUserPhoto != null) {
          props.updateUserPhoto(u);
        }
        setSubmitLoading(false);
        setRa(ra);
        setView("detail");
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const backToMainView = () => {
    if (props.updateUserPhoto && props.updateUserPhoto != null) {
      props.updateUserPhoto(null);
    }

    setView("main");
  };

  const getIconColor = (status) => {
    let color;
    switch (status) {
      case "APPROVED":
        color = "green";
        break;
      case "CANCELED":
        color = "red";
        break;
      default:
        color = "orange";
    }

    return color;
  };

  const mainView = () => (
    <div style={fullMobileWidth}>
      {title}
      <Card
        style={{
          marginTop: "25px",
          paddingLeft: "5px",
          paddingRight: "5px",
          overflow: "scroll",
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell style={{ fontWeight: "bold" }}>DATA</TableCell>
              {merchantStatsView ? (
                <TableCell style={{ fontWeight: "bold" }}>USER</TableCell>
              ) : (
                <TableCell style={{ fontWeight: "bold" }}>FORNITORE</TableCell>
              )}
              <TableCell style={{ fontWeight: "bold" }}>AZIONE</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>P.TI</TableCell>
            </TableRow>
          </TableHead>
          {props.actions.length > 0 ? (
            <TableBody>
              {props.actions.map((act) => (
                <TableRow
                  onClick={() => showDetails(act)}
                  key={act.id}
                  style={{ cursor: "pointer" }}
                  hover={true}
                >
                  <TableCell align="right">
                    {act.business_name == "SposiClub.it" ? (
                      <span></span>
                    ) : (
                      <span
                        className={"info-circle " + getIconColor(act.status)}
                      ></span>
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(getDate(act)).format("DD/MM/YY")}
                  </TableCell>
                  {merchantStatsView ? (
                    <TableCell>
                      {act.user_name + " " + act.user_email}
                    </TableCell>
                  ) : (
                    <TableCell>{act.business_name}</TableCell>
                  )}
                  <TableCell>{translateAction(act.type)}</TableCell>
                  <TableCell align="right">
                    {parseBmeIntToInt(act.reward)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={4}>
                  Non hai ancora effettuato interazioni
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Card>

      <div className="legenda-punti">
        <div>
          <span className="info-circle green"></span> Punti raddoppiati
        </div>
        <div>
          <span className="info-circle orange"></span> Punti da raddoppiare
        </div>
        <div>
          <span className="info-circle red"></span> Interazione annullata
        </div>
      </div>
      <LoadingOverlay show={submitLoading} />
    </div>
  );

  const detailView = () => (
    <div style={fullMobileWidth}>
      <div
        style={{
          ...centeredBoldParagraph,
          color: "#9CA1A1",
          marginBottom: "2px",
        }}
      >
        Ecco il dettaglio
        <br />
        della tua interazione
      </div>
      <Card>
        <CardContent>
          {ra !== null ? (
            <div style={{ fontSize: "13px", color: "#8b8b8b" }}>
              {[
                { name: "DATA", datum: moment(getDate(ra)).format("DD-MM-YY") },
                { name: "FORNITORE", datum: ra.business_name },
                { name: "PUNTI", datum: parseBmeIntToInt(ra.reward) },
                { name: "DETTAGLIO", datum: getDetail(ra) },
                { name: "STATO", datum: traslateStatus(ra.status) },
              ].map((el, index) => [
                <div
                  key={el.name}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ fontWeight: "bold" }}>{el.name}:</div>
                  <div>{el.datum}</div>
                </div>,
                index !== 4 && (
                  <Divider
                    style={{ margin: "15px 0px 15px 0px" }}
                    key={`${el.name}-div`}
                  />
                ),
              ])}
            </div>
          ) : null}
        </CardContent>
      </Card>

      <br />

      {props.showScanner !== null &&
      ra.status === "PENDING" &&
      ra.type !== "FAIR" &&
      ra.type != "REVIEW" ? (
        <div>
          <div
            style={{ ...centeredText, color: "#9CA1A1", marginBottom: "2px" }}
          >
            I punti della tua interazione non sono ancora stati raddoppiati:
            recati presso il punto vendita e scansiona il codice del negozio
            <br />
            COSI’ POTRAI RADDOPPIARE I TUOI PUNTI
          </div>

          <Button
            onClick={() => {
              console.log(ra);
              props.showScanner(ra.id);
            }}
            style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
            variant="outlined"
            color="primary"
          >
            Scansiona
          </Button>
          <br />
        </div>
      ) : null}

      {ra.status === "CANCELED" ? (
        <div>
          L'interazione è stata cancellata e con essa i punti attribuiti.
          Contatta l'assistenza per ulteriori informazioni
          <br />
        </div>
      ) : null}

      <Button
        onClick={() => setView("main")}
        style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
        variant="outlined"
        color="primary"
      >
        Indietro
      </Button>
      <LoadingOverlay show={submitLoading} />
    </div>
  );

  const detailMerchantView = () => {
    const weddingDate = moment(user.custom_fields.wedding_date).format(
      "DD-MM-YY"
    );
    let province = "";
    if (user.custom_fields) {
        try {
            const cf = JSON.parse(user.custom_fields);
            province = cf.wedding_province;
        } catch(e) {
        }
    }
    return (
      <div style={fullMobileWidth}>
        <div
          style={{
            ...centeredBoldParagraph,
            color: "#9CA1A1",
            marginBottom: "2px",
          }}
        >
          Ecco il dettaglio
          <br />
          della tua interazione
        </div>
        <Card>
          <CardContent>
            {ra !== null ? (
              <div style={{ fontSize: "13px", color: "#8b8b8b" }}>
                {[
                  {
                    name: "DATA",
                    datum: moment(getDate(ra)).format("DD-MM-YY"),
                  },
                  { name: "USER", datum: ra.user_name },
                  { name: "EMAIL", datum: ra.user_email },
                  { name: "TELEFONO", datum: user.mobile },
                  { name: "DATA MATRIMONIO", datum: weddingDate },
                  { name: "PROVINCIA MATRIMONIO", datum: province },
                  { name: "PUNTI", datum: parseBmeIntToInt(ra.reward) },
                  { name: "DETTAGLIO", datum: getDetail(ra) },
                  { name: "STATO", datum: traslateStatus(ra.status) },
                ].map((el, index) => [
                  <div
                    key={el.name}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontWeight: "bold" }}>{el.name}:</div>
                    <div>{el.datum}</div>
                  </div>,
                  index !== 7 && (
                    <Divider
                      style={{ margin: "15px 0px 15px 0px" }}
                      key={`${el.name}-div`}
                    />
                  ),
                ])}
              </div>
            ) : null}
          </CardContent>
        </Card>

        <br />

        {props.showQRCode !== null &&
        ra.status === "PENDING" &&
        ra.type !== "FAIR" &&
        ra.type != "REVIEW" ? (
          <div>
            <div
              style={{ ...centeredText, color: "#9CA1A1", marginBottom: "2px" }}
            >
              I punti della tua interazione non sono ancora stati riconosciuti e
              raddoppiati dal fornitore: puoi farla riconoscere recandoti presso
              il suo punto vendita e facendo leggere il codice.
              <br />
              COSI’ POTRAI RADDOPPIARE I TUOI PUNTI
            </div>

            <Button
              onClick={() => props.showQRCode(ra.id)}
              style={{
                ...buttonStyle,
                marginTop: "25px",
                marginBottom: "20px",
              }}
              variant="outlined"
              color="primary"
            >
              Mostra Codice
            </Button>
            <br />
          </div>
        ) : null}

        {ra.status === "CANCELED" ? (
          <div>
            L'interazione è stata cancellata e con essa i punti attribuiti.
            Contatta l'assistenza per ulteriori informazioni
            <br />
          </div>
        ) : null}

        <Button
          onClick={() => backToMainView()}
          style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
          variant="outlined"
          color="primary"
        >
          Indietro
        </Button>
        <LoadingOverlay show={submitLoading} />
      </div>
    );
  };

  const getDate = (ra) => {
    if (ra.type === "FAIR" || ra.type === "REVIEW") {
      return ra.created_at;
    } else if (ra.type === "ENTRY") {
      return ra.created_at;
    }
    return ra.action_date;
  };

  const getDetail = (ra) => {
    if (ra.type === "FAIR") {
      return "Stand operatore: " + ra.business_name;
    } else if (ra.type === "PURCHASE") {
      return parseBmeIntToFloat(ra.expense) + "€";
    } else if (ra.type === "REVIEW") {
      return ra.comment;
    } else {
      return "-";
    }
  };

  switch (view) {
    case "detail":
      return merchantStatsView ? detailMerchantView() : detailView();
    default:
      return mainView();
  }
};

export default withFirebase(withBmarkenAPI(ActionsTable));
