import React, { useState, useContext, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List';
import LoadingOverlay from '../common_components/LoadingOverlay'
import { fullMobileWidth, boldCenteredText, buttonStyle } from '../styles/helpers'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { translateError } from '../utils/utils'
import Topbar from '../common_components/Topbar'
import moment from "moment";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import FormFiera from '../common_components/FormFiera';
import { SharedContext } from '../utils/Session';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const GestioneFiere = (props) => {

    const [view, setView] = useState("main")
    const [fiere, setFiere] = useState([])
    const [loading, setLoading] = useState(false)
    const [fieraToUpdate, setFieraToUpdate] = useState(null)
    const { dispatchMessage } = useContext(SharedContext);

    
    useEffect(() => {
        getFairs()
    }, []);

    const getFairs = () => {
        setLoading(true)
        props.bmarkenAPI.getContents("FAIR", () => props.firebase.doRefreshToken())
        .then(f => {
            setLoading(false)
            if(f !== null)
                setFiere(f)
            else
                setFiere([])
        })
        .catch(error => {
            setLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const deleteFairs = (id) => {
        setLoading(true)

        props.bmarkenAPI.deleteContent(id, () => {
            return props.firebase.doRefreshToken(true)
        })
        .then(() => {
            getFairs()
            setLoading(false)
            dispatchMessage("Fiera rimossa con successo", 'info')

        })
        .catch(error => {
            setLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const handleChangeStatusContent = id => event => {

        let status = event.target.checked
        setLoading(true)

        props.bmarkenAPI.updateContentStatus(id, status, () => {
            return props.firebase.doRefreshToken(true)
        })
        .then((c) => {
            setLoading(false)
            getFairs()
        })
        .catch(error => {
            setLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    };

    const updateFair = (f) => {
        setFieraToUpdate(f)
        setView("update")
    }

    const cancel = () => {
        getFairs()
        setView("main")
    }

    const createView = () => {
        return (
            <div>
                <FormFiera cancel={cancel} />
            </div>
        )
    }

    const updateView = () => {
        return (
            <div>
                <FormFiera cancel={cancel} fair={fieraToUpdate} />
            </div>
        )
    }

    const mainView = () => {
        return (
            <div style={{...boldCenteredText}}>
                <main >
                    <List>
                    {
                        fiere.map(f => {
                            return(
                                <Card key={f.id} style={{margin:'20px'}}>
                                    <CardHeader
                                        action={
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={f.active}
                                                        onChange={handleChangeStatusContent(f.id)}
                                                        value={f.id}
                                                    />
                                                }
                                                label={f.active ? "Fiera attiva" : "Fiera disattiva"}
                                            />
                                        }
                                    />
                                    <CardMedia
                                        style={{height: 185}}
                                        image={f.url}
                                        title={f.title}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h6" display="block">
                                            ID Fiera: {f.id}
                                        </Typography>
                                        <Typography gutterBottom variant="caption">
                                            Non verrà più mostrata dopo il: {moment(f.expiration_date).format("DD-MM-YYYY")}
                                        </Typography>
                                        <Typography gutterBottom variant="h5" component="h2" style={{marginTop: '10px', marginBottom: '15px'}}>
                                            {f.title}
                                        </Typography>
                                        <Typography component="p">
                                            { f.description.substring(0,300) + '...' }
                                        </Typography>
                                    </CardContent>
                                    <div style={{display: 'flex', justifyContent: 'space-between', padding: '15px'}}>
                                        <Button size="small" color="primary" onClick={() => deleteFairs(f.id)}>
                                            Cancella
                                        </Button>
                                        <Button size="small" color="primary" onClick={() => updateFair(f) }>
                                            Modifica
                                        </Button>
                                    </div>
                                </Card>
                            )
                        })
                    }
                    </List>
                </main>
                <Button onClick={() => setView("create")} style={buttonStyle} variant="outlined" color="primary">
                    Crea
                </Button>
                <LoadingOverlay show={loading} />
            </div>
        )
    }

    const viewController = (view) => {
        switch(view){
            case "create": return createView();
            case "update": return updateView();
            default: return mainView();
        }
    }

    const goBack = () => {
        if(view === "main") props.history.goBack()
        else if(view === "create" || view === "update") setView("main")
    }

    return (
        <div>
            <Topbar goBack={() => goBack()}/>
            <div style={fullMobileWidth}>
                {viewController(view)}
            </div>
        </div>
    )
}

export default withFirebase(withBmarkenAPI(GestioneFiere))