import React from "react";
import acquistoBG from "../styles/images/nuovo_acquisto.jpg";
import visitaBG from "../styles/images/nuovo_visita.jpg";
import recensioneBG from "../styles/images/ragazza_recensione.jpg";
import fieraBG from "../styles/images/fiera.jpg";
import landingBG from "../styles/images/landing.jpg";
import homeBG from "../styles/images/nuovo_home.jpg";
import genericBG from "../styles/images/sfondo_gen.jpg";

const backGroundMap = {
  acquisto: genericBG, //acquistoBG,
  visita: genericBG, //visitaBG,
  fiera: genericBG, //fieraBG,
  recensione: genericBG, //recensioneBG,
  landing: genericBG, //landingBG,
  home: genericBG, //homeBG
};

const backGroundComponent = (props) => (
  <div>
    <div
      style={{
        position: "fixed",
        zIndex: -1,
        width: "100vw",
        height: "100vh",
        top: "0",
        left: "0",
        //backgroundColor: "#7d0231",
        backgroundColor: "#fff",
        backgroundImage:
          props.background === "home"
            ? null
            : `url(${backGroundMap[props.background]})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    />
  </div>
);

export default backGroundComponent;
