
import React from 'react'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import LogoHeader from '../common_components/LogoHeader'
import { fullMobileWidth, boldCenteredText } from '../styles/helpers'
import SposiIcon from '../common_components/SposiIcon'
import { ROUTES } from '../constants'
import Ricerca from './Ricerca'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { centeredText, centeredBoldParagraph, grayParagraph } from '../styles/helpers'
import ActionButtons from '../common_components/ActionButtons'
import MerchantShortInfo from '../common_components/MerchantShortInfo';
import ActionForms from '../common_components/ActionForms';
import ActionSuccessPage from '../common_components/ActionSuccessPage';
import HandleUserRedirect from '../helpers/HandleUserRedirect'
import BackGroundComponent from '../common_components/BackGroundComponent'
import { goBackFromView, translateError } from '../utils/utils';
import { SharedContext } from '../utils/Session';
import ConfirmPopUp from '../common_components/ConfirmPopUp'
import LoadingOverlay from '../common_components/LoadingOverlay'
import SposiLoading from '../common_components/SposiLoading'
import Button from '@material-ui/core/Button'
import { Route, Switch } from "react-router-dom"
const moment = require('moment');


class Recensione extends React.PureComponent {
  static contextType = SharedContext

  constructor(props) {
      super(props);

      this.handleTextChange = this.handleTextChange.bind(this);
      this.handleDateChange = this.handleDateChange.bind(this);

      this.state = {
        loading: false,
        activeView: "main",
        selectedMerchant: null,
        selectedAction: "REVIEW",
        openConfirm: false,

        visitDate: moment(),
        purchaseDate: moment(),
        visitReason: "",
        purchaseValue: 0,
        review: "",

        rewardingAction: null,

        reward:50,
    };
  }

  componentDidMount() {
    /*
    var url = new URL(window.location.href);
    var merchantID = url.searchParams.get("merchantID");
  
    if(merchantID && merchantID!==""){
        this.setState({loading:true})
        this.props.bmarkenAPI.getMerchant(merchantID, () => this.props.firebase.doRefreshToken())
        .then(m => {
            this.setState({
              selectedMerchant: m,
              activeView: "form", 
              loading:false
            })
        })
        .catch(error => {
            this.setState({loading: false })
            error = translateError(error)
            this.context.dispatchMessage(error.message, 'error')
        })
    }*/
    const path = this.props.history.location.pathname.split("/")
    if (path.length > 2 && path[path.length-2] == "operatore" && path[path.length-1]) {
      const merchantID = path[path.length-1]
      this.setState({loading:true})
      this.props.bmarkenAPI.getMerchant(merchantID, () => this.props.firebase.doRefreshToken())
      .then(m => {
          this.setState({
            selectedMerchant: m,
            activeView: "form", 
            loading:false
          })
      })
      .catch(error => {
          this.setState({loading: false })
          error = translateError(error)
          this.context.dispatchMessage(error.message, 'error')
      })
    }
  }

  handleTextChange = event => {
    this.setState({ [event.target.name]: event.target.value });
    //console.log(this.state)
  };

  handleDateChange = (dateName, dateValue) => {
    this.setState({ [dateName]: dateValue });
    //console.log(this.state)
  }

  onSelectMerchant = (merchant) => {
    this.setState({
      selectedMerchant: merchant,
      activeView: 'form',
    })
    this.props.history.push(this.props.match.url + '/operatore/' + merchant.id)
  }

  onSubmit = () => {

    if(this.state.review === "" || this.state.review === undefined || this.state.review === null){
      this.context.dispatchMessage("Errore: inserire la recensione", 'error')
      return;
    }

    if(this.state.review.length < 200){
      this.context.dispatchMessage("Errore: inserire un testo di almeno 200 caratteri", 'error')
      return;
    }

    //sul success mostro pagina risultato
    this.setState({activeView: "suspense"})

    this.props.bmarkenAPI.createRewardingAction(
      this.state.selectedAction,
      this.state.selectedMerchant.id,
      "",
      this.state.purchaseValue,
      this.state.review,
      this.state.selectedAction === 'VISIT' ? this.state.visitDate.format('YYYY-MM-DD') : this.state.purchaseDate.format('YYYY-MM-DD'),
      "",
      () => this.props.firebase.doRefreshToken()
    ).then((ra) => {
      this.setState({ 
        loading: false,
        rewardingAction: ra,
        activeView: 'result',
        reward: ra.reward,
      })
    })
    .catch(error => {
      this.setState({loading: false, activeView: "form" })
			error = translateError(error)
			this.context.dispatchMessage(error.message, 'error')
    })
  }

  
  onChangeAction = (action) => {
    if(action === "PURCHASE"){
      this.props.history.push(ROUTES.acquisto+"/operatore/"+this.state.selectedMerchant.id)
    }else if(action === "REVIEW"){
      this.props.history.push(ROUTES.recensione+"/operatore/"+this.state.selectedMerchant.id)
    }else{
      this.props.history.push(ROUTES.visita+"/operatore/"+this.state.selectedMerchant.id)
    }
  }

  searchView = ({match, history}) => (
    <div className="main-content">
      <SposiIcon iconName="visita" active={true} main={true} />
      <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>
        <span className="item-block">Ottieni fino a 50 punti</span>
        <span className="rowTitlePoint item-block">per la RECENSIONE di un fornitore</span>
      </p>
      <p style={{...grayParagraph}}>SCEGLI IL FORNITORE</p>
      <Ricerca 
        onSelect={this.onSelectMerchant} 
        {...this.props} 
        history={history}
        match={match}/>
    </div>
  )
  


  formView = () => (
    <div className="main-content">
      <SposiIcon iconName="recensione" active={true} main={true} />
      <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>Vuoi ottenere i punti per</p>
      <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '5px', marginTop: '0px'}}>la RECENSIONE di:</p>
      <MerchantShortInfo merchant={this.state.selectedMerchant} />
      <ActionButtons selected={this.state.selectedAction} maxVisitReward={20} maxPurcahseReward={100} maxReviewReward={50} onClickCallback={this.onChangeAction}/>
      <br/>
      <ActionForms 
        selected={this.state.selectedAction} 
        data={this.state.actionData} 
        onTextChange={this.handleTextChange} 
        onDateChange={this.handleDateChange}
        visitDate={this.state.visitDate}
        visitReason={this.state.visitReason}
        purchaseDate={this.state.purchaseDate}
        purchaseValue={this.state.purchaseValue}
        review={this.state.review}
        onSubmit={() => this.setState({openConfirm: true})}
      />
      <ConfirmPopUp
        open={this.state.openConfirm}
        onClose={() => this.setState({openConfirm: false})}
        onConfirm={() => this.onSubmit()}
        text="Confermi l'inserimento della recensione? Devi aver inserito un testo di senso compiuto superiore ai 200 caratteri"
        title="Conferma"
      />
    </div>
  )

  resultView = () => (
    <ActionSuccessPage
      history={this.props.history}
      merchant={this.state.selectedMerchant}
      reward={this.state.reward}
      selected={this.state.selectedAction}
    />
  )

  mainView = () => (
    <div className="main-content">
      <BackGroundComponent background={'recensione'}/>
      <div style={{ 
        ...boldCenteredText, 
        ...fullMobileWidth,
        fontWeight: 'normal',
        width: '100%',
        }}>
          <div style={{ paddingBottom: '50px'}}>
            <div>
              <LogoHeader white={true}/>
            </div>
            <div style={{color: '#fff'}}>VUOI RECENSIRE UN FORNITORE?</div>
            <div style={{color: '#fff', marginTop: '10px', marginBottom: '30px'}}> Ottieni subito i tuoi punti</div>
            <SposiIcon iconName="recensione" active={true} main={true} />
            <center>
              <Button
                /*onClick={() => this.setState({activeView: 'search'})}*/
                onClick={() => this.props.history.push(this.props.match.url + '/ricerca')}
                variant="contained"
                color="primary"
                className="btnProcedi"
                style={{fontWeight: 'bold'}}
                >
                Procedi
              </Button>
            </center>
        </div>
      </div>
    </div>
  )

  suspenseView = () => (
    <div>
      <SposiLoading show={true}/>
    </div>
  )

  operatoreView = () => {
    switch (this.state.activeView) {
      case 'result':
        return this.resultView()
      case 'suspense':
        return this.suspenseView()
      default:
        return this.formView()
    }
  }
  /*
  viewController = (activeView) => {
    switch (activeView) {
      case 'search':
        return this.searchView()
      case 'form':
        return this.formView()
      case 'result':
        return this.resultView()
      case 'suspense':
        return this.suspenseView()
      default:
        return this.mainView()
    }
  }*/
  viewController = () => {
    switch (this.state.activeView) {
      case 'suspense':
        return this.suspenseView()
      default:
        return this.mainView()
    }
  }


  goBack = () => {
    /*
    const backView = goBackFromView(this.state.activeView) 
    if(backView === null) this.props.history.goBack()
    else this.setState({activeView: backView})
    */
    this.props.history.goBack()
  }

  render() {
    return (
      <div style={centeredText}>
        <Topbar history={this.props.history} from={this.props.path} goBack={() => this.goBack()}/>
          {/*this.viewController(this.state.activeView)*/}
          <Switch>
          <Route path={`${this.props.match.path}/ricerca`} component={this.searchView}/>
          <Route path={`${this.props.match.path}/operatore`} component={this.operatoreView}/>
          <Route path={`${this.props.match.path}`} component={this.viewController}/>
          </Switch>

        <Bottombar from={this.props.location.pathname}/>
        <LoadingOverlay show={this.state.loading} />
      </div>
    )
  }

  
}

export default withBmarkenAPI(withFirebase(Recensione))
