import React from 'react'
import { centeredBoldParagraph } from '../styles/helpers'

const MerchantShortInfo = (props) => {
    return(
        props.merchant &&
        <div>
            <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '4px'}}>{props.merchant.name.toUpperCase()}</p>
            <p style={{...centeredBoldParagraph, fontSize: 12, color:"#9CA1A1", marginBottom: '1px'}}>
                {props.merchant.category}
                <br/>
                {props.merchant.city + " " + props.merchant.province.toUpperCase()}
            </p>
            <p style={{...centeredBoldParagraph, fontSize: 10, color:"#9CA1A1", marginBottom: '1px'}}>Ottieni questi punti con le tue interazioni</p>
        </div>
    )
}

export default MerchantShortInfo;