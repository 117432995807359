import React from "react";
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import ArrowForward from "@material-ui/icons/ArrowForward";

const byDate = (a, b) => new Date(a[0]) - new Date(b[0]);

const EventList = (props) => {
  const eventByDate = new Map();

  for (const obj of props?.events) {
    const date = obj.start_date;

    if (!eventByDate.has(date)) {
      eventByDate.set(date, []);
    }
    eventByDate.get(date).push(obj);
  }
  const loading = props?.loading;

  return (
    <React.Fragment>
      <Typography color="primary" variant="h6">
        Eventi in fiera
      </Typography>
      {!eventByDate?.size && !loading && (
        <Typography>
          Al momento non vi sono eventi a cui ti puoi prenotare
        </Typography>
      )}
      {eventByDate?.size > 0 &&
        Array.from(eventByDate)
          .sort(byDate)
          .map((event) => (
            <List key={event[0]}>
              <Typography align="left" color="primary" variant="h6">
                {moment(event[0]).format("DD-MM-YYYY")}
              </Typography>
              {event[1].map((ev) => (
                <ListItem
                  style={{
                    padding: "5px 0",
                    alignItems: "flex-start",
                    flexDirection: "column",
                  }}
                  button={new Date() !== event[0]}
                  divider
                  onClick={() => props.onClick(ev)}
                >
                  <React.Fragment>
                    {ev.image_url && (
                      <img
                        style={{
                          width: "100%",
                          margin: "auto",
                        }}
                        src={ev.image_url}
                      />
                    )}
                  </React.Fragment>
                  <ListItemText
                    primary={
                      <Typography variant="body" color="primary">
                        {ev.name}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography variant="body1">{`${ev.start_hour} - ${ev.end_hour}`}</Typography>
                        <Typography variant="body1">
                          {ev.description}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {new Date() !== event[0] && (
                    <Button
                      style={{
                        justifyContent: "flex-end",
                        alignSelf: "center",
                        marginBottom: 2,
                      }}
                      color="primary"
                    >
                      Prenota
                    </Button>
                  )}
                </ListItem>
              ))}
            </List>
          ))}
    </React.Fragment>
  );
};

export default EventList;
