import React from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import { ROUTES } from "../constants";
import SposiIcon from "../common_components/SposiIcon";
import {
  centeredBoldParagraph,
  centeredText,
  buttonStyle,
  fullMobileWidth,
} from "../styles/helpers";
import ActionsTable from "../common_components/ActionsTable";
import {
  translateError,
  parseBmeIntToInt,
  getIDsFromCode,
} from "../utils/utils";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import { SharedContext } from "../utils/Session";
import QRCodeContainer from "../common_components/QRCodeContainer";
import LoadingOverlay from "../common_components/LoadingOverlay";
import Button from "@material-ui/core/Button";
import QrReader from "react-qr-reader";

class Punti extends React.PureComponent {
  static contextType = SharedContext;

  constructor(props) {
    super(props);

    this.state = {
      card: null,
      qrCode: null,
      actionID: null,
      rewardingActions: [],
      loading: false,
      view: "main",
      scanEnabled: true,
      ra: null,
    };
  }

  getWelcomeRow = () => {
    const row = {
      action_date: this.state.card.created_at,
      business_name: "SposiClub.it",
      created_at: this.state.card.created_at,
      id: "welcome-0000",
      status: "CONFIRMED",
      type: "GIFT",
      reward: 1000,
    };

    return row;
  };

  getCompleteProfileRow = () => {
    const row = {
      action_date: this.state.card.created_at,
      business_name: "SposiClub.it",
      created_at: this.state.card.created_at,
      id: "profile-0000",
      status: "CONFIRMED",
      type: "PROFILE50",
      reward: 5000,
    };

    return row;
  };

  getData = () => {
    this.setState({ loading: true });

    const userID = this.props.bmarkenAPI.getTokenInfo().bme_id;
    let profileComplete = false;

    this.props.bmarkenAPI
      .getUser(userID, () => this.props.firebase.doRefreshToken())
      .then((u) => {
        let customFields = JSON.parse(u.custom_fields);
        profileComplete =
          customFields.profile_complete != null &&
          customFields.profile_complete != ""
            ? customFields.profile_complete
            : false;

        return this.props.bmarkenAPI.getUserProductDefault(() =>
          this.props.firebase.doRefreshToken()
        );
      })
      .then((card) => {
        this.setState({
          card: card,
        });
        return this.props.bmarkenAPI.getAllRewardingActions(userID, () =>
          this.props.firebase.doRefreshToken()
        );
      })
      .then((actions) => {
        if (actions && actions !== null) {
          if (profileComplete) {
            actions.push(this.getCompleteProfileRow());
          }

          //aggiungo l'interazione finta di benvenuto con 10 punti
          actions.push(this.getWelcomeRow());

          this.setState({
            rewardingActions: actions,
            loading: false,
            view: "main",
          });
        } else {
          let actions = [];

          if (profileComplete) {
            actions.push(this.getCompleteProfileRow());
          }

          actions.push(this.getWelcomeRow());

          this.setState({
            rewardingActions: actions,
            loading: false,
            view: "main",
          });
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  componentDidMount() {
    this.getData();
  }

  showQRCode = (id) => {
    this.setState({ loading: true });
    this.props.bmarkenAPI
      .getRewadingActionQRCode(id, () => this.props.firebase.doRefreshToken())
      .then((up) => {
        this.setState({
          view: "qrcode",
          actionID: id,
          qrCode: up.id,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  currentLevel = (points) => {
    const level =
      points <= 1000 ? "SILVER" : points <= 2000 ? "GOLD" : "PLATINUM";

    const difference =
      points <= 1000
        ? 1001 - points
        : points <= 2000
        ? 2001 - points
        : 3001 - points;
    const nextlevel =
      level === "SILVER" ? "GOLD" : level === "GOLD" ? "PLATINUM" : null;

    return (
      <div style={{ ...centeredBoldParagraph, marginTop: "25px" }}>
        {level && (
          <div style={{ color: "#dc005a" }}>
            Con {points} punti sei al livello {level}
          </div>
        )}
        {level !== "PLATINUM" && (
          <div>
            <div>Ti mancano {difference} per arrivare al</div>
            <div style={{ color: "#dc005a" }}>LIVELLO {nextlevel}</div>
          </div>
        )}
      </div>
    );
  };

  showScanner = (idRewardingAction) => {
    //console.log("RA: ", idRewardingAction);
    this.setState({
      view: "scanner",
      ra: idRewardingAction,
    });
  };

  handleError = () => {
    this.context.dispatchMessage(
      "Scanner in errore. Controllare che il browser in uso non stia bloccando la fotocamera e ritentare",
      "error"
    );
  };

  handleScan = (code) => {
    if (!this.state.scanEnabled) return;

    if (code) {
      this.setState({
        scanEnabled: false,
        loading: true,
      });

      //scansiono solo qrcode di tipo "MERCHANT_<id_merchant>"
      const ids = getIDsFromCode(code, "MERCHANT_", 1);
      if (ids === null) {
        //errore codice scannerizzato non è un id valido
        this.setState({
          scanEnabled: true,
          loading: false,
        });
        this.context.dispatchMessage(
          "Il codice scannerizzato non appartiene ad un negozio",
          "error"
        );
        return;
      }

      const idMerchant = ids[0];
      this.props.bmarkenAPI
        .getRewadingActionQRCode(this.state.ra, () =>
          this.props.firebase.doRefreshToken()
        )
        .then((up) => {
          return this.props.bmarkenAPI.confirmAction(
            up.id,
            "",
            idMerchant,
            () => this.props.firebase.doRefreshToken()
          );
        })
        .then(() => {
          this.setState({
            scanEnabled: true,
            loading: false,
            view: "main",
          });

          this.context.dispatchMessage(
            "Azione confermata con successo",
            "info"
          );

          this.getData();
        })
        .catch((error) => {
          this.setState({
            loading: false,
            scanEnabled: true,
          });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  };

  render() {
    //const points = this.state.card.available_value
    if (this.state.view === "qrcode") {
      return (
        <div
          style={{ ...fullMobileWidth, ...centeredBoldParagraph }}
          className="main-content"
        >
          <QRCodeContainer
            onRefreshCode={() => this.showQRCode(this.state.actionID)}
            code={this.state.qrCode}
          />
          <br />
          <Button
            onClick={() => this.getData()}
            style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
            variant="outlined"
            color="primary"
          >
            Indietro
          </Button>
          <LoadingOverlay show={this.state.loading} />
        </div>
      );
    } else if (this.state.view === "scanner") {
      return (
        <div>
          <Topbar
            history={this.props.history}
            from={this.props.path}
            goBack={() => this.setState({ view: "main" })}
          />
          <div>
            <div>
              <QrReader
                delay={300}
                onError={this.handleError}
                onScan={this.handleScan}
                style={{ width: "100%" }}
              />
            </div>
            <p style={{ ...centeredText, fontSize: 18, color: "#9CA1A1" }}>
              Inquadra il QR code del negozio che hai visitato
              <br />
              per confermare l'interazione e raddoppiare i punti!
            </p>
          </div>

          <Button
            onClick={() =>
              this.setState({
                view: "main",
              })
            }
            style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
            variant="outlined"
            color="primary"
          >
            Indietro
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Topbar
            history={this.props.history}
            goBack={() => {
              /*this.props.history.push(ROUTES.base)*/
              this.props.history.goBack();
            }}
          />
          <div className="main-content" style={centeredText}>
            <div>
              <SposiIcon iconName="punti" active={true} main={true} />
              <div
                style={{
                  ...centeredBoldParagraph,
                  color: "#9CA1A1",
                  marginBottom: "0px",
                }}
              >
                COMPLIMENTI!
                <br />
                Hai accumulato
              </div>
              <div
                style={{
                  ...centeredBoldParagraph,
                  color: "#dc005a",
                  marginBottom: "2px",
                  paddingTop: "0",
                  marginTop: "0",
                }}
              >
                {this.state.card !== null
                  ? parseBmeIntToInt(this.state.card.available_value)
                  : 0}{" "}
                punti
              </div>
            </div>

            <ActionsTable
              showScanner={(id) => this.showScanner(id)}
              title={null}
              actions={this.state.rewardingActions}
            />
          </div>
          {this.currentLevel(
            this.state.card !== null
              ? parseBmeIntToInt(this.state.card.available_value)
              : 0
          )}
          <br />
          <br />
          <div style={centeredText}>
            <div
              style={{
                ...centeredBoldParagraph,
                color: "#dc005a",
                marginBottom: "2px",
                paddingTop: "0",
                marginTop: "0",
              }}
            >
              RICORDA COME OTTENERE I PUNTI:
            </div>

            <div>
              Visita in fiera: <b>20 punti</b>
              <br />
              Visita in negozio: <b>10 punti</b>
              <br />
              Visita in negozio riconosciuta*: <b>20 punti</b>
              <br />
              Acquisto: <b>100 punti</b>
              <br />
              Acquisto riconosciuto*: <b>200 punti</b>
              <br />
              Recensione: <b>50 punti</b>
            </div>
            <br />
            <br />
            <div
              style={{
                fontSize: 14,
              }}
            >
              (*) Per far riconoscere e raddoppiare i tuoi punti/interazione,
              <br />
              chiedi al fornitore di fornirti il suo QR-code da inquadrare con
              il tuo
              <br />
              smartphone all’interno della relativa procedura di interazione
            </div>
          </div>
          <LoadingOverlay show={this.state.loading} />
          <Bottombar from={this.props.location.pathname} />
        </div>
      );
    }
  }
}

export default withFirebase(withBmarkenAPI(Punti));
