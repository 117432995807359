import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { PROVINCIE } from "../constants";
import LogoHeader from "../common_components/LogoHeader";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { ROUTES, validateEmail } from "../constants";
import {
  fullMobileWidth,
  boldCenteredText,
  centeredBoldParagraph,
  buttonStyle,
} from "../styles/helpers";
import {
  translateError,
  validateMobileNumber,
  validateUUID,
} from "../utils/utils";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import { DatePicker } from "@material-ui/pickers";
import SelectFromOptions from "../common_components/SelectFromOptions";
import { SharedContext } from "../utils/Session";
import SocialIcons from "../common_components/SocialIcons";
import queryString from "query-string";
const moment = require("moment");

const Registrazione = (props) => {
  const { dispatchMessage } = useContext(SharedContext);

  useEffect(() => {
    setSubmitLoading(true);
    let params = queryString.parse(props.location.search);
    if (params && params !== null) {
      const idFair = params["fairId"];
      if (idFair && idFair !== null && validateUUID(idFair)) {
        //faccio chiamata api
        props.bmarkenAPI
          .getContent(idFair)
          .then((c) => {
            console.log(c);
            setSubmitLoading(false);
            setFairLogo(c.url);
            setFairCallToAction(c.description);
            setRedirectUrl(
              params["r"] === "1" && c.operation_url ? c.operation_url : null
            );
          })
          .catch((err) => {
            console.log("Error getting content: ", err);
            setSubmitLoading(false);
          });
      } else {
        setSubmitLoading(false);
      }
    } else {
      setSubmitLoading(false);
    }
  }, []);

  const [redirectUrl, setRedirectUrl] = useState(null);

  const [checkBoxState, setCheckBoxState] = useState({
    privacy: false,
    regolamento: false,
    marketing: false,
  });
  const [requiredFields, setRequiredFields] = useState({
    email: "",
    password: "",
    passwordCheck: "",
  });
  const [optionalFields, setOptionalFields] = useState({
    nome: "",
    cognome: "",
    telefono: "",
    dataNozze: null,
    provinciaNozze: "",
  });
  const [reqFieldErrors, setReqFieldErrors] = useState({
    email: false,
    password: false,
    passwordCheck: false,
    nome: false,
    cognome: false,
    telefono: false,
    dataNozze: false,
    provinciaNozze: false,
  });

  const [fairLogo, setFairLogo] = useState(null);
  const [fairCallToAction, setFairCallToAction] = useState(null);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleRFChange = (name) => (event) => {
    setRequiredFields({ ...requiredFields, [name]: event.target.value });
  };

  const handleCBChange = (name) => (event) => {
    setCheckBoxState({ ...checkBoxState, [name]: event.target.checked });
  };

  const validateTel = (tel) => {};

  const handleOptionalFieldChange = (fieldName, target = false) => (event) => {
    const newState = {
      ...optionalFields,
      [fieldName]: target ? event.target.value : event,
    };
    setOptionalFields(newState);
  };

  const handleChangeProvince = (prov) => {
    const newState = {
      ...optionalFields,
      ["provinciaNozze"]: prov,
    };
    setOptionalFields(newState);
  };

  const handleChangeDate = (d) => {
    const newState = {
      ...optionalFields,
      ["dataNozze"]: d,
    };
    setOptionalFields(newState);
  };

  const Regolamento = () => (
    <div>
      Dichiaro di avere letto e accettato il
      <Link
        to={ROUTES.regolamento}
        target="_blank"
        style={{ color: "#8b8b8b" }}
      >
        {" "}
        REGOLAMENTO
      </Link>{" "}
      e i
      <Link
        to={ROUTES.termini_servizio}
        target="_blank"
        style={{ color: "#8b8b8b" }}
      >
        {" "}
        TERMINI DI UTILIZZO DEL SERVIZIO
      </Link>
    </div>
  );
  const Privacy = () => (
    <div>
      Dichiaro di avere letto e accettato le norme che regolano il
      <Link
        to={ROUTES.privacy_policy}
        target="_blank"
        style={{ color: "#8b8b8b" }}
      >
        {" "}
        TRATTAMENTO DEI DATI PERSONALI (PRIVACY)
      </Link>
    </div>
  );

  const Marketing = () => (
    <div>
      Acconsento al trattamento dei dati personali per finalità di marketing
      dirette da parte del Circuito, o degli esercenti a cui appartengono le
      promo e gli strumenti di fidelizzazione utilizzati, o da terze parti.
    </div>
  );

  const showSuccessMessage = () => {
    let areComplete = true;
    let message = {};
    Object.keys(optionalFields).forEach((key) => {
      if (!optionalFields[key]) areComplete = false;
    });
    if (!areComplete) {
      dispatchMessage(
        "Registrazione avvenuta con successo! Una mail è stata inviata all'indirizzo email inserito. Segui le istruzioni sulla mail per attivare il tuo account e poi effettua la login. Una volta loggato potrei completare il tuo profilo e ricevere i punti bonus.",
        "warning"
      );
      props.history.push({ pathname: ROUTES.accesso });
    } else {
      // registrazione conclusa
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        dispatchMessage(
          "Registrazione avvenuta con successo e 10 punti bonus caricati! Una mail è stata inviata all'indirizzo email inserito. Segui le istruzioni sulla mail per attivare il tuo account e poi effettua la login.",
          "notify"
        );
        props.history.push({ pathname: ROUTES.accesso });
      }
    }
  };

  const submitFields = async () => {
    //deve checkare termini e privacy
    if (
      !checkBoxState.privacy ||
      !checkBoxState.regolamento ||
      !checkBoxState.marketing
    ) {
      dispatchMessage(
        "Per procedere è necessario accettare il regolamento e le norme sulla privacy e acconsentire al trattemento dei dati per finalità di marketing spuntando le tre caselle in basso",
        "error"
      );
      return;
    }

    //Deve inserire pass corrette
    const passwordIsValid = requiredFields.password.length >= 8;
    const passwordsAreEqual =
      requiredFields.password === requiredFields.passwordCheck;
    const emailIsValid = validateEmail(requiredFields.email);
    if (!passwordIsValid || !emailIsValid || !passwordsAreEqual) {
      setReqFieldErrors({
        email: !emailIsValid,
        password: !passwordIsValid,
        passwordCheck: !passwordsAreEqual,
      });
      return;
    }

    let telefono = optionalFields.telefono;
    if (telefono !== "") {
      telefono = telefono.trim();
      if (telefono.startsWith("+39") || telefono.startsWith("0039")) {
        dispatchMessage(
          "Devi immettere il numero di cellulare senza il prefisso internazionale italiano",
          "error"
        );
        return;
      }
      if (telefono.startsWith("+") || telefono.startsWith("00")) {
        dispatchMessage(
          "Il numero di cellulare non è corretto. Attenzione i numeri con prefisso internazionale non sono accettati",
          "error"
        );
        return;
      }
      if (!validateMobileNumber(telefono)) {
        dispatchMessage(
          "Il numero di cellulare è sbagliato. Ricorda: non sono ammessi il prefisso +39, né spazi, parentesi o trattini",
          "error"
        );
        return;
      }
    }

    let provinciaNozze = optionalFields.provinciaNozze;
    let dataNozze = optionalFields.dataNozze;
    const nome = optionalFields.nome;
    const cognome = optionalFields.cognome;

    if (
      nome === "" ||
      cognome === "" ||
      provinciaNozze === "" ||
      dataNozze === null ||
      telefono === ""
    ) {
      setReqFieldErrors({
        nome: nome === "",
        cognome: cognome === "",
        provinciaNozze: provinciaNozze === "",
        dataNozze: dataNozze === null,
        telefono: telefono === "",
      });
      return;
    }

    localStorage.setItem("StopListener", "true");
    if (provinciaNozze !== "") {
      provinciaNozze = provinciaNozze.value;
    }

    dataNozze =
      dataNozze !== null ? moment(dataNozze).format("YYYY-MM-DD") : null;

    setSubmitLoading(true);

    //registro su Firebase

    props.firebase
      .doCreateUserWithEmailAndPassword(
        requiredFields.email,
        requiredFields.password
      )
      .then((u) => {
        return props.firebase.doRefreshToken(false);
      })
      .then((token) => {
        //registro su Bmarken
        return props.bmarkenAPI.signup(token);
      })
      .then(() => {
        //tutto ok: refresho il token e lo salvo
        return props.firebase.doRefreshToken(true);
      })
      .then((token) => {
        //completiamo l'iscrizione qui
        return props.bmarkenAPI.completeSignup(
          token,
          optionalFields.nome,
          optionalFields.cognome,
          telefono,
          dataNozze,
          provinciaNozze,
          checkBoxState.privacy,
          checkBoxState.regolamento,
          checkBoxState.marketing
        );
      })
      .then(() => {
        //invio email di conferma
        return props.firebase.doSendEmailVerification();
      })
      .then(() => {
        localStorage.removeItem("StopListener");
        //lo sloggo e lo mando sulla login in attesa che confermi l'account con la mail inviata ed effettui la prima login
        props.firebase
          .doSignOut()
          .then(() => {
            return props.bmarkenAPI.logout();
          })
          .then(() => {
            //segnalare che registrazione ok e che email per attivare account è stata inviata + redirect su pagina login
            setSubmitLoading(false);
            showSuccessMessage();
            //props.history.push({ pathname: ROUTES.accesso});
          })
          .catch((err) => {
            setSubmitLoading(false);
            err = translateError(err);
            dispatchMessage(err.message, "error");
          });
      })
      .catch((err) => {
        localStorage.removeItem("StopListener");
        //cancello lo user da Firebase se c'è qualche errore. E poi lo sloggo
        if (
          props.firebase.getAuth().currentUser &&
          props.firebase.getAuth().currentUser != null
        ) {
          props.firebase.getAuth().currentUser.delete();
        }

        props.firebase.doSignOut();
        err = translateError(err);
        setSubmitLoading(false);
        dispatchMessage(err.message, "error");
      });
  };

  const onGoogleClick = () => {
    setSubmitLoading(true);
    props.firebase
      .doSignInWithGoogle()
      .then((socialAuthUser) => {
        console.log(socialAuthUser);
      })
      .catch((error) => {
        props.firebase.doSignOut();
        error = translateError(error);
        setSubmitLoading(false);
        dispatchMessage(error.message, "error");
      });
  };

  const onFacebookClick = () => {
    setSubmitLoading(true);
    props.firebase
      .doSignInWithFacebook()
      .then((socialAuthUser) => {
        console.log(socialAuthUser);
      })
      .catch((error) => {
        props.firebase.doSignOut();
        error = translateError(error);
        setSubmitLoading(false);
        dispatchMessage(error.message, "error");
      });
  };

  if (localStorage.getItem("ListenerError")) {
    this.context.dispatchMessage(
      localStorage.getItem("ListenerError"),
      "error"
    );
    localStorage.removeItem("ListenerError");
  }

  return (
    <div style={boldCenteredText}>
      {fairLogo != null ? (
        <div>
          <LogoHeader />
          <img
            style={{
              marginBottom: "25px",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            alt="logoFiera"
            src={fairLogo}
          />
          <div
            style={{
              marginBottom: "25px",
              ...centeredBoldParagraph,
              color: "#dc005a",
            }}
          >
            {fairCallToAction}
          </div>
        </div>
      ) : (
        <div>
          <LogoHeader />
          <div style={{ marginBottom: "25px" }}>
            Registrati con un tuo account
          </div>
          <SocialIcons
            onGoogleClick={onGoogleClick}
            onFacebookClick={onFacebookClick}
          />
          <div style={{ marginBottom: "25px", marginTop: "25px" }}>
            Oppure compila questi dati per creare un nuovo account
          </div>
        </div>
      )}

      <div>
        <Typography color="primary" style={{ fontWeight: 700 }} gutterBottom>
          È sufficiente una sola registrazione per coppia con relativi
          accompagnatori
        </Typography>
      </div>
      <div>
        <TextField
          id="outlined-email-input"
          label="La tua email"
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          style={fullMobileWidth}
          required={true}
          onChange={handleRFChange("email")}
          value={requiredFields.email}
          error={reqFieldErrors.email}
          onClick={() => setReqFieldErrors({ ...reqFieldErrors, email: false })}
        />
      </div>
      {reqFieldErrors.email && (
        <div
          style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
        >
          Inserire un indirizzo email valido
        </div>
      )}
      <div>
        <TextField
          id="outlined-password-input"
          label="Scegli una password (almeno 8 caratteri)"
          type="password"
          name="password"
          margin="normal"
          variant="outlined"
          style={fullMobileWidth}
          required={true}
          onChange={handleRFChange("password")}
          value={requiredFields.password}
          error={reqFieldErrors.password}
          onClick={() =>
            setReqFieldErrors({ ...reqFieldErrors, password: false })
          }
        />
      </div>
      {reqFieldErrors.password && (
        <div
          style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
        >
          La password deve essere lunga almeno 8 caratteri
        </div>
      )}
      <div>
        <TextField
          id="outlined-repeatpassword-input"
          label="Digita nuovamente la password"
          name="ripeti password"
          type="password"
          margin="normal"
          variant="outlined"
          style={fullMobileWidth}
          required={true}
          onChange={handleRFChange("passwordCheck")}
          value={requiredFields.passwordCheck}
          error={reqFieldErrors.passwordCheck}
          onClick={() =>
            setReqFieldErrors({ ...reqFieldErrors, passwordCheck: false })
          }
        />
      </div>
      {reqFieldErrors.passwordCheck && (
        <div
          style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
        >
          Le password digitate non coincidono
        </div>
      )}
      {/*<div style={{...fullMobileWidth, marginBottom: '25px', marginTop: '25px'}}>Per completare la tua registrazione puoi completare ancora questi dati e ottenere già 50 punti</div>*/}
      <div>
        {/********************* OPTIONAL *******************/}
        <div>
          <TextField
            id="outlined-name-input"
            label="Nome"
            name="nome"
            margin="normal"
            variant="outlined"
            style={fullMobileWidth}
            value={optionalFields.nome}
            required={true}
            error={reqFieldErrors.nome}
            onChange={handleOptionalFieldChange("nome", true)}
          />
        </div>
        {reqFieldErrors.nome && (
          <div
            style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
          >
            Inserisci il tuo nome
          </div>
        )}
        <div>
          <TextField
            id="outlined-surname-input"
            label="Cognome"
            name="cognome"
            margin="normal"
            variant="outlined"
            style={fullMobileWidth}
            value={optionalFields.cognome}
            required={true}
            error={reqFieldErrors.cognome}
            onChange={handleOptionalFieldChange("cognome", true)}
          />
        </div>
        {reqFieldErrors.cognome && (
          <div
            style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
          >
            Inserisci il tuo cognome
          </div>
        )}
        <div>
          <TextField
            id="outlined-phone-input"
            label="Numero di cellulare"
            name="telefono"
            margin="normal"
            variant="outlined"
            style={fullMobileWidth}
            value={optionalFields.telefono}
            required={true}
            error={reqFieldErrors.telefono}
            onChange={handleOptionalFieldChange("telefono", true)}
          />
        </div>
        {reqFieldErrors.telefono && (
          <div
            style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
          >
            Inserisci il tuo numero di cellulare
          </div>
        )}
        {/* *************** data e provincia ********************* */}
        <div style={fullMobileWidth}>
          <DatePicker
            disablePast={true}
            format="DD/MM/YYYY"
            label={"Data delle nozze"}
            views={["year", "month", "date"]}
            value={optionalFields.dataNozze}
            onChange={(data) => handleChangeDate(data)}
            inputVariant="outlined"
            emptyLabel=""
            fullWidth={true}
            required={true}
            error={reqFieldErrors.dataNozze}
            cancelLabel="cancella"
          />
        </div>
        {reqFieldErrors.dataNozze && (
          <div
            style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
          >
            Inserisci la data del tuo matrimonio
          </div>
        )}

        <div>
          <SelectFromOptions
            value={optionalFields.provinciaNozze}
            placeholder={"Digita il nome di una provincia *"}
            label={"provincia in cui organizzi le nozze"}
            onChange={(e) => handleChangeProvince(e)}
            options={PROVINCIE}
          />
        </div>
        {reqFieldErrors.provinciaNozze && (
          <div
            style={{ fontSize: "11px", color: "#f44435", marginBottom: "5px" }}
          >
            Inserisci la provincia del tuo matrimonio
          </div>
        )}
      </div>
      <div
        style={{
          ...fullMobileWidth,
          margin: "auto",
          textAlign: "left",
          marginTop: "35px",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBoxState.regolamento}
              onChange={handleCBChange("regolamento")}
              value="regolamento"
              color="primary"
            />
          }
          label={<Regolamento />}
        />
      </div>
      <div style={{ ...fullMobileWidth, margin: "auto", textAlign: "left" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBoxState.privacy}
              onChange={handleCBChange("privacy")}
              value="privacy"
              color="primary"
            />
          }
          label={<Privacy />}
        />
      </div>

      <div style={{ ...fullMobileWidth, margin: "auto", textAlign: "left" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkBoxState.marketing}
              onChange={handleCBChange("marketing")}
              value="merketing"
              color="primary"
            />
          }
          label={<Marketing />}
        />
      </div>
      <div>
        <Button
          onClick={submitFields}
          style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
          variant="outlined"
          color="primary"
        >
          Invia
        </Button>
      </div>
      <div>
        <Button
          onClick={() => {
            props.history.push({ pathname: ROUTES.accesso });
          }}
          style={{ ...buttonStyle, marginBottom: "50px" }}
          variant="outlined"
          color="primary"
        >
          Annulla
        </Button>
      </div>
      <LoadingOverlay show={submitLoading} />
    </div>
  );
};

export default withFirebase(withBmarkenAPI(Registrazione));
