import React from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import SposiIcon from "../common_components/SposiIcon";
import Ricerca from "./Ricerca";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import {
  centeredText,
  centeredBoldParagraph,
  grayParagraph,
} from "../styles/helpers";
import Negozio from "./Negozio";
import { Route, Switch } from "react-router-dom";
import { translateError } from "../utils/utils";
import LogoHeader from "../common_components/LogoHeader";
import Card from "../common_components/Card";
import bgBoxes from "../styles/images/img_merchant.png";
import { ROUTES } from "../constants";

class Fornitori extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      selectedMerchant: null,
      showMerchant: false,
    };
  }

  componentDidMount() {
    const path = this.props.history.location.pathname.split("/");
    if (
      path.length > 2 &&
      path[path.length - 2] == "fornitore" &&
      path[path.length - 1]
    ) {
      const merchantID = path[path.length - 1];
      this.props.bmarkenAPI
        .getMerchant(merchantID, () => this.props.firebase.doRefreshToken())
        .then((m) => {
          this.setState({
            selectedMerchant: m,
          });
        })
        .catch((error) => {
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  }

  onSelectMerchant = (merchant) => {
    this.setState({
      selectedMerchant: merchant,
      showMerchant: true,
    });
    this.props.history.push(this.props.match.url + "/fornitore/" + merchant.id);
  };

  ricercaView = ({ match, history }) => (
    <div className="main-content">
      <div style={centeredText}>
        <Topbar
          history={this.props.history}
          from={this.props.path}
          //goBack={this.props.history.goBack}
          goBack={() => this.props.history.push(ROUTES.base)}
        />
        {/*<SposiIcon iconName="visita" active={true} main={true} />*/}
        <Card bg={bgBoxes}>
          <LogoHeader white />
        </Card>

        <p
          style={{
            ...centeredBoldParagraph,
            color: "#dc005a",
            marginBottom: "2px",
          }}
        >
          Scopri i fornitori convenzionati SPOSICLUB
          {/* <span className="item-block" style={{ marginBottom: "3px" }}>
            Scopri i fornitori presso cui puoi
          </span>
          <span className="item-block">ottenere i tuoi punti</span>*/}
        </p>

        <p style={{ ...grayParagraph }}>SCEGLI IL FORNITORE</p>
      </div>

      <Ricerca
        onSelect={this.onSelectMerchant}
        history={history}
        match={match}
      />

      <Bottombar from={this.props.path} />
    </div>
  );

  fornitoreView = () =>
    this.state.selectedMerchant && (
      <div className="main-content">
        <div>
          <Topbar
            history={this.props.history}
            from={this.props.path}
            //goBack={this.props.history.goBack}
            goBack={() => this.props.history.push(ROUTES.fornitori)}
          />
          <Negozio
            history={this.props.history}
            merchant={this.state.selectedMerchant}
          />
          <Bottombar from={this.props.path} />
        </div>
      </div>
    );

  render() {
    return (
      <Switch>
        <Route
          path={`${this.props.match.path}/fornitore`}
          component={this.fornitoreView}
        />
        <Route path={`${this.props.match.path}`} component={this.ricercaView} />
      </Switch>
    );
    /*
        if(this.state.showMerchant){
            return (
                <div className="main-content">
                    <div>
                        <Topbar history={this.props.history} from={this.props.path} goBack={this.props.history.goBack}/>
                        <Negozio history={this.props.history} merchant={this.state.selectedMerchant}/>
                        <Bottombar from={this.props.path}/>
                    </div>
                </div>
            )
        }else{
            //pagina iniziale
            return(
                <div className="main-content">
                    <div style={centeredText}>
                    <Topbar history={this.props.history} from={this.props.path} goBack={this.props.history.goBack}/>
                    <SposiIcon iconName="visita" active={true} main={true} />
                    
                    <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>
                        <div style={{marginBottom: '3px'}}>Scopri i fornitori presso cui puoi</div>
                        <div>ottenere i tuoi punti</div>
                    </p>


                    <p style={{...grayParagraph}}>SCEGLI IL FORNITORE</p>
                    </div>

                    <Ricerca 
                        onSelect={this.onSelectMerchant}
                        history={this.props.history}
                        match={this.props.match}/>

                    <Bottombar from={this.props.path}/>
                </div>
            )
        }
        */
  }
}

export default withBmarkenAPI(withFirebase(Fornitori));
