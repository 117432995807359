import React, { useState, useContext } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'
import moment from 'moment'
import { fullMobileWidth, centeredBoldParagraph, buttonStyle } from '../styles/helpers'
import { parseBmeIntToInt, parseBmeIntToFloat, translateError, translateAction, getUserInfo, traslateStatus } from '../utils/utils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import { SharedContext } from '../utils/Session';
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api';
import LoadingOverlay from '../common_components/LoadingOverlay'
import ConfirmPopUp from '../common_components/ConfirmPopUp'
import iconUser from '../styles/images/icon-user.png'


const ActionsTableMerchant = (props) => {

    const [view, setView] = useState("main")
    const [ra, setRa] = useState(null)
    const [user, setUser] = useState(null)
    const { dispatchMessage } = useContext(SharedContext);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [choice, setChoice] = useState(false)

    const showDetails = (ra) => {

        props.bmarkenAPI.getUser(ra.user_id, () => {
            return props.firebase.doRefreshToken(true)
        })
        .then(user => {
            setRa(ra)
            setUser(user)
            setView('detail')
        })
        .catch(error => {
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const openConfirmPopup = (choice) => {
        setChoice(choice)
        setOpenConfirm(true)
    }

    const confirmAction = () => {
        
        if(choice === "approve"){
            props.approveAction(ra.id)
        }else{
            props.cancelAction(ra.id)
        }
        setView('main')
    }

    const sendEmail = (email) => {
        window.open(
            "mailto:"+email,
            '_blank'
        );
    }

    const mainView = () =>
        <div style={fullMobileWidth}>
            <div style={{...centeredBoldParagraph, color:"#9CA1A1", marginBottom: '10px', marginTop: '10px'}}>
                INTERAZIONI DEGLI UTENTI
            </div>
            <Card style={{marginTop: '25px', paddingLeft: '5px', paddingRight: '5px', overflow: 'scroll'}}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{fontWeight: 'bold'}}>DATA</TableCell>
                            <TableCell style={{fontWeight: 'bold'}}>UTENTE</TableCell>
                            <TableCell style={{fontWeight: 'bold'}}>AZIONE</TableCell>
                            <TableCell style={{fontWeight: 'bold'}}>P.TI</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.actions.map(act => (
                            <TableRow onClick={() => showDetails(act) } key={act.id} style={{cursor: 'pointer'}} hover={true}>
                                <TableCell>{moment(getDate(act)).format('DD/MM/YY')}</TableCell>
                                <TableCell>{act.user_name}</TableCell>
                                <TableCell>{translateAction(act.type)}</TableCell>
                                <TableCell align="right">{parseBmeIntToInt(act.reward)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Card>
            <LoadingOverlay show={submitLoading} />
        </div>

    const detailView = () => {
        let nozze = "-"
        try{
            let customFields = JSON.parse(user.custom_fields);
            nozze = new Date(customFields.wedding_date)
            nozze = moment(nozze).format('DD-MM-YY')
        }catch(e){

        }
        let province = "";
        if (user.custom_fields) {
            try {
                const cf = JSON.parse(user.custom_fields);
                province = cf.wedding_province;
            } catch(e) {
            }
        }
        
        return(
        <div style={fullMobileWidth}>
            <div style={{...centeredBoldParagraph, color:"#9CA1A1", marginBottom: '10px', marginTop: '10px'}}>
                {
                    ra.type === "PURCHASE" ? (<div>ACQUISTO</div>)
                    : ra.type === "FAIR" ? (<div>FIERA</div>)
                    : ra.type === "VISIT" ? (<div>VISITA</div>)
                    : ra.type === "REVIEW" ? (<div>RECENSIONE</div>)
                    : ra.type === "ENTRY" ? (<div>INGRESSO FIERA</div>)
                    : null
                }
            </div>
            <Card>
                <CardContent>
                    {
                        ra!==null
                        ?(
                            <div style={{fontSize: '13px' }}>
                                {[
                                    {name: '', datum: (<div class='col-user'><span class='col-user-img'><img style={{width: 90, height: 90}} src={user.picture ? user.picture : iconUser} /></span><span class='col-user-name'>{user.complete_name + " " + user.last_name}</span></div>), itemStyle: {width:'100%'}},
                                    {name: 'DATA INTERAZIONE:', datum: moment(getDate(ra)).format('DD-MM-YY')},
                                    {name: 'DATA NOZZE:', datum: nozze},
                                    {name: 'EMAIL:', datum: user.email},
                                    {name: 'TELEFONO:', datum: user.mobile},
                                    {name: 'PROVINCIA NOZZE:', datum: province},
                                    {name: 'DETTAGLIO:', datum: getDetail(ra)},
                                    {name: 'STATO:', datum:traslateStatus(ra.status)}
                                ].map((el, index) => [
                                    <div key={el.name} style={{display: 'flex', justifyContent: 'space-between'}}>
                                        {el.name && <div style={{fontWeight: 'bold'}}>{el.name}</div>}
                                        {
                                            el.name === "EMAIL:"
                                            ?(<div style={{color:'blue', textDecoration:'underline'}}><a onClick={() => sendEmail(el.datum)}>{el.datum}</a></div>)
                                            :(<div style={{color:"#8b8b8b"}, (el.itemStyle ? el.itemStyle : null) }>{el.datum}</div>)
                                        }
                                    </div>,
                                    index !== 6 && <Divider style={{margin: '15px 0px 15px 0px'}} key={`${el.name}-div`}/>
                                ])}
                            </div>
                        ):null
                    }
                </CardContent>
            </Card>
            <div style={{textAlign:"left", fontSize:"12px", marginTop: '20px'}}>
                Cliccando sulla mail verrà aperta una nuova
                pagina nel suo browser. Può chiudere quella pagina
                dopo aver inviato la mail per tornare a questa schermata
            </div>

            <div style={{marginTop: '20px'}}>
                Se ritieni che questa interazione non sia<br/>
                autentica, puoi eliminarla cancellando<br/>
                all’utente i punti assegnati
            </div>
            <Button disabled={ra.status!="PENDING"} onClick={() => openConfirmPopup("cancel")} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Elimina
            </Button>

            <br/>
            <div style={{marginTop: '20px'}}>
            Non hai potuto approvare subito questa<br/>
            interazione? Fai in modo che il tuo cliente<br/>
            raddoppi i propri punti
            </div>
            <Button  disabled={ra.status!="PENDING"} onClick={() => openConfirmPopup("approve")} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Conferma
            </Button>

            <br/>
            <div>
                <Button onClick={() => setView('main')} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Indietro
                </Button>
            </div>
            <LoadingOverlay show={submitLoading} />

            <ConfirmPopUp
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                onConfirm={() => confirmAction()}
                text={choice === "approve" ? "Sei sicuro di voler confermare l'interazione?" : "Sei sicuro di voler cancellare l'interazione?"}
                title="Conferma"
            />
        </div>
        )
    }

    const getDate = (ra) => {
        if(ra.type === 'FAIR' || ra.type === 'REVIEW'){
            return ra.timestamp
        }else if(ra.type === "ENTRY"){
            return ra.created_at
        }
        return ra.action_date
    }

    const getDetail = (ra) => {
        if(ra.type === 'FAIR') {
            return "Visita in fiera"
        }else if(ra.type === "PURCHASE"){
            return parseBmeIntToFloat(ra.expense)+"€"
        }else if(ra.type === "REVIEW"){
            return ra.comment
        }else if(ra.type === "ENTRY"){
            return ra.comment
        }else{
            return "-"
        }
    }

    switch (view){
        case 'detail': return detailView()
        default: return mainView()
    }
}

export default  withFirebase(withBmarkenAPI(ActionsTableMerchant))