import React, { useState, useContext } from "react";
import SearchMap from "../common_components/SearchMap";
import SearchFilter from "../common_components/SearchFilter";
import SearchResult from "../common_components/SearchResult";
import MapIcon from "@material-ui/icons/Map";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import { translateError, parse_query_string } from "../utils/utils";
import { SharedContext } from "../utils/Session";
import Button from "@material-ui/core/Button";
import { fullMobileWidth } from "../styles/helpers";
import { Route, Switch } from "react-router-dom";
import { Context } from "../utils/Session/sharedContext";

/*
const PROVINCES = [
  {label: "Alessandria", value: "AL", lat: 44.55, lng: 8.37},
  {label: "Ancona", value: "AN", lat: 43.37, lng: 13.31},
  {label: "Aosta", value: "AO", lat: 45.44, lng: 7.19},
  {label: "Arezzo", value: "AR", lat: 43.28, lng: 11.53},
  {label: "Ascoli Piceno", value: "AP", lat: 42.51, lng: 13.34},
  {label: "Asti", value: "AT", lat: 44.53, lng: 8.12},
  {label: "Avellino", value: "AV", lat: 40.55, lng: 14.47},
  {label: "Bari", value: "BA", lat: 41.07, lng: 16.53},
  {label: "Belluno", value: "BL", lat: 46.08, lng: 12.13},
  {label: "Benevento", value: "BN", lat: 41.08, lng: 14.46},
  {label: "Bergamo", value: "BG", lat: 45.42, lng: 9.4},
  {label: "Biella", value: "BI", lat: 45.34, lng: 8.03},
  {label: "Bologna", value: "BO", lat: 44.3, lng: 11.21},
  {label: "Bolzano", value: "BZ", lat: 46.3, lng: 11.2},
  {label: "Brescia", value: "BS", lat: 45.32, lng: 10.12},
  {label: "Brindisi", value: "BR", lat: 40.39, lng: 17.56},
  {label: "Cagliari", value: "CA", lat: 39.13, lng: 9.07},
  {label: "Caltanissetta", value: "Cl", lat: 37.29, lng: 14.04},
  {label: "Campobasso", value: "CB", lat: 41.34, lng: 14.39},
  {label: "Caserta", value: "CE", lat: 41.04, lng: 14.19},
  {label: "Catania", value: "CT", lat: 37.3, lng: 15.05},
  {label: "Catanzaro", value: "CZ", lat: 38.54, lng: 16.35},
  {label: "Chieti", value: "CH", lat: 42.21, lng: 14.1},
  {label: "Como", value: "Co", lat: 45.48, lng: 9.05},
  {label: "Cosenza", value: "CS", lat: 39.17, lng: 16.15},
  {label: "Cremona", value: "CR", lat: 45.08, lng: 10.02},
  {label: "Crotone", value: "KR", lat: 39.05, lng: 17.08},
  {label: "Cuneo", value: "CN", lat: 44.24, lng: 7.33},
  {label: "Enna", value: "EN", lat: 37.34, lng: 14.16},
  {label: "Ferrara", value: "FE", lat: 44.5, lng: 11.38},
  {label: "Firenza", value: "FI", lat: 43.46, lng: 11.15},
  {label: "Foggia", value: "FG", lat: 41.28, lng: 15.33},
  {label: "Forl", value: "FO", lat: 44.13, lng: 12.03},
  {label: "Frosinone", value: "FR", lat: 41.38, lng: 13.22},
  {label: "Genova", value: "GE", lat: 44.25, lng: 8.55},
  {label: "Gorizia", value: "GO", lat: 45.57, lng: 13.38},
  {label: "Grosseto", value: "GR", lat: 42.46, lng: 11.07},
  {label: "Imperia", value: "IM", lat: 43.53, lng: 8.01},
  {label: "Isernia", value: "IS", lat: 41.36, lng: 14.14},
  {label: "L'Aquila", value: "AQ", lat: 42.21, lng: 13.24},
  {label: "La Spezia", value: "SP", lat: 44.07, lng: 9.5},
  {label: "Latina", value: "LT", lat: 41.28, lng: 12.53},
  {label: "Lecce", value: "LE", lat: 40.21, lng: 18.11},
  {label: "Lecco", value: "LC", lat: 45.51, lng: 9.23},
  {label: "Livorno", value: "LI", lat: 43.33, lng: 10.19},
  {label: "Lodi", value: "LO", lat: 45.19, lng: 9.3},
  {label: "Lucca", value: "LU", lat: 43.51, lng: 10.31},
  {label: "Macerata", value: "MC", lat: 43.18, lng: 13.27},
  {label: "Mantova", value: "MN", lat: 45.09, lng: 10.47},
  {label: "Massa", value: "MS", lat: 44.2, lng: 10.05},
  {label: "Matera", value: "MT", lat: 40.41, lng: 16.36},
  {label: "Messina", value: "ME", lat: 38.11, lng: 15.34},
  {label: "Milano", value: "MI", lat: 45.28, lng: 9.11},
  {label: "Modena", value: "MO", lat: 44.39, lng: 10.55},
  {label: "Napoli", value: "NA", lat: 40.21, lng: 14.15},
  {label: "Novara", value: "NO", lat: 45.27, lng: 8.38},
  {label: "Nuoro", value: "NU", lat: 40.19, lng: 9.16},
  {label: "Oristano", value: "OR", lat: 39.54, lng: 8.36},
  {label: "Padova", value: "PD", lat: 45.24, lng: 11.53},
  {label: "Palermo", value: "PA", lat: 38.07, lng: 13.21},
  {label: "Parma", value: "PR", lat: 44.48, lng: 10.21},
  {label: "Pavia", value: "PV", lat: 45.11, lng: 9.1},
  {label: "Perugia", value: "PG", lat: 43.07, lng: 12.24},
  {label: "Pesaro", value: "PS", lat: 43.54, lng: 12.55},
  {label: "Pescara", value: "PE", lat: 42.27, lng: 14.13},
  {label: "Piacenza", value: "PC", lat: 45.03, lng: 9.41},
  {label: "Pisa", value: "PI", lat: 43.43, lng: 10.24},
  {label: "Pistoia", value: "PT", lat: 43.56, lng: 10.55},
  {label: "Pordenone", value: "PN", lat: 45.57, lng: 12.39},
  {label: "Potenza", value: "PZ", lat: 40.38, lng: 15.49},
  {label: "Prato", value: "PO", lat: 43.53, lng: -11.06},
  {label: "Ragusa", value: "RG", lat: 36.56, lng: -14.44},
  {label: "Ravenna", value: "RA", lat: 44.25, lng: 12.12},
  {label: "Reggio Calabria", value: "RC", lat: 38.06, lng: 15.39},
  {label: "Reggio Emilia", value: "RE", lat: 44.42, lng: 10.38},
  {label: "Rieti", value: "RI", lat: 42.22, lng: 12.52},
  {label: "Rimini", value: "RN", lat: 44.04, lng: 12.34},
  {label: "Roma", value: "Roma", lat: 41.54, lng: 12.29},
  {label: "Rovigo", value: "RO", lat: 45.04, lng: 11.48},
  {label: "Salerno", value: "SA", lat: 40.41, lng: 14.46},
  {label: "Sassari", value: "SA", lat: 40.43, lng: 8.34},
  {label: "Savona", value: "SV", lat: 44.19, lng: 8.28},
  {label: "Siena", value: "SI", lat: 43.19, lng: 11.18},
  {label: "Siracusa", value: "SR", lat: 37.04, lng: 15.17},
  {label: "Sondrio", value: "SO", lat: 46.11, lng: 9.53},
  {label: "Taranto", value: "TA", lat: 40.28, lng: 17.14},
  {label: "Teramo", value: "TE", lat: 42.4, lng: 13.43},
  {label: "Terni", value: "TR", lat: 42.34, lng: 12.4},
  {label: "Torino", value: "TO", lat: 45.04, lng: 7.42},
  {label: "Trapani", value: "TP", lat: 38.01, lng: 12.32},
  {label: "Trento", value: "TN", lat: 46.04, lng: 11.08},
  {label: "Treviso", value: "TV", lat: 45.4, lng: 12.15},
  {label: "Trieste", value: "TS", lat: 45.39, lng: 13.46},
  {label: "Udine", value: "UD", lat: 46.04, lng: 13.14},
  {label: "Varese", value: "VA", lat: 45.5, lng: 8.49},
  {label: "Venezia", value: "VE", lat: 45.26, lng: 12.21},
  {label: "Verbano-Cusio-Ossola", value: "VB", lat: 45.56, lng: 8.33},
  {label: "Vercelli", value: "VC", lat: 45.2, lng: 8.25},
  {label: "Verona", value: "VR", lat: 45.27, lng: 11.0},
  {label: "Vibo Valentia", value: "VV", lat: 38.4, lng: 16.06},
  {label: "Vicenza", value: "VI", lat: 45.33, lng: 11.33},
  {label: "Viterbo", value: "VT", lat: 42.33, lng: 12.07}
]
*/
// solo Piemonte e toscana
let PROVINCES = [
  { label: "Alessandria", value: "AL", lat: 44.55, lng: 8.37 },
  { label: "Asti", value: "AT", lat: 44.53, lng: 8.12 },
  { label: "Biella", value: "BI", lat: 45.34, lng: 8.03 },
  { label: "Cuneo", value: "CN", lat: 44.24, lng: 7.33 },
  { label: "Novara", value: "NO", lat: 45.27, lng: 8.38 },
  { label: "Torino", value: "TO", lat: 45.04, lng: 7.42 },
  { label: "Verbano-Cusio-Ossola", value: "VB", lat: 45.56, lng: 8.33 },
  { label: "Vercelli", value: "VC", lat: 45.2, lng: 8.25 },

  { label: "Arezzo", value: "AR", lat: 43.28, lng: 11.53 },  
  { label: "Firenze", value: "FI", lat: 43.46, lng: 11.15 },
  { label: "Grosseto", value: "GR", lat: 42.46, lng: 11.07 },
  { label: "Livorno", value: "LI", lat: 43.33, lng: 10.19 },
  { label: "Lucca", value: "LU", lat: 43.51, lng: 10.31 },
  { label: "Massa-Carrara", value: "MS", lat: 44.2, lng: 10.05 },
  { label: "Pisa", value: "PI", lat: 43.43, lng: 10.24 },
  { label: "Pistoia", value: "PT", lat: 43.56, lng: 10.55 },
  { label: "Prato", value: "PO", lat: 43.53, lng: -11.06 },
  { label: "Siena", value: "SI", lat: 43.19, lng: 11.18 },

  {label:"Bologna", value:"BO", lat: 44.30, lng: 11.21, region:"Emilia Romagna"},
  {label:"Ferrara", value:"FE", lat: 44.50, lng: 11.38, region:"Emilia Romagna"},
  {label:"Forlì-Cesena", value:"FC", lat: 44.13, lng: 12.03, region:"Emilia Romagna"},
  {label:"Modena", value:"MO", lat:44.39, lng: 10.55, region:"Emilia Romagna"},
  {label:"Parma", value:"PR", lat: 44.48, lng: 10.21, region:"Emilia Romagna"},
  {label:"Piacenza", value:"PC", lat: 45.03, lng: 9.41, region:"Emilia Romagna"},
  {label:"Ravenna", value:"RA", lat: 44.25, lng: 12.12, region:"Emilia Romagna"},
  {label:"Reggio Emilia", lat: 44.42, lng: 10.38, value:"RE", region:"Emilia Romagna"},
  {label:"Rimini", value:"RN", lat: 44.04, lng: 12.34, region:"Emilia Romagna"},

  {label:"Genova", value:"GE", lat: 44.25, lng: 8.55, region:"Liguria"},
  {label:"Imperia", value:"IM", lat: 43.53, lng: 8.01, region:"Liguria"},
  {label:"La Spezia", value:"SP", lat: 44.07, lng: 9.50, region:"Liguria"},
  {label:"Savona", value:"SV", lat: 44.19, lng: 8.28, region:"Liguria"},

  {label:"Bergamo", value:"BG", lat: 45.42, lng: 9.40, region:"Lombardia"},
  {label:"Brescia", value:"BR", lat: 45.32, lng: 10.12, region:"Lombardia"},
  {label:"Como", value:"CO", lat: 45.48, lng: 9.05, region:"Lombardia"},
  {label:"Cremona", value:"CR", lat: 45.08, lng: 10.02, region:"Lombardia"},
  {label:"Lecco", value:"LC", lat: 45.51, lng: 9.23, region:"Lombardia"},
  {label:"Lodi", value:"LO", lat: 45.19, lng: 9.30, region:"Lombardia"},
  {label:"Mantova", value:"MN", lat: 45.09, lng: 10.47, region:"Lombardia"},
  {label:"Milano", value:"MI", lat: 45.28, lng: 9.11, region:"Lombardia"},
  {label:"Monza Brianza", value:"MB", lat: 45.58, lng: 9.27, region:"Lombardia"},
  {label:"Pavia", value:"PV", lat: 45.11, lng: 9.10, region:"Lombardia"},
  {label:"Sondrio", value:"SO", lat: 46.11, lng: 9.53, region:"Lombardia"},
  {label:"Varese", value:"VA", lat: 45.50, lng: 8.49, region:"Lombardia"},
];
PROVINCES = PROVINCES.sort((a, b) => a.label.localeCompare(b.label));

function nvl(val) {
  return !val ? "" : val;
}
function nnvl(val) {
  return !val || val == "" ? null : val;
}

class Ricerca extends React.PureComponent {
  static contextType = SharedContext;
  constructor(props) {
    super(props);
    this.state = {
      lat: null,
      lng: null,
      merchants: [],
      province: null,
      category: null,
    };
  }

  componentDidMount() {
    let params;
    if (this.props.history.location.search) {
      params = parse_query_string(this.props.history.location.search);
    }
    const path = this.props.history.location.pathname.split("/");
    const lastPath = path.length > 0 ? path[path.length - 1] : "";
    if (lastPath == "intornoate") {
      this.searchPlace();
    }
    if (lastPath == "listaoperatori" || lastPath == "mappaoperatori") {
      if (params && (params["prov"] || params["cat"])) {
        this.searchPlaceCategory(
          params["prov"],
          params["cat"],
          params["lat"],
          params["lng"],
          true
        );
      }
    }
  }

  searchPlace = () => {
    const location = window.navigator && window.navigator.geolocation;
    if (location) {
      location.getCurrentPosition(
        (position) => {
          this.setState({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            merchants: [],
            province: null,
            category: null,
          });
          this.props.history.push(this.props.match.url + "/intornoate");
        },
        (error) => {
          this.context.dispatchMessage(error.message, "error");
        }
      );
    }
  };

  /*
  searchPlaceCategory = (prov, cat, lat, lng, changeUrl) => {
    const catid = cat
    this.props.bmarkenAPI.searchMerchants(
      null,
      null,
      0,
      null,
      nnvl(prov),
      nnvl(catid),
      () => this.props.firebase.doRefreshToken()
    )
    .then(merchants => {
      if (merchants && merchants.length) {
        merchants.sort(function(a, b){
          if (!a.name) return -1
          if (!b.name) return 1
          return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        })
      }
      
      // se lat e long non sono presenti prendo quelle dell'utente
      if (!lat || !lng) {
        const location = window.navigator && window.navigator.geolocation
        if (location) {
          location.getCurrentPosition((position) => {
            this.setState({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              merchants: merchants,
              province: prov,
              category: catid,
            })
          })
        }
      } else {
        this.setState({
          lat: lat,
          lng: lng,
          merchants: merchants,
          province: prov,
          category: catid,
        })
      }
      if (!merchants || !merchants.length) {
        this.context.dispatchMessage("Nessun operatore trovato", 'info')
        return
      }
      if (!changeUrl) {
        this.props.history.push({
          pathname: this.props.match.url + '/listaoperatori',
          search: encodeURI(
            '?prov=' + nvl(prov) +
            '&cat=' + nvl(cat) +
            '&lat=' + nvl(lat) +
            '&lng=' + nvl(lng)
          )
        })
      }
    })
    .catch(error => {
      error = translateError(error)
      this.context.dispatchMessage(error.message, 'error')
    })
  }
  */

  searchPlaceCategory = (prov, cat, lat, lng, changeUrl) => {
    const catid = cat;
    this.props.bmarkenAPI
      .searchMerchantsPublic(null, null, 0, null, nnvl(prov), nnvl(catid))
      .then((merchants) => {
        if (merchants && merchants.length) {
          merchants.sort(function (a, b) {
            if (!a.name) return -1;
            if (!b.name) return 1;
            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
          });

          lat = merchants[0]?.location?.lat;
          lng = merchants[0]?.location?.lng;
        }
        this.setState({
          lat: lat,
          lng: lng,
          merchants: merchants,
          province: prov,
          category: catid,
        });

        if (!merchants || !merchants.length) {
          this.context.dispatchMessage("Nessun operatore trovato", "info");
          return;
        }

        if (!changeUrl) {
          this.props.history.push({
            pathname: this.props.match.url + "/listaoperatori",
            search: encodeURI("?prov=" + nvl(prov) + "&cat=" + nvl(cat)),
          });
        }
      })
      .catch((error) => {
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  selectName = async (value) => {
    this.props.onSelect(value);
  };

  selectMerchantId = async (id) => {
    this.props.bmarkenAPI
      .getMerchant(id, () => this.props.firebase.doRefreshToken())
      .then((m) => {
        this.props.onSelect(m);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderMapButton = (
    <Button
      onClick={() => {
        this.props.history.push({
          pathname: this.props.match.url + "/listaoperatori/mappaoperatori",
          search: encodeURI(
            "?prov=" +
              nvl(this.state.province) +
              "&cat=" +
              nvl(this.state.category) +
              "&lat=" +
              nvl(this.state.lat) +
              "&lng=" +
              nvl(this.state.lng)
          ),
        });
      }}
      variant="outlined"
      color="primary"
      style={{ fontWeight: "bold", marginTop: "8px" }}
    >
      <MapIcon />
    </Button>
  );

  renderFilter = ({ match, history }) => (
    <SearchFilter
      onSearchPlace={this.searchPlace}
      onSelectName={this.selectName}
      onSearchPlaceCategory={this.searchPlaceCategory}
      provinces={PROVINCES}
      history={history}
      match={match}
    />
  );

  renderMap = ({ match, history }) =>
    this.state.lat &&
    this.state.lng && (
      <SearchMap
        lat={this.state.lat}
        lng={this.state.lng}
        merchants={this.state.merchants}
        onSelectName={this.selectName}
        onSelectMerchantId={this.selectMerchantId}
        history={this.props.history}
      />
    );

  renderListMap = ({ match, history }) =>
    this.state.lat &&
    this.state.lng &&
    this.state.merchants &&
    this.state.merchants.length && (
      <SearchMap
        lat={this.state.lat}
        lng={this.state.lng}
        merchants={this.state.merchants}
        onSelectName={this.selectName}
        onSelectMerchantId={this.selectMerchantId}
        history={this.props.history}
      />
    );

  renderList = ({ match, history }) => {
    const { categories } = useContext(Context);
    const categoryName = categories?.find((c) => c.id === this.state.category)?.label || "";
    return (
      (this.state.province || this.state.category) && (
        <SearchResult
          merchants={this.state.merchants}
          provinceName={nvl(this.state.province)}
          categoryName={categoryName}
          onSelectMerchant={this.selectName}
          action={this.renderMapButton}
          history={this.props.history}
        />
      )
    );
  };

  render() {
    return (
      <div style={fullMobileWidth}>
        <Switch>
          <Route
            path={`${this.props.match.path}/listaoperatori/mappaoperatori`}
            component={this.renderListMap}
          />
          <Route
            path={`${this.props.match.path}/listaoperatori`}
            component={this.renderList}
          />
          <Route
            path={`${this.props.match.path}/intornoate`}
            component={this.renderMap}
          />
          <Route
            path={`${this.props.match.path}`}
            component={this.renderFilter}
          />
        </Switch>
      </div>
      /*
  <div style={fullMobileWidth}>
    {this.props.ricercaState.isFilter && 
      <SearchFilter 
        onSearchPlace={this.searchPlace} 
        onSelectName={this.selectName}
        onSearchPlaceCategory={this.searchPlaceCategory}
      />
    }
    {this.props.ricercaState.isMap && 
      <SearchMap 
        lat={this.state.lat} 
        lng={this.state.lng} 
        merchants={this.state.merchants} 
        onSelectName={this.selectName}
        onSelectMerchantId={this.selectMerchantId}
      />
    }
    {this.props.ricercaState.isList && 
      <div>
        
      <SearchResult 
        merchants={this.state.merchants} 
        provinceName={this.state.province} 
        categoryName={this.state.category}
        onSelectMerchant={this.selectName}
        action={this.renderMapButton}
      />
      </div>
    }
  </div>*/
    );
  }
}

export default withFirebase(withBmarkenAPI(Ricerca));
//export { RicercaState }
