import React from "react";

import { Button as ButtonUI, makeStyles } from "@material-ui/core";

import bg from "../styles/images/fiera.jpg";

const useStyles = makeStyles({
  root: {
    border: "2px",
    backgroundColor: "white",
    padding: "3px 10px 0",
    width: "100%",
    maxWidth: 220,
    whiteSpace: "nowrap",
    color: "#e30059",
    border: "1px solid currentColor",

    "& + &": {
      marginTop: 10,
    },
  },
  rootColor: {
    border: "2px",
    backgroundColor: "#e30059",
    padding: "3px 10px 0",
    width: "100%",
    maxWidth: 220,
    whiteSpace: "nowrap",
    color: "#fff",
    border: "1px solid currentColor",

    "& + &": {
      marginTop: 10,
    },
  },
  label: {
    fontSize: 14,
    padding: 0,
  },
});

export default function Button({ children, ...props }) {
  const classes = useStyles();

  return (
    <ButtonUI
      classes={{
        root: props.bgColor ? classes.rootColor : classes.root,
        label: classes.label,
      }}
      variant="contained"
      disableElevation
      {...props}
    >
      {children}
    </ButtonUI>
  );
}
