import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import MerchantList from "./MerchantList";

const cellStyle = {
  fontSize: "14px",
  color: "#8b8b8b",
  minHeight: "35px",
};

const redTextStyle = {
  color: "#dc005a",
  fontSize: "16px",
  fontWeight: "bold",
};

const rowStyle = {
  color: "#8b8b8b",
};

class SearchResult extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Card raised={true} style={{ margin: "3px" }}>
        <CardHeader
          title={`${this.props.categoryName} ${this.props.provinceName}`}
          action={this.props.action}
        />
        <CardContent>
          <MerchantList
            items={this.props.merchants}
            button={true}
            onClick={(merchant) => this.props.onSelectMerchant(merchant)}
          />
          {/*  <Table style={{ width: "100%" }}>
            <TableBody>
              {this.props.merchants.map((merchant) => (
                <TableRow
                  hover={true}
                  key={merchant.id}
                  style={{ ...rowStyle, cursor: "pointer" }}
                  onClick={() => this.props.onSelectMerchant(merchant)}
                >
                  <TableCell style={{ ...cellStyle, fontWeight: "bold" }}>
                    {merchant.name}
                  </TableCell>
                  <TableCell style={cellStyle} align="right">
                    {merchant.city}{" "}
                    {merchant.province && "(" + merchant.province + ")"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */}
        </CardContent>
      </Card>
    );
  }
}

export default SearchResult;
