import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { fullMobileWidth, centeredText, grayParagraph } from '../styles/helpers'
import Autocomplete from '../common_components/Autocomplete'
import deburr from 'lodash/deburr';
import SelectFromOptions from '../common_components/SelectFromOptions'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import {translateError} from '../utils/utils'
import PlacesAutocomplete from './PlacesAutocomplete';
import { SharedContext } from '../utils/Session';
import { Context } from '../utils/Session/sharedContext'

const SearchFilter = (props) => {
  let initialState = {
    prov: '',
    category: '',
    lat: null,
    lng: null,
  }

  const { dispatchMessage } = useContext(SharedContext);
  const [fieldsState, setFieldState] = useState(initialState)
  const [submitLoading, setSubmitLoading] = useState(false)

  const handleChange = (fieldName, target = false) => event => {
    const newState = {
      ...fieldsState,
      [fieldName]: target ? event.target.value : event
    }
    setFieldState(newState)
  }

  
  /*
  const CATEGORIES = [
    {label:"PER IL RICEVIMENTO", value:"PER IL RICEVIMENTO"},
    {label:"Ristorante matrimonio", value:"Ristorante matrimonio"},
    {label:"Catering matrimoni", value:"Catering matrimoni"},
    {label:"Location matrimoni", value:"Location matrimoni"},
    {label:"Torte nuziali", value:"Torte nuziali"},
    {label:"LOOK E ABBIGLIAMENTO", value:"LOOK E ABBIGLIAMENTO"},
    {label:"Abiti da cerimonia", value:"Abiti da cerimonia"},
    {label:"Abiti da sposa", value:"Abiti da sposa"},
    {label:"Abiti da sposo", value:"Abiti da sposo"},
    {label:"Acconciature sposa", value:"Acconciature sposa"},
    {label:"Intimo e accessori sposa", value:"Intimo e accessori sposa"},
    {label:"Scarpe sposa", value:"Scarpe sposa"},
    {label:"Trucco sposa", value:"Trucco sposa"},
    {label:"Benessere", value:"Benessere"},
    {label:"SERVIZI E ACCESSORI", value:"SERVIZI E ACCESSORI"},
    {label:"Allestimento casa", value:"Allestimento casa"},
    {label:"Arredamento", value:"Arredamento"},
    {label:"Noleggio auto matrimonio", value:"Noleggio auto matrimonio"},
    {label:"Bomboniere nozze", value:"Bomboniere nozze"},
    {label:"Fedi nuziali", value:"Fedi nuziali"},
    {label:"Fiori matrimonio", value:"Fiori matrimonio"},
    {label:"Fotografo matrimonio", value:"Fotografo matrimonio"},
    {label:"Lista nozze", value:"Lista nozze"},
    {label:"Musica matrimonio", value:"Musica matrimonio"},
    {label:"Noleggi e allestimenti matrimoni", value:"Noleggi e allestimenti matrimoni"},
    {label:"Scenografie e spettacoli nozze", value:"Scenografie e spettacoli nozze"},
    {label:"Partecipazioni nozze", value:"Partecipazioni nozze"},
    {label:"Viaggi di nozze", value:"Viaggi di nozze"},
    {label:"Wedding planner", value:"Wedding planner"},
    {label:"Organizzare il matrimonio", value:"Organizzare il matrimonio"},
  ]
  */
  const { categories } = useContext(Context);


  async function search(query) {
      const inputValue = deburr(query.trim()).toLowerCase();
      const inputLength = inputValue.length;

      if(inputLength === 0){
        return []
      }

      return props.bmarkenAPI.searchMerchantsPublic(
        null,
        null,
        null,
        inputValue,
        null,
        null,
      )
      .then(merchants => {
        return merchants
      })
      .catch(error => {
        error = translateError(error)
        dispatchMessage(error.message, 'error')
        return []
      })

  }


  const onLocationSelect = (address, lat, lng) => {
    const newState = {
      ...fieldsState,
      ['prov']: address[2].short_name,
      ['lat']: lat,
      ['lng']: lng,
    }
    setFieldState(newState)
    
  }



  const submitFields = async () => {
    let provCode = null
    let lat = null
    let lng = null
    let catId = null
    if (fieldsState.category) {
      catId = fieldsState.category.id
    }
    if (fieldsState.province) {
      provCode = fieldsState.province.value
      lat = fieldsState.province.lat
      lng = fieldsState.province.lng
    }
    if (provCode || catId)
      props.onSearchPlaceCategory(provCode, catId, lat, lng)
  }
  

  return (
    <div style={centeredText}>
      <div style={{...grayParagraph, marginTop: '0px', paddingTop: '0px'}}>Su una mappa</div>
      <div>
        <Button
          onClick={() => props.onSearchPlace()}
          variant="outlined"
          color="primary"
          style={{fontWeight: 'bold'}}
          >
          cerca intorno a te
        </Button>
      </div>
      <div style={{...grayParagraph, marginTop: '15px'}}>oppure scrivi il suo nome</div>
      <div>
        <Autocomplete
          inputLabel="scrivi qui il nome"
          inputVariant="outlined"
          inputMargin="normal"
          inputStyle={fullMobileWidth}
          search={search}
          onChange={props.onSelectName}
        />
      </div>
      <div style={{...grayParagraph, marginTop: '5px'}}>oppure effettua una ricerca</div>
      
        {/*}
        <PlacesAutocomplete 
          style={fullMobileWidth}
          margin="normal"
          variant="outlined"
          label="indica la località"
          id="inputId"
          onSelected={(address, lat, lng) => onLocationSelect(address, lat, lng)}
      />*/}
      <div>
      <SelectFromOptions
          label="Indica la provincia"
          onChange={handleChange('province')}
          options={props.provinces}/>
      </div>
      <div>
      <SelectFromOptions
          label="Indica la categoria"
          onChange={handleChange('category')}
          options={categories}/>
      </div>
      <div>
        <Button
          onClick={submitFields}
          variant="outlined"
          color="primary"
          style={{fontWeight: 'bold', width: '150px'}}
          >
          cerca
        </Button>
      </div>
      <LoadingOverlay show={submitLoading} />
    </div>
  )
}

export default  withFirebase(withBmarkenAPI(SearchFilter))