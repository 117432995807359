import React, { useState, useContext } from "react";
import Topbar from "../common_components/Topbar";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  fullMobileWidth,
  boldCenteredText,
  buttonStyle,
} from "../styles/helpers";
import Button from "@material-ui/core/Button";
import { SharedContext } from "../utils/Session";
import { translateError, getUserInfo } from "../utils/utils";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Assistenza = (props) => {
  const classes = useStyles();

  const u = getUserInfo();

  const [message, setMessage] = useState("");
  const { dispatchMessage } = useContext(SharedContext);
  const callSupport = () => {
    window.open("tel:0116610626", "_blank");
  };

  const sendEmail = () => {
    if (message.length > 1000) {
      dispatchMessage("Massimo 1000 caratteri", "error");
      return;
    }

    if (message === "") {
      dispatchMessage("Inserire un messaggio", "error");
      return;
    }

    props.bmarkenAPI
      .sendSupportEmail(message, () => this.props.firebase.doRefreshToken())
      .then(() => {
        dispatchMessage("Email inviata con successo", "info");
      })
      .catch((error) => {
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  return (
    <div>
      <Topbar
        history={props.history}
        from={props.path}
        goBack={props.history.goBack}
      />
      <div>
        <div
          style={{
            ...boldCenteredText,
            ...fullMobileWidth,
            fontWeight: "normal",
          }}
        >
          <div style={{ fontSize: 16 }}>
            Descrivi qui la tua segnalazione di assistenza: <br />
            ti risponderemo al più presto sulla casella di
            <br />
            posta elettronica indicata nel tuo profilo
            <br />
            <div style={{ color: "#DC005A" }}>{u.user_email}</div>
          </div>
          <br />
          <div className={classes.root}>
            <div>
              <TextField
                id="outlined-phone-input"
                label="Messaggio"
                name="message"
                margin="normal"
                variant="outlined"
                style={fullMobileWidth}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                multiline
                rowsMax="8"
              />
            </div>

            <div>
              <Button
                onClick={() => sendEmail()}
                style={{
                  ...buttonStyle,
                  marginTop: "25px",
                  marginBottom: "20px",
                }}
                variant="outlined"
                color="primary"
              >
                Invia Richiesta
              </Button>
            </div>

            {/*                  <br/>

                <div style={{fontSize:16}}>
                Hai urgenza? Chiama la nostra assistenza<br/>
                LUN-VEN 9-12 / 15-18<br/>
                </div>
                <Button onClick={() => callSupport()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Chiama assistenza
                </Button>

                <div style={{fontSize:16}}>
                Telefono<br/>
                011.6610626<br/>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withFirebase(withBmarkenAPI(Assistenza));
