import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ROUTES } from '../constants'
import blackLogo from '../styles/images/SposiClub_logo.svg';
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { validateRoles, getUserInfo, getPrivacy } from '../utils/utils';

const noAuthMenuRoutes = [
  { path: ROUTES.base, text: 'Home'},
  { path: ROUTES.fiera, text: 'Fiere'},
  { path: ROUTES.fornitori_public, text: 'Fornitori'},
  { path: 'https://youtu.be/aWvy44BO82Y', text: 'Come funziona'},
  { path: ROUTES.regolamento, text: 'Regolamento'},
]

const userMenuRoutes = [
  { path: ROUTES.base, text: 'Home'},
  { path: ROUTES.fiera, text: 'Fiere sposi'},
  { path: ROUTES.visita, text: 'Carica visita'},
  { path: ROUTES.acquisto, text: 'Carica acquisto'},
  { path: ROUTES.recensione, text: 'Carica recensione'},
  { path: ROUTES.punti, text: 'I tuoi punti'},
  { path: ROUTES.premi, text: 'Elenco premi'},
  { path: ROUTES.fornitori, text: 'Cerca fornitori'},
  /*{ path: ROUTES.convenzioni, text: 'Convenzioni'},*/
  { path: ROUTES.regolamento, text: 'Regolamento'},
  { path: ROUTES.assistenza, text: 'Assistenza'}, // puntamento momentaneo
  { path: ROUTES.profilo, text: 'Il tuo profilo'},
  { path: 'https://youtu.be/aWvy44BO82Y', text: 'Come funziona'},
  { path: ROUTES.logout, text: 'Logout'},
]

const userNoPrivacyMenuRoutes = [
  { path: ROUTES.base, text: 'Home'},
  { path: ROUTES.privacy_policy, text: 'Privacy'},
  { path: ROUTES.regolamento, text: 'Regolamento'},
  { path: ROUTES.termini_servizio, text: 'Termini di servizio'},
  { path: ROUTES.logout, text: 'Logout'},
]

const merchantMenuRoutes = [
  { path: ROUTES.base, text: 'Home'},
  { path: ROUTES.scansiona, text: 'Scansiona'},
  { path: ROUTES.interazioni, text: 'Interazioni'},
  /*{ path: ROUTES.faq_merchant, text: 'Assistenza'},*/
  { path: ROUTES.assistenza_merchant , text: 'Assistenza'},
  { path: 'https://youtu.be/FceVOAqJ-74', text: 'Come funziona'},
  { path: ROUTES.logout, text: 'Logout'},
]

const superadminMenuRoutes = [
  { path: ROUTES.base, text: 'Home'},
  { path: ROUTES.gestione_fiere, text: 'Fiere'},
  { path: ROUTES.gestione_recensioni, text: 'Recensioni'},
  { path: ROUTES.stats, text: 'Statistiche'},
  { path: ROUTES.logout, text: 'Logout'},
]

const Topbar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = () => {
    const { firebase, bmarkenAPI } = props;
    console.log("FACCIO LOGOUT")
    firebase.doSignOut()
    .then(()=>{
        bmarkenAPI.logout()
    }).catch(error => {
        console.error(error)
    })
  }

  const getRoutes = () => {
    const userInfo = getUserInfo();
    if (!userInfo?.user_id) return noAuthMenuRoutes
    if(validateRoles('M', userInfo.role, true)) return merchantMenuRoutes
    else if(validateRoles('TM', userInfo.role, true)) return superadminMenuRoutes
    else if(!getPrivacy())return userNoPrivacyMenuRoutes
    else return userMenuRoutes
  }

  return [
  <div key={'appbar-separator'} style={{width: '100%', height: '63px'}}></div>,
  <AppBar style={{ background: '#f2f2f2' }} position="fixed" key={'appbar'}>
    <Toolbar>
      {props.goBack &&
        <IconButton edge="end" aria-label="Menu" onClick={props.goBack}>
          <ArrowBack />
        </IconButton>
      }
      {!props.withoutLogo && <div style={{overflow: 'hidden', height: '60px', left: '50%', marginLeft: '-50px', position: 'fixed'}}>
        <Link to={ROUTES.base} style={{ textDecoration: 'none' }}>
          <img
            style={{height: '232px', width: '100px', marginTop: '-85px'}}
            alt="logoSposiClubMenu"
            src={blackLogo}
          />
        </Link>
      </div>}
      
        <IconButton onClick={handleMenu} style={{ marginLeft: 'auto'}} edge="end" aria-label="Menu">
          <MenuIcon />
        </IconButton>
        {/*props.bmarkenAPI.checkIfLoggedIn() && */}
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {getRoutes().map((el, index) => {
            if(el.path !== ROUTES.logout){
              if(el.text !== 'Come funziona'){
                return(
                  <Link key={index} to={el.path} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}>
                    <MenuItem selected={props.from === el.path} >{el.text}</MenuItem>
                  </Link>)
              } else {
                return(
                  <a href={el.path} key={"cm_func"} target="_blank" style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}>
                    <MenuItem selected={props.from === el.path} >{el.text}</MenuItem>
                  </a>)
              }
            }else{
              return(
                <div key={index} onClick={handleLogout} style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}>
                  <MenuItem selected={props.from === el.path} >{el.text}</MenuItem>
                </div>
              )
            }
          })}
        </Menu>
        {/* } */}
    </Toolbar>
  </AppBar>
  ]}

export default withFirebase(withBmarkenAPI(Topbar))