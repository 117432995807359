import React, { useContext, useState } from 'react'
import Topbar from '../common_components/Topbar'
import BottombarMerchant from '../common_components/BottombarMerchant'
import { validateUUID, getUserInfo, translateError, parseBmeIntToInt, parseBmeIntToFloat, translateAction, getIDsFromCode} from '../utils/utils';
import QrReader from 'react-qr-reader'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { SharedContext } from '../utils/Session';
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import ConfirmPopUp from '../common_components/ConfirmPopUp'
import { fullMobileWidth, centeredBoldParagraph, buttonStyle } from '../styles/helpers'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import moment from 'moment'
import Button from '@material-ui/core/Button'

const Scanner = (props) => {

    const [scanEnabled, setScanEnabled] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [openConfirmation, setOpenConfirmation] = useState(false)
    const [couponOwner, setCouponOwner] = useState(null)
    const [activeView, setActiveView] = useState("main")
    const [qrcode, setQRCode] = useState("")
    const [action, setAction] = useState(null)
    const [user, setUser] = useState(null)
    const [couponInfo, setCouponInfo] = useState(null)
    const [fair, setFair] = useState(null)
    const { dispatchMessage } = useContext(SharedContext);



    const checkCodeType = (code) => {
        if(code.indexOf("confirm:") !== -1){
            //si tratta di un codice per confermare un'interazione. ritorno il codice pulito 
            return 1
        }else if(code.indexOf("fair:") !== -1){
            //si tratta di un qrcode di una fiera
            return 2
        }else if(code.indexOf("ACCESS_FAIR_") !== -1){
            return 3
        }else{
            //si tratta di un prodotto oppure non è valido
            return 0
        }

    }

    const askConfirmation = (code, type, userCode) => {
        setSubmitLoading(true)
        setQRCode(code)
        if(type === 1){
            props.bmarkenAPI.getActionByQRCode(code, () => props.firebase.doRefreshToken())
            .then((ra) => {
                setSubmitLoading(false)
                setAction(ra)
                setActiveView('confirmAction')
            })
            .catch(error => {
                setScanEnabled(true)
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
        }else if(type===2){
            props.bmarkenAPI.getFairByQRCode(code, () => props.firebase.doRefreshToken())
            .then((f) => {
                setSubmitLoading(false)
                setFair(f)
                setActiveView('confirmFair')
            })
            .catch(error => {
                setScanEnabled(true)
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
        }else if(type===3){
            props.bmarkenAPI.getContent(code, () => props.firebase.doRefreshToken())
            .then((f) => {
                
                setFair(f)
                return props.bmarkenAPI.getUser(userCode, () => props.firebase.doRefreshToken())
            })
            .then(u => {
                setSubmitLoading(false)
                setUser(u)
                setActiveView('confirmEntry')
            })
            .catch(error => {
                setScanEnabled(true)
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
        }else{
            const bme = getUserInfo()
            props.bmarkenAPI.getProductInfo(code, bme.business, () => props.firebase.doRefreshToken())
            .then((c) => {
                setCouponInfo(c)
                return  props.bmarkenAPI.makeGetRequest(c.owner_link.url, () => props.firebase.doRefreshToken())
            })
            .then((owner) => {
                setSubmitLoading(false)
                setCouponOwner(owner)
                setActiveView('confirmBurnCoupon')
            })
            .catch(error => {
                setScanEnabled(true)
                setSubmitLoading(false)
                if(error.message === "Entry not found in database"){
                    error.message = "USEPERMISSION_WRONG_MERCHANT"
                }
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
        }
    }

    const confirmEntryAction = () => {
        setSubmitLoading(true)
        const bme = getUserInfo()

        props.bmarkenAPI.createEntryRewardingAction(bme.business,user.id, fair.id, () => props.firebase.doRefreshToken())
        .then(() => {
            setSubmitLoading(false)
            setScanEnabled(true)
            setActiveView("main")
            dispatchMessage("Ingresso confermato", 'info')
        })
        .catch(error => {
            setSubmitLoading(false)
            setScanEnabled(true)
            if(error && (error.message === "Rewarding action is duplicated" || error.message === "Daily rewarding action number reached")){
                error.message = "Ingresso già registrato in questa giornata"
            }else{
                error = translateError(error)
            }   
            dispatchMessage(error.message, 'error')
        })
    }

    const confirmBurnCoupon = () => {
        setSubmitLoading(true)
        const bme = getUserInfo()

        props.bmarkenAPI.burnCoupon(bme.business, qrcode, () => props.firebase.doRefreshToken())
        .then(() => {
            setSubmitLoading(false)
            setScanEnabled(true)
            setActiveView("main")
            dispatchMessage("Coupon bruciato con successo", 'info')
        })
        .catch(error => {
            setSubmitLoading(false)
            setScanEnabled(true)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const confirmAction = () => {
        setSubmitLoading(true)
        const bme = getUserInfo()

        props.bmarkenAPI.confirmAction(qrcode, "", bme.business, () => props.firebase.doRefreshToken())
        .then(() => {
            setSubmitLoading(false)
            setScanEnabled(true)
            setActiveView("main")
            dispatchMessage("Azione confermata con successo", 'info')
        })
        .catch(error => {
            setSubmitLoading(false)
            setScanEnabled(true)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const handleScan = (code) => {
        if(!scanEnabled)
            return

        if (code) {
            setScanEnabled(false)
            setSubmitLoading(true)

            //controllo che tipo di codice è
            const codeType = checkCodeType(code)
            console.log("Scan: ", codeType)
            let userCode = null
            let error = false
            const bme = getUserInfo()

            if(codeType === 0 && (!validateUUID(code) || bme.business_type !== "Merchant")){//errore
                error = true
            }else if(codeType === 1){//azione da confermare
                code = code.substring(8)
                if(!validateUUID(code) || bme.business_type !== "Merchant"){
                    error = true
                }
            }else if(codeType === 2 ){//fiera da confermare
                code = code.substring(5)
                if(!validateUUID(code) || bme.business_type !== "Merchant"){
                    error = true
                }
            }else if(codeType === 3){
                const ids = getIDsFromCode(code, "ACCESS_FAIR_", 2)
                console.log(bme.business_type)
                if(ids === null || bme.business_type !== "Fair"){
                    error = true
                }
                userCode = ids[1]
                code = ids[0]
            }else{//prodotto da utilizzare
                
            }

            if(error){
                //errore codice scannerizzato non è un id valido
                setSubmitLoading(false)
                setScanEnabled(true)
                dispatchMessage("Codice scannerizzato non valido", "error")
                return
            }

            setSubmitLoading(false)
            askConfirmation(code, codeType, userCode)
        }
    }

    const handleError = () => {
        dispatchMessage("Scanner in errore. Controllare che il browser in uso non stia bloccando la fotocamera e ritentare", "error")
    }

    const viewController = (activeView) => {
        switch (activeView) {
            case 'confirmAction':
                return confirmView()
            case 'confirmFair':
                    return confirmFair()
            case 'confirmEntry':
                    return confirmEntry()
            case 'confirmBurnCoupon':
                    return burnCouponView()
            default:
                return mainView()
        }
    }

    const mainView = () => {
        return (
            <div>
                <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}
                />
            </div>
        )
    }

    const getDate = (ra) => {
        if(ra.type === 'FAIR' || ra.type === 'REVIEW'){
            return ra.timestamp
        }
        return ra.action_date
    }

    const getDetail = (ra) => {
        if(ra.type === 'FAIR') {
            return ra.location
        }else if(ra.type === "PURCHASE"){
            return parseBmeIntToFloat(ra.expense)+"€"
        }else if(ra.type === "REVIEW"){
            return ra.comment
        }else{
            return "-"
        }
    }

    const burnCouponView = () => {
        return (
            <div>
                <br/>
                <Card>
                    <CardContent>
                        <div style={{fontSize: '13px', color:"#8b8b8b"}}>
                            {[
                                {name: 'DATA EMISSIONE', datum: moment(couponInfo.issued_at).format('DD-MM-YY')},
                                {name: 'NOME PREMIO', datum: couponInfo.campaign_name},
                                {name: 'EMAIL UTENTE', datum: couponOwner.email},
                                {name: 'NOME UTENTE', datum: couponOwner.complete_name + " "+ couponOwner.last_name},
                            ].map((el, index) => [
                                <div key={el.name} style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{fontWeight: 'bold'}}>{el.name}:</div>
                                    <div>{el.datum}</div>
                                </div>,
                                index !== 4 && <Divider style={{margin: '15px 0px 15px 0px'}} key={`${el.name}-div`}/>
                            ])}
                        </div>  
                    </CardContent>
                </Card>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={() => setOpenConfirmation(true)} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Conferma
                    </Button>
                    <Button onClick={() => cancelScan()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Annulla
                    </Button>
                </div>

                <ConfirmPopUp
                open={openConfirmation}
                onClose={() => closeConfirmation()}
                onConfirm={() => confirmBurnCoupon()}
                text="Confermi bruciatura coupon?"
                title="Conferma"
                />
            </div>
        )
    }

    const closeConfirmation = () => {
        setScanEnabled(true)
        setOpenConfirmation(false)
    }

    const cancelScan = () => {
        setScanEnabled(true)
        setActiveView('main')
    }

    const confirmView = () => {
        return (
            <div>
                <Card>
                    <CardContent>
                        <div style={{fontSize: '13px', color:"#8b8b8b"}}>
                            {[
                                {name: 'DATA', datum: moment(getDate(action)).format('DD-MM-YY')},
                                {name: 'UTENTE', datum: action.user_name},
                                {name: 'EMAIL', datum: action.user_email},
                                {name: 'DETTAGLIO', datum: getDetail(action)},
                                {name: 'STATO', datum: action.status}
                            ].map((el, index) => [
                                <div key={el.name} style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <div style={{fontWeight: 'bold'}}>{el.name}:</div>
                                    <div>{el.datum}</div>
                                </div>,
                                index !== 4 && <Divider style={{margin: '15px 0px 15px 0px'}} key={`${el.name}-div`}/>
                            ])}
                        </div>  
                    </CardContent>
                </Card>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={() => setOpenConfirmation(true)} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Conferma
                    </Button>
                    <Button onClick={() => cancelScan()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Annulla
                    </Button>
                </div>

                <ConfirmPopUp
                open={openConfirmation}
                onClose={() => closeConfirmation()}
                onConfirm={() => confirmAction()}
                text="Se sicuro di voler confermare l'interazione?"
                title="Conferma"
                />
            </div>
        )
    }

    const confirmEntry = () => {
        return (
            <div>
                <Card>
                    <CardContent>
                        <div style={{fontSize: '13px', color:"#8b8b8b"}}>
                            Pass fiera: {fair.title}
                            <br/>
                            Nome: {user.complete_name+" "+user.last_name}
                            <br/>
                            Email: {user.email}
                        </div>  
                    </CardContent>
                </Card>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={() => setOpenConfirmation(true)} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Conferma
                    </Button>
                    <Button onClick={() => cancelScan()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Annulla
                    </Button>
                </div>

                <ConfirmPopUp
                open={openConfirmation}
                onClose={() => closeConfirmation()}
                onConfirm={() => confirmEntryAction()}
                text="Se sicuro di voler confermare l'interazione?"
                title="Conferma"
                />
            </div>
        )
    }

    const confirmFair = () => {
        return (
            <div>
                <Card>
                    <CardContent>
                        <div style={{fontSize: '13px', color:"#8b8b8b"}}>
                            {fair.title} <br/>
                            {fair.description}
                            
                        </div>  
                    </CardContent>
                </Card>

                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Button onClick={() => setOpenConfirmation(true)} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Conferma
                    </Button>
                    <Button onClick={() => cancelScan()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                    Annulla
                    </Button>
                </div>

                <ConfirmPopUp
                open={openConfirmation}
                onClose={() => closeConfirmation()}
                onConfirm={() => confirmAction()}
                text="Se sicuro di voler confermare l'interazione?"
                title="Conferma"
                />
            </div>
        )
    }

    return (
        <div>
            <Topbar history={props.history} from={props.path} goBack={props.history.goBack}/>
                
            {viewController(activeView)}

            <BottombarMerchant from={props.location.pathname} />
            
            <LoadingOverlay show={submitLoading} />
        </div>
    )
}

export default withFirebase(withBmarkenAPI(Scanner))