import React from 'react'
import {withRouter } from "react-router-dom"
class Redirect extends React.Component {
    
    constructor(props) {
		super(props);
    }
    
    componentDidMount() {
        this.props.history.push({ pathname: this.props.path });
    }

    render() {
        return (null);
    }
}

export default withRouter(Redirect)