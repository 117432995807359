import React from 'react'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { ROUTES } from '../constants'

const bottomRoutes = [
  { path: ROUTES.scansiona, text: 'scansiona'},
  { path: ROUTES.interazioni, text: 'interazioni'},
  { path: ROUTES.assistenza_merchant, text: 'assistenza'},
]

const BottombarMerchant = (props) => [
  <div key={'bottombar-separator'} style={{width: '100%', height: '64px', marginTop: '25px'}}></div>,
  <AppBar className="bottom-bar-merchant" key={'bottombar'} position="fixed" color="primary" style={{ top: 'auto', bottom: 0, background: '#f2f2f2', paddingTop: '3px', paddingBottom:'5px', zIndex: '2' }}>
    <Toolbar style={{ justifyContent: 'space-evenly'}}>
      {bottomRoutes.map((el, index) => (
        <Link key={index} to={el.path} style={{textDecoration: 'none', width: '80px', border: '1px solid gray', borderRadius: '5px', padding: '4px 8px'}}>
          <div style={{ flexDirection: 'column', textAlign: 'center'}}>
            <div style={{textAlign: 'center', color: '#707173', fontSize: '14px'}}>{el.text}</div>
          </div>
        </Link>
      ))}
    </Toolbar>
  </AppBar>
]

export default BottombarMerchant;