import React from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import LogoHeader from "../common_components/LogoHeader";
import { fullMobileWidth, boldCenteredText } from "../styles/helpers";
import SposiIcon from "../common_components/SposiIcon";
import BackGroundComponent from "../common_components/BackGroundComponent";
import Ricerca from "./Ricerca";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import {
  centeredText,
  centeredBoldParagraph,
  grayParagraph,
} from "../styles/helpers";
import ActionButtons from "../common_components/ActionButtons";
import MerchantShortInfo from "../common_components/MerchantShortInfo";
import ActionForms from "../common_components/ActionForms";
import ActionSuccessPage from "../common_components/ActionSuccessPage";
import QRCodeContainer from "../common_components/QRCodeContainer";
import HandleUserRedirect from "../helpers/HandleUserRedirect";
import {
  goBackFromView,
  translateError,
  isFutureDate,
  getIDsFromCode,
  parse_query_string,
} from "../utils/utils";
import { SharedContext } from "../utils/Session";
import { ROUTES } from "../constants";
import ConfirmPopUp from "../common_components/ConfirmPopUp";
import LoadingOverlay from "../common_components/LoadingOverlay";
import SposiLoading from "../common_components/SposiLoading";
import Button from "@material-ui/core/Button";
import { Route, Switch } from "react-router-dom";
import QrReader from "react-qr-reader";
const moment = require("moment");

class Visita extends React.PureComponent {
  static contextType = SharedContext;

  constructor(props) {
    super(props);

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.state = {
      loading: false,
      activeView: "main",
      selectedMerchant: null,
      selectedAction: "VISIT",
      openConfirm: false,
      qrCode: null,

      visitDate: moment(),
      purchaseDate: moment(),
      visitReason: "",
      purchaseValue: 0,
      review: "",

      rewardingAction: null,
      reward: 20,

      scanEnabled: true,
    };
  }

  componentDidMount() {
    const path = this.props.history.location.pathname.split("/");
    if (
      path.length > 2 &&
      path[path.length - 2] == "operatore" &&
      path[path.length - 1]
    ) {
      const merchantID = path[path.length - 1];
      this.setState({ loading: true });
      this.props.bmarkenAPI
        .getMerchant(merchantID, () => this.props.firebase.doRefreshToken())
        .then((m) => {
          this.setState({
            selectedMerchant: m,
            activeView: "form",
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({ loading: false });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  }

  handleTextChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDateChange = (dateName, dateValue) => {
    this.setState({ [dateName]: dateValue });
  };

  onSelectMerchant = (merchant) => {
    this.setState({
      selectedMerchant: merchant,
      activeView: "form",
    });
    this.props.history.push(this.props.match.url + "/operatore/" + merchant.id);
  };

  onSubmit = () => {
    if (isFutureDate(this.state.visitDate)) {
      this.context.dispatchMessage(
        "Errore: inserire la data odierna o una data precedente",
        "error"
      );
      return;
    }
    if (this.state.visitReason === "") {
      this.context.dispatchMessage(
        "Errore: inserire la motivazione della visita",
        "error"
      );
      return;
    }
    //sul success mostro pagina risultato
    this.setState({ activeView: "suspense" });
    this.props.bmarkenAPI
      .createRewardingAction(
        this.state.selectedAction,
        this.state.selectedMerchant.id,
        "",
        parseInt(this.state.purchaseValue),
        this.state.visitReason,
        this.state.selectedAction === "VISIT"
          ? this.state.visitDate.format("YYYY-MM-DD")
          : this.state.purchaseDate.format("YYYY-MM-DD"),
        "",
        () => this.props.firebase.doRefreshToken()
      )
      .then((ra) => {
        this.setState({
          loading: false,
          rewardingAction: ra,
          activeView: "result",
          reward: ra.reward,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, activeView: "form" });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });
  };

  onChangeAction = (action) => {
    if (action === "PURCHASE") {
      this.props.history.push(
        ROUTES.acquisto + "/operatore/" + this.state.selectedMerchant.id
      );
    } else if (action === "REVIEW") {
      this.props.history.push(
        ROUTES.recensione + "/operatore/" + this.state.selectedMerchant.id
      );
    } else {
      this.props.history.push(
        ROUTES.visita + "/operatore/" + this.state.selectedMerchant.id
      );
    }
  };

  searchView = ({ match, history }) => (
    <div className="main-content">
      <SposiIcon iconName="visita" active={true} main={true} />
      <p
        style={{
          ...centeredBoldParagraph,
          color: "#dc005a",
          marginBottom: "2px",
        }}
      >
        <span className="item-block">Ottieni fino a 20 punti</span>
        <span className="rowTitlePoint item-block">
          per la VISITA di un fornitore
        </span>
      </p>
      <p style={{ ...grayParagraph }}>SCEGLI IL FORNITORE</p>
      <Ricerca
        onSelect={this.onSelectMerchant}
        {...this.props}
        history={history}
        match={match}
      />
    </div>
  );

  formView = () => (
    <div className="main-content">
      <SposiIcon iconName="acquisto" active={true} main={true} />
      <p
        style={{
          ...centeredBoldParagraph,
          color: "#dc005a",
          marginBottom: "2px",
        }}
      >
        Vuoi ottenere i punti per
      </p>
      <p
        style={{
          ...centeredBoldParagraph,
          color: "#dc005a",
          marginBottom: "10px",
          marginTop: "0px",
        }}
      >
        la VISITA di:
      </p>
      <MerchantShortInfo merchant={this.state.selectedMerchant} />
      <ActionButtons
        selected={this.state.selectedAction}
        maxVisitReward={20}
        maxPurcahseReward={100}
        maxReviewReward={50}
        onClickCallback={this.onChangeAction}
      />
      <br />
      <ActionForms
        selected={this.state.selectedAction}
        data={this.state.actionData}
        onTextChange={this.handleTextChange}
        onDateChange={this.handleDateChange}
        visitDate={this.state.visitDate}
        visitReason={this.state.visitReason}
        purchaseDate={this.state.purchaseDate}
        purchaseValue={this.state.purchaseValue}
        review={this.state.review}
        onSubmit={() => this.setState({ openConfirm: true })}
      />
      <ConfirmPopUp
        open={this.state.openConfirm}
        onClose={() => this.setState({ openConfirm: false })}
        onConfirm={() => this.onSubmit()}
        text="Confermi l'inserimento dell'interazione visita? Devi esserti recato presso questo fornitore per informazioni inerenti al tuo matrimonio ed esserti fatto riconoscere personalmente"
        title="Conferma"
      />
    </div>
  );

  /*showQRCode = () => {
    this.setState({loading: true})
    this.props.bmarkenAPI.getRewadingActionQRCode(this.state.rewardingAction.id, () => this.props.firebase.doRefreshToken())
    .then(up => {
      this.setState({
        activeView: 'qrCode',
        qrCode: up.id,
        loading: false,
      })
    })
    .catch(error => {
      this.setState({loading: false})
			error = translateError(error)
			this.context.dispatchMessage(error.message, 'error')
    })
  }*/

  openScanner = () => {
    this.setState({
      activeView: "scanner",
    });
  };

  handleError = () => {
    this.context.dispatchMessage(
      "Scanner in errore. Controllare che il browser in uso non stia bloccando la fotocamera e ritentare",
      "error"
    );
  };

  handleScan = (code) => {
    if (!this.state.scanEnabled) return;

    if (code) {
      this.setState({
        scanEnabled: false,
        loading: true,
      });

      //scansiono solo qrcode di tipo "MERCHANT_<id_merchant>"
      const ids = getIDsFromCode(code, "MERCHANT_", 1);
      if (ids === null) {
        //errore codice scannerizzato non è un id valido
        this.setState({
          scanEnabled: true,
          loading: false,
        });
        this.context.dispatchMessage(
          "Il codice scannerizzato non appartiene ad un negozio",
          "error"
        );
        return;
      }

      const idMerchant = ids[0];
      this.props.bmarkenAPI
        .getRewadingActionQRCode(this.state.rewardingAction.id, () =>
          this.props.firebase.doRefreshToken()
        )
        .then((up) => {
          return this.props.bmarkenAPI.confirmAction(
            up.id,
            "",
            idMerchant,
            () => this.props.firebase.doRefreshToken()
          );
        })
        .then(() => {
          this.setState({
            scanEnabled: true,
            loading: false,
          });

          this.context.dispatchMessage(
            "Azione confermata con successo",
            "info"
          );
          this.props.history.push(this.props.match.url + "/ricerca");
        })
        .catch((error) => {
          this.setState({
            loading: false,
            scanEnabled: true,
          });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  };

  resultView = () => (
    <div className="main-content">
      <ActionSuccessPage
        history={this.props.history}
        merchant={this.state.selectedMerchant}
        reward={this.state.reward}
        selected={this.state.selectedAction}
        openScanner={() => this.openScanner()}
      />
    </div>
  );

  scannerView = () => (
    <div>
      <Topbar
        history={this.props.history}
        from={this.props.path}
        goBack={() => this.setState({ activeView: "result" })}
      />
      <div>
        <div>
          <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: "100%" }}
          />
        </div>
        <p style={{ ...centeredText, fontSize: 18, color: "#9CA1A1" }}>
          Inquadra il QR code del negozio che hai visitato per confermare
          l'interazione e raddoppiare i punti!
        </p>
      </div>
    </div>
  );

  mainView = () => (
    <div className="main-content">
      <BackGroundComponent background={"visita"} />
      <div
        style={{
          ...boldCenteredText,
          ...fullMobileWidth,
          fontWeight: "normal",
        }}
      >
        <div style={{ paddingBottom: "50px" }}>
          <div>
            <LogoHeader white={true} />
          </div>
          <div style={{ color: "#fff" }}>HAI VISITATO UN FORNITORE?</div>
          <div
            style={{ color: "#fff", marginTop: "10px", marginBottom: "30px" }}
          >
            {" "}
            Ottieni subito i tuoi punti
          </div>
          <SposiIcon iconName="visita" active={true} main={true} />
          <center>
            <Button
              /*onClick={() => this.setState({ activeView: 'search'})}*/
              onClick={() =>
                this.props.history.push(this.props.match.url + "/ricerca")
              }
              variant="contained"
              color="primary"
              className="btnProcedi"
              style={{ fontWeight: "bold" }}
            >
              Procedi
            </Button>
          </center>
        </div>
      </div>
    </div>
  );

  suspenseView = () => (
    <div>
      <SposiLoading show={true} />
    </div>
  );
  operatoreView = () => {
    switch (this.state.activeView) {
      case "result":
        return this.resultView();
      case "suspense":
        return this.suspenseView();
      case "scanner":
        return this.scannerView();
      default:
        return this.formView();
    }
  };
  viewController = () => {
    switch (this.state.activeView) {
      case "suspense":
        return this.suspenseView();
      default:
        return this.mainView();
    }
  };

  goBack = () => {
    /*
    const backView = goBackFromView(this.state.activeView, this.state.ricercaState)
    if(backView.view === null) this.props.history.goBack()
    else this.setState({activeView: backView.view, ricercaState: backView.subView})
    */
    this.props.history.goBack();
  };

  render() {
    return (
      <div style={centeredText}>
        <Topbar goBack={() => this.goBack()} />
        {/*this.viewController(this.state.activeView)*/}
        <Switch>
          <Route
            path={`${this.props.match.path}/ricerca`}
            component={this.searchView}
          />
          <Route
            path={`${this.props.match.path}/operatore`}
            component={this.operatoreView}
          />
          <Route
            path={`${this.props.match.path}`}
            component={this.viewController}
          />
        </Switch>

        <Bottombar from={this.props.location.pathname} />
        <LoadingOverlay show={this.state.loading} />
      </div>
    );
  }
}

export default withBmarkenAPI(withFirebase(Visita));
