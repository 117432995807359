import React, { useState, useContext, useEffect } from "react";
import Topbar from "../common_components/Topbar";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { SharedContext } from "../utils/Session";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ROUTES } from "../constants";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import {
  centeredText,
  centeredBoldParagraph,
  buttonStyle,
  fullMobileWidth,
} from "../styles/helpers";
import { translateError, parseBmeIntToInt } from "../utils/utils";
import { withBmarkenAPI } from "../utils/Api";
import Button from "@material-ui/core/Button";
import { withFirebase } from "../utils/Firebase";
import BottombarMerchant from "../common_components/BottombarMerchant";
import TablePagination from "@material-ui/core/TablePagination";
import ActionsTable from "../common_components/ActionsTable";
import PrizeTable from "../common_components/PrizeTable";
import ConfirmPopUp from "../common_components/ConfirmPopUp";
import iconUser from "../styles/images/icon-user.png";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import moment from "moment";

const StatisticheUtenti = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [actions, setActions] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [view, setView] = useState("main");
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("-issuedValue");
  const [email, setEmail] = useState("");
  const { dispatchMessage } = useContext(SharedContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [userPrizes, setUserPrizes] = useState([]);
  const [points, setPoints] = useState(0);

  const getData = (p, r, o, e) => {
    setSubmitLoading(true);

    props.bmarkenAPI
      .getUserStatsWithPointsCount(e, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then((countResults) => {
        setCount(countResults);
        return props.bmarkenAPI.getUserStatsWithPoints(p, r, o, e, () => {
          return props.firebase.doRefreshToken(true);
        });
      })
      .then((users) => {
        if (users && users.length > 0) {
          setUsers(users);
          setSubmitLoading(false);
        } else {
          setUsers([]);
          setSubmitLoading(false);
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const changeStatus = (action, userID) => {
    setSubmitLoading(true);
    props.bmarkenAPI
      .changeUserStatus(userID, action, () => props.firebase.doRefreshToken())
      .then(() => {
        setSubmitLoading(false);
        setView("main");
        getData(page, rowsPerPage, orderBy, "");
        dispatchMessage("Azione eseguita con successo", "info");
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  useEffect(() => {
    setSubmitLoading(true);
    let p = page;
    let r = rowsPerPage;
    let o = orderBy;

    if (!p) p = 0;
    if (!r) r = 25;
    if (!o) o = "-issuedValue";

    getData(p, r, o, "");
  }, []);

  const download = () => {
    setSubmitLoading(true);
    props.bmarkenAPI
      .downloadUserStats(() => props.firebase.doRefreshToken())
      .then((s) => {
        setSubmitLoading(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(newPage, rowsPerPage, orderBy, "");
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getData(page, newRowsPerPage, orderBy, "");
  };

  const getWelcomeRow = (createdAt) => {
    const row = {
      action_date: createdAt,
      business_name: "SposiClub.it",
      created_at: createdAt,
      id: "welcome-0000",
      status: "CONFIRMED",
      type: "GIFT",
      reward: 1000,
    };

    return row;
  };

  const getCompleteProfileRow = (user) => {
    const row = {
      action_date: user.created_at,
      business_name: "SposiClub.it",
      created_at: user.created_at,
      id: "profile-0000",
      status: "CONFIRMED",
      type: "PROFILE50",
      reward: 5000,
    };

    return row;
  };

  const showActions = (user) => {
    props.bmarkenAPI
      .getAllRewardingActions(user.id, () => props.firebase.doRefreshToken())
      .then((actions) => {
        if (!actions || actions === null) {
          actions = [];
        }

        let customFields = JSON.parse(user.custom_fields);
        const profileComplete =
          customFields.profile_complete != null &&
          customFields.profile_complete != ""
            ? customFields.profile_complete
            : false;
        if (profileComplete) {
          actions.push(getCompleteProfileRow(user));
        }

        actions.push(getWelcomeRow(user.created_at));
        setActions(actions);

        setSelectedUser(user);
        setView("actions");
        setSubmitLoading(false);

        return props.bmarkenAPI.getProducts(user.id, () =>
          props.firebase.doRefreshToken()
        );
      })
      .then((products) => {
        let card = null;
        let userPrizes = [];

        products.map((product, index) => {
          if (product.default) {
            card = product;
          } else {
            userPrizes.push(product);
          }
        });

        setPoints(card !== null ? parseBmeIntToInt(card.available_value) : 0);
        setUserPrizes(userPrizes);
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const getIconColor = (status) => {
    let color;
    switch (status) {
      case 2:
        color = "red";
        break;
      default:
        color = "green";
    }
    return color;
  };

  const handleChangeOrderBy = (event) => {
    setOrderBy(event.target.value);
    setPage(0);
    getData(0, rowsPerPage, event.target.value, "");
  };

  const onChange = (event) => {
    setEmail(event.target.value);
  };

  const searchEmail = () => {
    let e = email;
    e = e.trim();

    setSubmitLoading(true);
    let p = page;
    let r = rowsPerPage;
    let o = orderBy;

    if (!p) p = 0;
    if (!r) r = 25;
    if (!o) o = "email";

    getData(p, r, o, e);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  if (view === "actions") {
    const changeStatusLabel =
      selectedUser.status == 0 ? "Disabilita Utente" : "Abilita Utente";
    const changeStatusMessage =
      selectedUser.status == 0 ? "disabilitare" : "abilitare";
    const action = selectedUser.status == 0 ? "DISABLE" : "ENABLE";
    return (
      <div>
        <Topbar history={props.history} goBack={() => setView("main")} />
        <div className="main-content" style={centeredText}>
          <br />
          <div className="col-user">
            <span className="col-user-img">
              <img
                style={{ width: 90, height: 90 }}
                src={selectedUser.picture ? selectedUser.picture : iconUser}
              />
            </span>
            <span className="col-user-name">
              {selectedUser.complete_name + " " + selectedUser.last_name}
            </span>
            <span className="col-user-name">
              {"(" + selectedUser.email + ")"}
            </span>
          </div>
          <Button
            onClick={() => setOpenConfirm(true)}
            style={{ ...buttonStyle, marginTop: "25px", marginBottom: "20px" }}
            variant="outlined"
            color="primary"
          >
            {changeStatusLabel}
          </Button>
          <PrizeTable prizes={userPrizes} />
          <br />
          <br />
          <ActionsTable showQRCode={null} title={null} actions={actions} />
          <ConfirmPopUp
            open={openConfirm}
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => changeStatus(action, selectedUser.id)}
            text={
              "Vuoi " +
              changeStatusMessage +
              " l'accesso e l'utilizzo dell'applicazione all'utente " +
              selectedUser.email +
              "?"
            }
            title="Conferma"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Topbar
          history={props.history}
          goBack={() => props.history.push(ROUTES.base)}
        />
        <div style={centeredText}>
          <div
            style={{
              ...centeredBoldParagraph,
              color: "#9CA1A1",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Statistiche Utenti
          </div>

          <Grid justify="space-between" container spacing={24}>
            <Grid item>
              <FormControl>
                <InputLabel id="demo-simple-select-helper-label">
                  Ordina per
                </InputLabel>
                <Select
                  id="select-orderby"
                  value={orderBy}
                  onChange={handleChangeOrderBy}
                >
                  <MenuItem value={"email"}>Email (crescente)</MenuItem>
                  <MenuItem value={"-email"}>Email (decrescente)</MenuItem>
                  <MenuItem value={"status"}>Stato (crescente)</MenuItem>
                  <MenuItem value={"-status"}>Stato (decrescente)</MenuItem>
                  <MenuItem value={"issuedValue"}>
                    Punti ricevuti (crescente)
                  </MenuItem>
                  <MenuItem value={"-issuedValue"}>
                    Punti ricevuti (decrescente)
                  </MenuItem>
                  <MenuItem value={"usedValue"}>
                    Punti utilizzati o cancellati (crescente)
                  </MenuItem>
                  <MenuItem value={"-usedValue"}>
                    Punti utilizzati o cancellati (decrescente)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <div className="legenda-punti">
                <div>
                  <span className="info-circle green"></span> Utenti attivi
                </div>
                <div>
                  <span className="info-circle red"></span> Utenti disabilitati
                </div>
              </div>
            </Grid>
            <Grid item>
              <div>
                <Button
                  onClick={() => download()}
                  style={{
                    ...buttonStyle,
                    marginTop: "25px",
                    marginBottom: "20px",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Scarica Utenti
                </Button>
              </div>
            </Grid>
          </Grid>
          <div>
            <div>
              <TextField
                id="outlined-email-input"
                label="Email"
                name="email"
                margin="normal"
                variant="outlined"
                style={fullMobileWidth}
                value={email}
                onChange={onChange}
              />
            </div>
            <Button
              onClick={() => searchEmail()}
              style={{
                ...buttonStyle,
                width: "100px",
                marginTop: "25px",
                marginBottom: "100px",
              }}
              variant="outlined"
              color="primary"
            >
              Cerca
            </Button>
          </div>
          <Card
            style={{
              marginTop: "25px",
              paddingLeft: "5px",
              paddingRight: "5px",
              overflow: "scroll",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Nome e Cognome
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    P.TI ricevuti
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    P.TI utilizzati o cancellati
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Provincia
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Data Matrimonio
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((u) => {
                  const customFields = JSON.parse(u.user.custom_fields);
                  const weddingDate = moment(customFields.wedding_date).format(
                    "DD-MM-YY"
                  );
                  const weddingProvince = customFields.wedding_province;
                  return (
                    <TableRow
                      onClick={() => showActions(u.user)}
                      key={u.user.id}
                      style={{ cursor: "pointer" }}
                      hover={true}
                    >
                      <TableCell align="right">
                        <span
                          className={
                            "info-circle " + getIconColor(u.user.status)
                          }
                        ></span>
                      </TableCell>
                      <TableCell>{u.user.email}</TableCell>
                      <TableCell>
                        {u.user.complete_name + " " + u.user.last_name}
                      </TableCell>
                      <TableCell>
                        {parseBmeIntToInt(u.performance.issued_value)}
                      </TableCell>
                      <TableCell>
                        {parseBmeIntToInt(u.performance.used_value)}
                      </TableCell>
                      <TableCell>{weddingProvince}</TableCell>
                      <TableCell>{weddingDate}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Card>
          <LoadingOverlay show={submitLoading} />
        </div>
        <BottombarMerchant from={props.location.pathname} />
      </div>
    );
  }
};

export default withFirebase(withBmarkenAPI(StatisticheUtenti));
