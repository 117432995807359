import React from "react";
import AcquistoOn from "../styles/images/Acquisto_On.svg";
import AcquistoOff from "../styles/images/Acquisto_Off.svg";
import VisitaOn from "../styles/images/Visita_On.svg";
import VisitaOff from "../styles/images/Visita_Off.svg";
import RecensioneOn from "../styles/images/Recensione_On.svg";
import RecensioneOff from "../styles/images/Recensione_Off.svg";
import PuntiOn from "../styles/images/Punti_On.svg";
import PuntiOff from "../styles/images/Punti_Off.svg";
import PremiOn from "../styles/images/Premi_On.svg";
import PremiOff from "../styles/images/Premi_Off.svg";
import FieraOn from "../styles/images/Fiera_On.svg";
import FieraOff from "../styles/images/Fiera_Off.svg";
import MapOn from "../styles/images/ico_map_2.png";
import PassOn from "../styles/images/free_pass.png";
import VisitMerchOn from "../styles/images/visit_merch.png";
import ReservationOn from "../styles/images/reservations.png";
import EventOn from "../styles/images/event.png";
import AgendaOn from "../styles/images/agenda.png";
import MerchListOn from "../styles/images/merch_list.png";
import StandMapOn from "../styles/images/map.png";
import IndicationsOn from "../styles/images/indications.png";
import WhatsappOn from "../styles/images/ico_whatsapp.png";
import TelOn from "../styles/images/ico_tel.png";
import EmailOn from "../styles/images/ico_email.png";
import MerchantMapOn from "../styles/images/ico_map.png";
import WebOn from "../styles/images/ico_piuinfo.png";

const svgMap = {
  acquisto: [AcquistoOn, AcquistoOff],
  visita: [VisitaOn, VisitaOff],
  recensione: [RecensioneOn, RecensioneOff],
  punti: [PuntiOn, PuntiOff],
  premi: [PremiOn, PremiOff],
  fiera: [FieraOn, FieraOff],
  map: [MapOn, MapOn],
  free_pass: [PassOn, PassOn],
  visit_merch: [VisitMerchOn, VisitMerchOn],
  reservations: [ReservationOn, ReservationOn],
  event: [EventOn, EventOn],
  agenda: [AgendaOn, AgendaOn],
  merch_list: [MerchListOn, MerchListOn],
  stand_map: [StandMapOn, StandMapOn],
  indications: [IndicationsOn, IndicationsOn],
  whatsapp: [WhatsappOn, WhatsappOn],
  phonecall: [TelOn, TelOn],
  contact_email: [EmailOn, EmailOn],
  merchant_map: [MerchantMapOn, MerchantMapOn],
  info: [WebOn, WebOn],
};

const SposiIcon = (props) => {
  const selectedSvg = props.active
    ? svgMap[props.iconName][0]
    : svgMap[props.iconName][1];
  const standardHeight = props.main ? "72px" : "35px";
  const h = props.forceHeight ? props.forceHeight : standardHeight;
  return (
    <img
      style={{ height: h, cursor: "pointer" }}
      alt={`${props.iconName}-icon`}
      src={selectedSvg}
      onClick={props.onClick}
    />
  );
};

export default SposiIcon;
