import React from "react";
import { Link } from "react-router-dom";
import LogoHeader from "../common_components/LogoHeader";
import { fullMobileWidth, boldCenteredText } from "../styles/helpers";
import { ROUTES } from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import Button from "../common_components/Button";
import Card from "../common_components/Card";

import bgPrizes from "../styles/images/img_prizes.png";
import bgBoxes from "../styles/images/img_merchant.png";
import BackGroundComponent from "../common_components/BackGroundComponent";
import bgFiera from "../styles/images/img_fiere.png";
import Topbar from "../common_components/Topbar";

const useStyles = makeStyles({
  root: {
    border: "2px",
    color: "white",
  },
  label: {
    color: "#ff2ba4",
  },
});

const Label = ({ text }) => {
  return (
    <>
      <div
        style={{
          backgroundColor: "white",
          height: 6,
          width: 6,
          borderRadius: 6,
          margin: "15px auto 0",
        }}
      ></div>
      <div
        style={{
          ...fullMobileWidth,
          marginBottom: "10px",
          marginTop: "10px",
          //color: "white",
        }}
      >
        {text}
      </div>
    </>
  );
};

const Accesso = (props) => {
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <BackGroundComponent background={"home"} />
      <Topbar history={props.history} from={props.path} key={"topBar"} />

      <div style={{ ...boldCenteredText, fontWeight: "normal", padding: 10 }}>
        <LogoHeader /*white*/ />

        <div style={{ marginTop: "-50px" }}>
          <Label text="Sei già registrato?" />
          <Button component={Link} to={ROUTES.login} bgColor={true}>
            Accedi
          </Button>
        </div>
        <div style={{ marginTop: "-20px" }}>
          <Label text="Sei un nuovo utente?" />
          <Button component={Link} to={ROUTES.registrazione} bgColor={true}>
            Iscriviti
          </Button>
          <div
            style={{
              ...fullMobileWidth,
              fontSize: "12px",
              marginTop: "10px",
              //color: "white",
            }}
          >
            Riceverai subito 10 punti di benvenuto
          </div>
        </div>

        <Card
          bg={bgFiera}
          title="VISITA LE FIERE CONVENZIONATE"
          contentHeight={true}
        >
          <Button component={Link} to="/fiera">
            Elenco fiere
          </Button>
        </Card>

        <Card bg={bgBoxes} title="SCOPRI I FORNITORI DELLA TUA ZONA">
          <Button href="/elenco-fornitori">Inizia la ricerca</Button>
        </Card>

        <Card bg={bgPrizes} title="VINCI PREMI SICURI">
          <Button href="https://www.sposiclub.it/static/come-funziona.html">
            Come funziona SposiClub
          </Button>
        </Card>

        {/* OLD STYLE
        <Card bg={bgPrizes} title="Organizza le tue nozze e vinci premi sicuri">
          <Button href="https://www.sposiclub.it/static/come-funziona.html">
            Scopri come funziona
          </Button>
        </Card>

        <Card bg={bgBoxes} title="Cerca i fornitori convenzionati">
          {
          <Button href="https://www.guidasposi.it/">
            Vai alle schede fornitori
          </Button>
          }
          <Button href="/fiera">FIERE</Button>
          <Button href="/elenco-fornitori">ELENCO FORNITORI</Button>
        </Card>
        */}

        {/*<Label text="Sei già registrato?" />
        <Button component={Link} to={ROUTES.login} bgColor={true}>
          Accedi
        </Button>

        <Label text="Sei un nuovo utente?" />
        <Button component={Link} to={ROUTES.registrazione} bgColor={true}>
          Iscriviti
      </Button>
        <div
          style={{
            ...fullMobileWidth,
            fontSize: "12px",
            marginTop: "10px",
            //color: "white",
          }}
        >
          Riceverai subito 10 punti di benvenuto
        </div>*/}

        <Label text="Sei un operatore?" />
        {/*
        <Button component={Link} to="https://sposiclubdemo.bmarken.com/manager">Accesso operatori</Button>
        */}
        <Button href="https://sposiclubdemo.bmarken.com/manager" bgColor={true}>
          Accesso operatori
        </Button>

        {/*<hr
          style={{
            marginTop: 50,
            height: 1,
            border: 0,
            backgroundColor: "white",
          }}
        />*/}

        <div style={{ ...fullMobileWidth, marginTop: 50 }}>
          <p
            style={{ fontSize: 12, marginBottom: 30, border: "1px solid grey" }}
          >
            Emmebie srl - Corso Moncalieri, 506/28 - 10133 TORINO - ITALY
            <br />
            P.IVA 08325390014 -{" "}
            <a
              style={{ fontSize: 12 }}
              target="legal"
              href="https://www.emmebie.it/policy-privacy/"
            >
              {/*Legal & privacy*/} Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accesso;
