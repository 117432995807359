import React, { useState, useContext, useEffect } from "react";
import Topbar from "../common_components/Topbar";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { SharedContext } from "../utils/Session";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { ROUTES } from "../constants";
import Card from "@material-ui/core/Card";
import {
  centeredText,
  centeredBoldParagraph,
  buttonStyle,
} from "../styles/helpers";
import { translateError, parseBmeIntToInt, setUserInfo } from "../utils/utils";
import iconUser from "../styles/images/icon-user.png";
import { withBmarkenAPI } from "../utils/Api";
import Button from "@material-ui/core/Button";
import { withFirebase } from "../utils/Firebase";
import BottombarMerchant from "../common_components/BottombarMerchant";
import TablePagination from "@material-ui/core/TablePagination";
import ActionsTable from "../common_components/ActionsTable";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
var QRCode = require("qrcode.react");

const StatisticheOperatori = (props) => {
  const [merchants, setMerchants] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [actions, setActions] = useState([]);
  const [view, setView] = useState("main");
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("Name");
  const { dispatchMessage } = useContext(SharedContext);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [displayUserInfo, setDisplayUserInfo] = useState(null);

  const getData = (p, r, o) => {
    setSubmitLoading(true);

    props.bmarkenAPI
      .getMerchantStatsWithActionsCount(() => {
        return props.firebase.doRefreshToken(true);
      })
      .then((countResults) => {
        setCount(countResults);
        return props.bmarkenAPI.getMerchantStatsWithActions(p, r, o, () => {
          return props.firebase.doRefreshToken(true);
        });
      })
      .then((merchants) => {
        if (merchants && merchants.length > 0) {
          setMerchants(merchants);
          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
          dispatchMessage("Nessun negozio trovato!", "error");
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  useEffect(() => {
    setSubmitLoading(true);
    getData(page, rowsPerPage, orderBy);
  }, []);

  const download = () => {
    setSubmitLoading(true);
    props.bmarkenAPI
      .downloadMerchantStats(() => props.firebase.doRefreshToken())
      .then((s) => {
        console.log(s);
        setSubmitLoading(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(newPage, rowsPerPage, orderBy);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    getData(page, newRowsPerPage, orderBy);
  };

  const showActions = (merchant) => {
    setSubmitLoading(true);
    setDisplayUserInfo(null);
    props.bmarkenAPI
      .getBusinessRewardingAction(merchant.id, "", () =>
        props.firebase.doRefreshToken()
      )
      .then((actions) => {
        if (actions !== null && actions !== undefined) {
          setActions(actions);
        } else {
          setActions([]);
        }

        setSelectedMerchant(merchant);
        setView("actions");
        setSubmitLoading(false);
      })
      .catch((error) => {
        setSubmitLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const updateHeaderTitle = (user) => {
    setDisplayUserInfo(user);
  };

  const handleChangeOrderBy = (event) => {
    console.log(event.target.value);
    setOrderBy(event.target.value);
    setPage(0);
    getData(0, rowsPerPage, event.target.value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  if (view === "actions") {
    let imageTag = null;
    if (displayUserInfo != null) {
      if (displayUserInfo.picture != "") {
        imageTag = (
          <img
            style={{ width: 90, height: 90 }}
            src={displayUserInfo.picture}
          />
        );
      } else {
        imageTag = <img style={{ width: 90, height: 90 }} src={iconUser} />;
      }
    }

    return (
      <div>
        <Topbar history={props.history} goBack={() => setView("main")} />
        <div className="main-content" style={centeredText}>
          <br />
          <div className="col-user">
            {imageTag != null && displayUserInfo != null ? (
              <span className="col-user-img">{imageTag}</span>
            ) : (
              <QRCode
                value={"MERCHANT_" + selectedMerchant.id}
                size={200}
                bgColor="white"
                fgColor="black"
              />
            )}

            {displayUserInfo == null ? (
              <div>
                <span className="col-user-name">{selectedMerchant.name}</span>
                <span className="col-user-name">
                  {"(" + selectedMerchant.manager_email + ")"}
                </span>
              </div>
            ) : null}
          </div>

          <ActionsTable
            updateUserPhoto={(u) => updateHeaderTitle(u)}
            merchantStatsView={true}
            showQRCode={null}
            title={null}
            actions={actions}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Topbar
          history={props.history}
          goBack={() => props.history.push(ROUTES.base)}
        />
        <div style={centeredText}>
          <div
            style={{
              ...centeredBoldParagraph,
              color: "#9CA1A1",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Statistiche Operatori
          </div>

          <Grid justify="space-between" container>
            <Grid item>
              <FormControl>
                <InputLabel id="demo-simple-select-helper-label">
                  Ordina per
                </InputLabel>
                <Select
                  id="select-orderby"
                  value={orderBy}
                  onChange={handleChangeOrderBy}
                >
                  <MenuItem value={"Name"}>Nome (crescente)</MenuItem>
                  <MenuItem value={"-Name"}>Nome (decrescente)</MenuItem>
                  <MenuItem value={"Categories"}>
                    Categoria (crescente)
                  </MenuItem>
                  <MenuItem value={"-Categories"}>
                    Categoria (decrescente)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item>
              <div>
                <Button
                  onClick={() => download()}
                  style={{
                    ...buttonStyle,
                    marginTop: "25px",
                    marginBottom: "20px",
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Scarica Report
                </Button>
              </div>
            </Grid>
          </Grid>
          <Card
            style={{
              marginTop: "25px",
              paddingLeft: "5px",
              paddingRight: "5px",
              overflow: "scroll",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Nome</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Mail manager
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Categoria
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    Interazioni eseguite
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchants.map((m) => {
                  if (m.merchant.type == "Loop") {
                    return null;
                  }
                  return (
                    <TableRow
                      onClick={() => showActions(m.merchant)}
                      key={m.merchant.id}
                      style={{ cursor: "pointer" }}
                      hover={true}
                    >
                      <TableCell>{m.merchant.name}</TableCell>
                      <TableCell>{m.merchant.manager_email}</TableCell>
                      <TableCell>
                        {m.merchant.categories.length > 0
                          ? m.merchant.categories[0]
                          : "-"}
                      </TableCell>
                      <TableCell>{m.performance.total_actions}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Card>
          <LoadingOverlay show={submitLoading} />
        </div>
        <BottombarMerchant from={props.location.pathname} />
      </div>
    );
  }
};

export default withFirebase(withBmarkenAPI(StatisticheOperatori));
