import React from 'react'
import FacebookIcon from '../styles/images/Facebook_Icon.png';
import GoogleIcon from '../styles/images/Google_Icon.png';

const SocialIcons = (props) => {
  return (
    <div>
      <img
        style={{height: '50px', marginRight: '20px', cursor: 'pointer'}}
        alt="FacebookIcon"
        src={FacebookIcon}
        onClick={props.onFacebookClick}
      />
      <img
        style={{height: '50px', marginLeft: '20px', cursor: 'pointer'}}
        alt="GoogleIcon"
        src={GoogleIcon}
        onClick={props.onGoogleClick}
      />
    </div>
  )
}

export default SocialIcons