import React from 'react'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import LogoHeader from '../common_components/LogoHeader'
import { fullMobileWidth, boldCenteredText } from '../styles/helpers'
import SposiIcon from '../common_components/SposiIcon'
import BackGroundComponent from '../common_components/BackGroundComponent'
import Grid from '@material-ui/core/Grid';
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { centeredText, centeredBoldParagraph, buttonStyle} from '../styles/helpers'
import QRCodeContainer from '../common_components/QRCodeContainer';
import Button from '@material-ui/core/Button'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { SharedContext } from '../utils/Session';
import { translateError, getDistanceFromLatLonInKm } from '../utils/utils';
import moment from "moment";
const numItemsPerRow = 2;

class Fiera extends React.PureComponent {

    static contextType = SharedContext

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            activeView: "main",
            qrcode: "",
            selectedFair: null,
            fairs: [],

            reward:20,
        };
    }

    componentDidMount() {
        this.setState({loading: true })
        this.props.bmarkenAPI.getContents("FAIR", () => this.props.firebase.doRefreshToken())
        .then(f => {
            this.setState({loading: false })
            if(f !== null)
                this.setState({fairs: f})
            else
                this.setState({fairs: []})
        })
        .catch(error => {
            this.setState({loading: false })
            error = translateError(error)
            this.context.dispatchMessage(error.message, 'error')
        })
    }

    showGPSError = (error) => {
        this.setState({loading: false })
        switch(error.code) {
            case error.PERMISSION_DENIED:
                this.context.dispatchMessage("Permesso di accesso alle coordinate GPS negate dallo user", 'error')
                break;
            case error.POSITION_UNAVAILABLE:
                this.context.dispatchMessage("Impossibile recuperare le coordinate GPS", 'error')
                break;
            case error.TIMEOUT:
                this.context.dispatchMessage("Richiesta di coordinate GPS non andata a buon fine, ritenta", 'error')
                break;
            case error.UNKNOWN_ERROR:
                this.context.dispatchMessage("Errore nel recupero delle coordinate GPS, ritenta", 'error')
                break;
        }
    }

    createFairQRCode = (position) => {

        let cf
        const fair = this.state.selectedFair
        if(fair.custom_fields !== ""){
            cf = JSON.parse(fair.custom_fields)
        }else{
            this.context.dispatchMessage("La fiera non ha associato delle coordinate, contattare l'assistenza", 'error')
            return
        }

        //console.log(cf, " -- ", position.coords)

        const distance = getDistanceFromLatLonInKm(cf.lat, cf.lng, position.coords.latitude, position.coords.longitude)
        //console.log("Distance: ", distance)
        if(distance > 4){
            this.context.dispatchMessage("Impossibile generare il codice per questa fiera: le vostre coordinate risultano troppo distanti", 'error')
            this.setState({loading: false })
            return
        }

        this.props.bmarkenAPI.getFairRewadingActionQRCode(fair.id, () => this.props.firebase.doRefreshToken())
        .then(up => {
            this.setState({
                loading: false,
                qrcode: up.id,
                activeView: "qrCode",
            });
        })
        .catch(error => {
            this.setState({loading: false })
            error = translateError(error)
            this.context.dispatchMessage(error.message, 'error')
        })
    }

    handleFairClick = (fair) => {
        this.setState({loading:true, selectedFair: fair})
        let cf = null
        if(fair.custom_fields !== ""){
            cf = JSON.parse(fair.custom_fields)
            if (cf.startingDate !== undefined) {
                let start = moment(cf.startingDate, "DD-MM-YYYY")
                let now = moment()
                //console.log(start)
                //console.log(now)
                if(now.isBefore(start)){
                    this.setState({loading: false })
                    this.context.dispatchMessage("Non è ancora possibile interagire con questa fiera", 'error')
                    return
                }
            }
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(this.createFairQRCode, this.showGPSError);
        } else {
            this.setState({loading: false })
            this.context.dispatchMessage("Impossibile recuperare le coordinate GPS, il browser non supporta questa funziona. Cambiare browser e riprovare", 'error')
        }
    }

    backToFairList = () => {
        this.setState({ activeView: 'list'})
    }

    viewController = (activeView) => {
        switch (activeView) {
            case 'list':
            return this.listView()
            case 'qrCode':
            return this.qrCodeView()
            default:
            return this.mainView()
        }
    }

    listView = () => {
        const fairsGrid = this.state.fairs.map((item, key) =>{
            let cf = null
            if(item.custom_fields !== ""){
                cf = JSON.parse(item.custom_fields)
            }

            return(
                <Grid item xs={6} key={key}>
                    <div>
                    <img width={135} height={135} style={{borderRadius: '50%'}} src={item.url} onClick={() => this.handleFairClick(item)}/>
                    <div style={{fontSize: 18}}>{item.title}</div>
                    {
                        cf !== null
                        ?(
                            <div>
                                <div style={{fontSize: 16}}>{cf.location}</div>
                                <div style={{fontSize: 16}}>{cf.dates}</div>
                            </div>
                        ):null
                    }
                    </div>
                </Grid>
            )
        });

        return(
            <div style={fullMobileWidth}>
                <p style={{...centeredBoldParagraph, color:"#dc005a", marginBottom: '2px'}}>
                    Vieni a una delle fiere convenzionate con SPOSI CLUB e riceverai fino a 20 punti per ogni espositore visitato
                </p>
                <p style={{...centeredBoldParagraph, fontSize: 14, marginBottom: '1px'}}>
                    Chiedi agli espositori della fiera di confermare con la loro app la tua visita allo stand e mostra il tuo codice personale.
                </p>
                <p style={{...centeredBoldParagraph, fontSize: 14, marginBottom: '1px'}}>
                    Puoi registrare un massimo di 10 visite.
                </p>
                <p style={{...centeredBoldParagraph, fontSize: 14, marginBottom: '1px'}}>
                    Clicca sulla fiera che stai visitando
                </p>

                <Grid container spacing={2} style={{marginTop: '10px'}}>
                    {fairsGrid}
                </Grid>
            </div>
        )
    }

    qrCodeView = () => (
        <div>
            <QRCodeContainer fairCode={this.state.qrcode}/>
            <Button onClick={() => this.backToFairList()} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px'}} variant="outlined" color="primary">
                Indietro
            </Button>
        </div>
    )

    mainView = () => (
        <div>
            <BackGroundComponent background={'fiera'}/>
            <div style={{
                ...boldCenteredText,
                ...fullMobileWidth,
                fontWeight: 'normal',
                width: '100%',
                }}>
                <div>
                    <LogoHeader white={true}/>
                </div>
                <div style={{color: '#fff'}}>Visita una delle <br/>FIERE CONVENZIONATE</div>
                <div style={{color: '#fff', marginTop: '10px', marginBottom: '30px'}}> Ottieni subito i tuoi punti</div>
                <SposiIcon iconName="fiera" active={true} main={true} />
                <center>
                    <Button
                    onClick={() => this.setState({activeView: "list"})}
                    variant="contained"
                    color="primary"
                    className="btnProcedi"
                    style={{fontWeight: 'bold'}}
                    >
                    Procedi
                    </Button>
                </center>
            </div>
        </div>
    )

    render() {
        return (
            <div style={centeredText}>
            <Topbar history={this.props.history} from={this.props.path} goBack={this.props.history.goBack}/>
                {this.viewController(this.state.activeView)}
            <LoadingOverlay show={this.state.loading} />
            <Bottombar from={this.props.location.pathname}/>
            </div>
        )
    }

}

export default withBmarkenAPI(withFirebase(Fiera))
