import React from 'react';

const BmarkenAPIContext = React.createContext(null);

export const withBmarkenAPI = Component => props => (
    <BmarkenAPIContext.Consumer>
        {bmarkenAPI => <Component {...props} bmarkenAPI={bmarkenAPI} />}
    </BmarkenAPIContext.Consumer>
);

export default BmarkenAPIContext;