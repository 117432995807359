import React from "react";
import LoadingOverlay from "../../common_components/LoadingOverlay";
import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";
import { withBmarkenAPI } from "../Api";
import { translateError } from "../utils";
import { setPrivacy, getPrivacy, validateEmail } from "../utils";
import { SharedContext } from ".";

const getDataObj = (user, path) => {
  const data = {
    authUser: user,
    path: path,
  };
  return data;
};

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    static contextType = SharedContext;

    constructor(props) {
      super(props);

      //se ho ancora le info salvate nel localstorage significa che non è
      //stato fatto un logout
      const refreshing = this.props.bmarkenAPI.checkIfLoggedIn();

      this.state = {
        data: {
          authUser: refreshing ? "refresh" : null,
          path: "/",
        },
        loading: false,
      };
    }

    componentDidMount() {
      /*
            this.listener = this.props.firebase.auth.onIdTokenChanged(
                authUser => {
                    let bmeLoginExecuted = this.props.bmarkenAPI.checkIfLoggedIn()
                    console.log("AUTHUSER: ", (authUser!==undefined && authUser!==null), " BME: ", bmeLoginExecuted)
                    authUser && bmeLoginExecuted
                        ? this.setState({ authUser })
                        : this.setState({ authUser: null });
                },
            );
            */

      const _this = this;
      this.listener = this.props.firebase.auth.onAuthStateChanged(function(
        user
      ) {
        //_this.setState({loading:true}) --> questo fa scattare un render che triggera il didmount dei componenti
        //in caso di logout da problemi

        let provider = null;
        if (user) provider = user.providerData["0"].providerId;

        if (user) {
          if (
            provider === "password" &&
            localStorage.getItem("StopListener") === "true"
          ) {
            //solo durante la creazione dell'account - non faccio nulla
            console.log("USER CREATO - bypass listener");
            return;
          }

          //esiste un utente loggato su firebase
          const bmeLoginExecuted = _this.props.bmarkenAPI.checkIfLoggedIn();
          if (bmeLoginExecuted) {
            //l'utente è già loggato anche su bme - FINE
            //controllo la privacy
            const privacy = getPrivacy();
            if (!privacy) {
              console.log("Privacy non settata");
              setPrivacy(false);
              _this.setState({
                data: getDataObj("privacy", "/"),
                loading: false,
              });
            } else {
              console.log("Privacy OK");
              setPrivacy(true);
              if (window.location.search) {
                let url = window.location.href;
                const parts = url.split("?");
                const params = parts[1];
                const decodedParams = decodeURIComponent(params);
                _this.setState({
                  data: getDataObj(user, decodedParams),
                  loading: false,
                });
              } else {
                _this.setState({ data: getDataObj(user, "/"), loading: false });
              }
            }
            return;
          }

          //loggo utente su BME

          console.log("Signed in user Firebase! Provider: ", provider);

          if (provider === "password") {
            const loginMerchant = localStorage.getItem("LoginMerchant");
            let loginMerchantConfirmed = false;
            if (
              loginMerchant !== undefined &&
              loginMerchant !== null &&
              loginMerchant
            ) {
              loginMerchantConfirmed = true;
            }

            if (
              user.email !== "admin.sposiclub@liberacta.com" &&
              !user.emailVerified &&
              !loginMerchantConfirmed &&
              // workaround per escludere il controllo emailVerified
              false
            ) {
              _this.props.firebase.doSignOut();
              _this.props.bmarkenAPI.logout();
              localStorage.setItem(
                "ListenerError",
                "Il tuo account risulta non ancora attivato, per farlo controlla la tua casella email e segui le istruzioni nella mail che ti è stata inviata"
              );
              _this.setState({ data: getDataObj(null, "/"), loading: false });
              return;
            } else {
              //provo login su BME
              _this
                .loginBmarken()
                .then((resp) => {
                  const token = resp.token;
                  const privacy = resp.privacy;
                  console.log("Loggato anche su BME! privacy: ", privacy);
                  _this.props.bmarkenAPI.saveToken(token);

                  //controllo la privacy
                  if (!privacy) {
                    console.log("Privacy non settata");
                    setPrivacy(false);
                    _this.setState({
                      data: getDataObj("privacy", "/"),
                      loading: false,
                    });
                  } else {
                    console.log("Privacy OK");
                    setPrivacy(true);
                    if (window.location.search) {
                      let url = window.location.href;
                      const parts = url.split("?");
                      const params = parts[1];
                      const decodedParams = decodeURIComponent(params);
                      _this.setState({
                        data: getDataObj(user, decodedParams),
                        loading: false,
                      });
                    } else {
                      _this.setState({
                        data: getDataObj(user, "/"),
                        loading: false,
                      });
                    }
                  }
                })
                .catch((err) => {
                  _this.props.firebase.doSignOut();
                  _this.props.bmarkenAPI.logout();
                  err = translateError(err);
                  localStorage.setItem("ListenerError", err.message);
                  _this.setState({
                    data: getDataObj(null, "/"),
                    loading: false,
                  });
                  return;
                });
            }
          } else {
            //provider social: controllo mail
            if (!validateEmail(user.email)) {
              console.log("Mail non fornita dal provider");
              user
                .delete()
                .then(() => {
                  console.log("Firebase user deleted");
                })
                .catch((error) => {
                  console.log("Firebase user NOT deleted");
                });

              _this.props.firebase.doSignOut();
              _this.props.bmarkenAPI.logout();
              localStorage.setItem(
                "ListenerError",
                "Il provider scelto per la login non ha fornito l'email. Per favore riprova e autorizza il provider a fornirci la tua email"
              );
              //_this.context.dispatchMessage("Il provider scelto per la login non ha fornito l'email. Per favore riprova e autorizza il provider a fornirci la tua email", 'error')
              _this.setState({ data: getDataObj(null, "/"), loading: false });
              return;
            }

            //provo login su BME
            _this
              .loginBmarken()
              .then((resp) => {
                const token = resp.token;
                const privacy = resp.privacy;
                console.log("Loggato anche su BME!");
                _this.props.bmarkenAPI.saveToken(token);

                //controllo la privacy
                if (!privacy) {
                  console.log("Privacy non settata");
                  setPrivacy(false);
                  _this.setState({
                    data: getDataObj("privacy", "/"),
                    loading: false,
                  });
                } else {
                  console.log("Privacy OK");
                  setPrivacy(true);
                  if (window.location.search) {
                    let url = window.location.href;
                    const parts = url.split("?");
                    const params = parts[1];
                    const decodedParams = decodeURIComponent(params);
                    _this.setState({
                      data: getDataObj(user, decodedParams),
                      loading: false,
                    });
                  } else {
                    _this.setState({
                      data: getDataObj(user, "/"),
                      loading: false,
                    });
                  }
                }
              })
              .catch((err) => {
                _this.props.firebase.doSignOut();
                _this.props.bmarkenAPI.logout();
                err = translateError(err);
                localStorage.setItem("ListenerError", err.message);
                _this.setState({ data: getDataObj(null, "/"), loading: false });
                return;
              });
          }
        } else {
          console.log("No user!");
          _this.setState({ data: getDataObj(null, "/"), loading: false });
        }
      });
    }

    loginBmarken = () => {
      const _this = this;
      let privacy = false;
      let rules = false;
      return new Promise(function(resolve, reject) {
        _this.props.firebase
          .doRefreshToken(false)
          .then((token) => {
            //chiamo signup Bmarken
            return _this.props.bmarkenAPI.signup(token);
          })
          .then((data) => {
            //tutto ok: refresho il token e lo salvo

            const customFields = JSON.parse(data.custom_fields);

            privacy = customFields.privacy;
            rules = customFields.rules;
            //console.log("DALLA LOGIN: privacy ", privacy, " rules ", rules);
            return _this.props.firebase.doRefreshToken(true);
          })
          .then((updatedToken) => {
            const resp = {
              token: updatedToken,
              privacy: privacy && rules,
            };
            resolve(resp);
            //this.props.bmarkenAPI.saveToken(updatedToken)
            //this.props.history.push({ pathname: ROUTES.profilo });
          })
          .catch((err) => {
            reject(err);
          });
      });
    };

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.data}>
          <LoadingOverlay show={this.state.loading} />
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withBmarkenAPI(withFirebase(WithAuthentication));
};

export default withAuthentication;
