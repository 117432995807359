import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import SocialIcons from '../common_components/SocialIcons'
import LogoHeader from '../common_components/LogoHeader'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { validateEmail } from '../constants'
import { fullMobileWidth, boldCenteredText, buttonStyle } from '../styles/helpers'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { translateError } from '../utils/utils'
import { ROUTES } from '../constants'
import { SharedContext } from '../utils/Session';

const ResetPassword = (props) => {
    const [email, setEmail] = useState('')
    const [submitLoading, setSubmitLoading] = useState(false)

    const { dispatchMessage } = useContext(SharedContext);

    const submitFields = async () => {
        setSubmitLoading(true)

        const emailIsValid = validateEmail(email)
        if(!emailIsValid){
            dispatchMessage('Formato email non corretto', 'error')
            setSubmitLoading(false)
            return
        }

        props.firebase.doPasswordReset(email)
        .then(() => {
            setSubmitLoading(false)
            dispatchMessage("Una mail è stata inviata all'indirizzo inserito. Segui le istruzioni in essa riportate per aggiornare la password", 'info')
        })
        .catch(error => {
            //NB: se ci sono errori per sicurezza sloggo user
            props.firebase.doSignOut()
            error = translateError(error)
            dispatchMessage(error.message, 'error')
            setSubmitLoading(false)
        })
    }
    

    return (
        <div style={{...boldCenteredText}}>
            <LogoHeader />
            <div style={{marginBottom: '25px'}}>Inserisci l'indirizzo email con cui ti sei registrato</div>
            <div style={{marginBottom: '25px', marginTop: '25px', fontSize:"12px"}}>Una mail contenente le istruzioni per il reset della password verrà inviata all'indirizzo inserito</div>
            <div >
                <TextField
                id="outlined-email-input"
                label="La tua email"
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                style={fullMobileWidth}
                onChange={(event) => setEmail(event.target.value)}    
                />
            </div>
            <Button onClick={submitFields} style={buttonStyle} variant="outlined" color="primary">
                Invia
            </Button>
            <br/> <br/>
            <Button onClick={() => props.history.push(ROUTES.login)} style={buttonStyle} variant="outlined" color="primary">
                Indietro
            </Button>
            <LoadingOverlay show={submitLoading} />
        </div>
    )
}

export default withFirebase(withBmarkenAPI(ResetPassword))