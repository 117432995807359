import app from 'firebase/app';
import 'firebase/auth';
import firebase from 'firebase';

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        app.auth().languageCode = 'it_IT';

        //https://firebase.google.com/docs/auth/web/auth-state-persistence
        app.auth().setPersistence(process.env.REACT_APP_FIREBASE_PERSISTANCE)
        .then(function() {
            console.log("Firebase persistance: "+process.env.REACT_APP_FIREBASE_PERSISTANCE)
        })
        .catch(function(error) {
            var errorCode = error.code;
            var errorMessage = error.message;
            console.error("Firebase persistance: ERROR")
            console.error("Code: "+errorCode)
            console.error("MESSAGE: "+errorMessage)
        });

        this.auth = app.auth();
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.googleProvider = new app.auth.GoogleAuthProvider();
    }

    // *** Auth API ***

    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doRefreshToken = (forceRefresh) => 
        this.auth.currentUser.getIdToken(forceRefresh);

    doSendEmailVerification = () => 
        this.auth.currentUser.sendEmailVerification();

    doSignOut = () => 
        this.auth.signOut();
        
    doPasswordReset = email => 
        this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    getRedirectResult = () => 
        this.auth.getRedirectResult()

    doSignInWithFacebook = () =>
        //this.auth.signInWithRedirect(this.facebookProvider);
        this.auth.signInWithPopup(this.facebookProvider);

    doSignInWithGoogle = () =>
        //this.auth.signInWithRedirect(this.googleProvider);
        this.auth.signInWithPopup(this.googleProvider);
    
    getTwitterAuthProvider = () => {
        return firebase.auth.TwitterAuthProvider
    }
    getGoogleAuthProvider = () => {
        return firebase.auth.GoogleAuthProvider
    }
    getAuth = () => {
        return this.auth
    }
}

export default Firebase;


/*
An alternate way to implement this is to specify a 
dedicated .env.development and .env.production file 
for both kinds of environment variables in 
your project. Each file is used to define 
environmental variables for the matching environment.
Defining a configuration becomes straightforward 
again, because you don’t have to select the 
correct configuration yourself.

*/