import { createMuiTheme } from '@material-ui/core/styles';

const sposiClubTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#e995b6',
      main: '#e30059',
      dark: '#9b023e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f2f2f2',
      main: '#8b8b8b',
      dark: '#333',
      contrastText: '#000',
    },
  },
});

export default sposiClubTheme