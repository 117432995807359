import React, { useState, useEffect } from "react";
import {
  fullMobileWidth,
  boldCenteredText,
  buttonStyle,
} from "../styles/helpers";
import ContactIcons from "../common_components/ContactIcons";
import ImagesCarousel from "../common_components/ImagesCarousel";
import Card from "@material-ui/core/Card";
//import Card from "../common_components/Card";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ActionButtons from "../common_components/ActionButtons";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import {
  getCompleteAddress,
  getUserInfo,
  translateError,
} from "../utils/utils";
import { SharedContext } from "../utils/Session";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { ROUTES } from "../constants";
import Recensioni from "../common_components/Recensioni";
import { Context } from "../utils/Session/sharedContext";
import { Link } from "react-router-dom";
import SposiIcon from "../common_components/SposiIcon";
import { useMediaQuery } from "@material-ui/core";
const GoogleMapsURL = "https://maps.google.com/?daddr={END_LAT},{END_LNG}";

function IconLink({ id, children, onClick }) {
  return (
    <Link
      style={{
        marginTop: "12px",
        textDecoration: "none",
        color: "#666",
        fontSize: 15,
      }}
    >
      <SposiIcon
        forceHeight={
          useMediaQuery((theme) => theme.breakpoints.up("sm")) ? "70px" : null
        }
        iconName={id}
        active={true}
        onClick={() => onClick()}
      />
      <div>{children}</div>
    </Link>
  );
}

class Negozio extends React.Component {
  static contextType = SharedContext;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      merchant: props.merchant,
      reviews: [],
      showInteractionsButtons: false,
      loginAlert: false,
    };
  }

  onChangeAction = (action) => {
    var url = new URL(window.location.href);
    let merchantID = url.searchParams.get("merchantID");
    if (!merchantID || merchantID === null || merchantID === "") {
      merchantID = this.props.merchant.id;
    }
    if (action === "PURCHASE") {
      this.props.history.push(ROUTES.acquisto + "/operatore/" + merchantID);
    } else if (action === "REVIEW") {
      this.props.history.push(ROUTES.recensione + "/operatore/" + merchantID);
    } else {
      this.props.history.push(ROUTES.visita + "/operatore/" + merchantID);
    }
  };

  componentDidMount() {
    const userInfo = getUserInfo();
    this.setState({ showInteractionsButtons: !!userInfo?.user_id });

    if (!this.state.merchant || this.state.merchant === null) {
      var url = new URL(window.location.href);
      var merchantID = url.searchParams.get("merchantID");

      if (merchantID && merchantID !== "") {
        this.setState({ loading: true });
        this.props.bmarkenAPI
          .getMerchant(merchantID, () => this.props.firebase.doRefreshToken())
          .then((m) => {
            this.setState({ merchant: m });
            return this.props.bmarkenAPI.getMerchantReviewsPublic(
              merchantID,
              "APPROVED"
            );
          })
          .then((reviews) => {
            if (reviews != null) {
              this.setState({ reviews: reviews, loading: false });
            } else {
              this.setState({ reviews: [], loading: false });
            }
          })
          .catch((error) => {
            this.setState({ loading: false });
            error = translateError(error);
            this.context.dispatchMessage(error.message, "error");
          });
      }
    } else {
      this.setState({ loading: true });
      this.props.bmarkenAPI
        .getMerchantReviewsPublic(this.props.merchant.id, "APPROVED")
        .then((reviews) => {
          if (reviews != null) {
            this.setState({ reviews: reviews, loading: false });
          } else {
            this.setState({ reviews: [], loading: false });
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  }

  render() {
    const merchant = this.props.merchant;
    //const addressValues = merchant.complete_address.split("|")

    //const address = addressValues[0].trim()
    //const city = addressValues[1].trim()
    //const prov = addressValues[3].trim()
    const location = merchant?.location;
    const address = location?.street;
    const city = location?.city;
    const prov = location?.province;

    const { categories } = this.context;
    const categoryName =
      categories.find((c) => c.id === merchant?.category)?.label || "";

    let showWhatsapp = false;
    let wn = merchant.whatsapp_phone;
    wn = wn.split(" ").join("");
    wn = wn.replace(".", "");
    wn = wn.trim();
    if (wn.startsWith("+393") || wn.startsWith("00393") || wn.startsWith("3")) {
      showWhatsapp = true;
    }

    const openWhatsapp = () => {
      let num = merchant.whatsapp_phone;
      num = num.replace(".", "");
      if (num.indexOf("+39") < 0) {
        num = "+39" + num;
      }

      window.open("whatsapp://send?phone=" + num, "_blank");
    };

    const makePhoneCall = () => {
      window.open("tel:" + merchant.mobile, "_blank");
    };

    const sendEmail = () => {
      if (getUserInfo()?.user_id) {
        this.props.history.push({
          pathname: ROUTES.contact_form + "/" + merchant.id,
        });
      } else {
        this.setState({ loginAlert: true });
      }
    };

    const viewWebpage = () => {
      window.open(merchant.web_url, "_blank");
    };

    const openInMaps = () => {
      const url = GoogleMapsURL.replace("{END_LAT}", merchant?.location?.lat).replace(
        "{END_LNG}",
        merchant?.location?.lng
      );
      window.open(url, "_blank");
    };

    const cardStyle = {
      margin: "3px",
      marginTop: "15px",
      marginBottom: "15px",
    };

    return (
      <React.Fragment>
        <div>
          <div style={{ ...fullMobileWidth }}>
            <div style={{ marginTop: "2vh" }}>
              <ImagesCarousel
                images={merchant?.media_contents?.filter(
                  (image) =>
                    image?.media_type === "image" &&
                    (!image?.media_subtype || image?.media_subtype !== "thumb")
                )}
              />
            </div>
            <div
              style={{
                fontSize: "23px",
                margin: "10px",
                color: "#dc005a",
                fontWeight: "bold",
              }}
            >
              {merchant.name.toUpperCase()}
            </div>
            <div
              style={{
                fontSize: "18px",
                margin: "0 10px",
                fontWeight: "bold",
                color: "#dc005a",
              }}
            >
              {categoryName}
            </div>
            <div
              style={{
                fontSize: "18px",
                margin: "0 10px",
              }}
            >
              {getCompleteAddress(merchant)}
            </div>
            <div
              style={{
                fontSize: "14px",
                margin: "10px 0 0 10px",
              }}
            >
              {this.state.reviews.length > 0 ? (
                <a
                  style={{
                    textDecoration: "underline",
                    color: "rgba(0, 0, 0, 0.54)",
                  }}
                  href="#btnrecensione"
                >
                  {this.state.reviews.length + " recensioni"}
                </a>
              ) : (
                <diV>{"Nessuna recensione"}</diV>
              )}
            </div>

            {/* <Card raised={true} style={cardStyle}>
            <CardContent>
              <ImagesCarousel images={merchant.images} />
            </CardContent>
          </Card>*/}

            {/* <Card raised={true} style={cardStyle}>
              <CardHeader
                title={
                  <div
                    style={{
                      color: "#dc005a",
                      fontWeight: "600",
                      fontSize: "18px",
                      marginTop: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    Contatti
                  </div>
                }
                subheader={
                  <div>
                    {address}
                    <br />
                    {city} ({prov})
                  </div>
                }
              />
              <CardContent>
                <ContactIcons
                  onWhatsappClick={openWhatsapp}
                  onTelClick={makePhoneCall}
                  onEmailClick={sendEmail}
                  onWebClick={viewWebpage}
                  onMapClick={openInMaps}
                  showWhatsapp={showWhatsapp}
                />
              </CardContent>
            </Card>*/}

            <Card style={{ marginTop: 10 }}>
              <CardContent>
                <div
                  /*className="smallGrid"*/ style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  {showWhatsapp && (
                    <IconLink id="whatsapp" onClick={() => openWhatsapp()}>
                      Whatsapp
                    </IconLink>
                  )}
                  <IconLink id="phonecall" onClick={() => makePhoneCall()}>
                    Chiama
                  </IconLink>
                  <IconLink id="contact_email" onClick={() => sendEmail()}>
                    Contatto
                  </IconLink>
                  <IconLink id="merchant_map" onClick={() => openInMaps()}>
                    Mappa
                  </IconLink>
                  <IconLink id="info" onClick={() => viewWebpage()}>
                    +Info
                  </IconLink>
                </div>
              </CardContent>
            </Card>

            <Card raised={true} style={cardStyle}>
              <CardContent>
                <div
                  style={{ marginTop: "25px", marginBottom: "20px" }}
                  dangerouslySetInnerHTML={{ __html: merchant.description }}
                />
              </CardContent>
            </Card>

            {this.state.showInteractionsButtons && (
              <Card raised={true} style={cardStyle}>
                {/*<CardHeader subheader="Ottieni questi punti con le tue interazioni" />*/}
                <CardHeader subheader="Accumula punti con questo fornitore" />
                <CardContent>
                  {/*  <ActionButtons
                    selected={null}
                    maxVisitReward={20}
                    maxPurcahseReward={100}
                    maxReviewReward={50}
                    onClickCallback={this.onChangeAction}
                  />*/}
                  <div
                    /*className="smallGrid"*/ style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <IconLink
                      id="visita"
                      onClick={() => this.onChangeAction("VISIT")}
                    >
                      VISITA
                      <br />
                      20 punti
                    </IconLink>
                    <IconLink
                      id="acquisto"
                      onClick={() => this.onChangeAction("PURCHASE")}
                    >
                      ACQUISTO
                      <br />
                      200 punti
                    </IconLink>
                    <IconLink
                      id="recensione"
                      onClick={() => this.onChangeAction("REVIEW")}
                    >
                      RECENSIONE
                      <br />
                      50 punti
                    </IconLink>
                  </div>
                </CardContent>
              </Card>
            )}

            <a name="btnrecensione"></a>
            <Recensioni data={this.state.reviews} />

            <LoadingOverlay show={this.state.loading} />
          </div>
        </div>
        <Dialog
          onClose={() => this.setState({ loginAlert: false })}
          open={this.state.loginAlert}
          maxWidth="sm"
        >
          <DialogContent>
            <Typography variant="h6">
              Per proseguire devi autenticarti
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                this.props.history.push({
                  pathname: ROUTES.login,
                  search:
                    "?" +
                    encodeURIComponent(ROUTES.contact_form + "/" + merchant.id),
                })
              }
            >
              Vai alla login
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => this.setState({ loginAlert: false })}
            >
              Annulla
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withBmarkenAPI(withFirebase(Negozio));
