import React from 'react'
import moment from 'moment'
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const Recensioni = (props) => {

  const RecensioniCard = () => (
    <Card raised={true} style={{margin: '3px'}}>
      <CardHeader
        title={
          <div style={{
              color: '#dc005a',
              fontWeight: '600',
              fontSize: '18px',
              marginTop: '20px',
              marginBottom: '15px',
          }}>
              Recensioni
          </div>
        }
      />
      <CardContent>
        <div>
          {props.data.map((el, i) => {
            const username = el.user_name && el.user_name.length > 0 ? el.user_name.split(" ")[0] : "sconosciuto"
              return(
                <div key={i} style={{marginBottom: '15px'}}>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: '600',
                    marginBottom: '5px',
                  }}>
                    <div style={{fontSize: '15px'}}>{username}</div>
                    <div style={{fontSize: '13px'}}>{moment(el.timestamp).format("DD/MM/YYYY")}</div>
                  </div>
                  <div style={{fontSize: '13px'}}>{el.comment}</div>
                </div>
              )
            })
          }
          </div>
      </CardContent>
    </Card>
  )

  return (
    props.data && props.data.length > 0
      ? <RecensioniCard/>
      /*:  <Card raised={true} style={{margin: '3px'}}>
          <CardHeader
            subheader="Non sono ancora state registrate recensioni per questo negozio"
          />
        </Card>*/
      : null
)}

export default Recensioni