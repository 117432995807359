import React from 'react'
import whiteLogo from '../styles/images/SposiClub_logo_white.svg';
import blackLogo from '../styles/images/SposiClub_logo.svg';

const LogoHeader = (props) => {
  return (
    <div style={{overflow: 'hidden', height: '150px'}}>
      <img
        style={{height: '355px', width: '250px', marginTop: '-110px'}}
        alt="logoSposiClub"
        src={props.white ? whiteLogo : blackLogo}
      />
    </div>
  )
}

export default LogoHeader