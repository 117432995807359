import React from "react";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { withBmarkenAPI } from "../utils/Api";
import { withFirebase } from "../utils/Firebase";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import { getUserInfo, translateError, validateFloat } from "../utils/utils";
import CardMedia from "@material-ui/core/CardMedia";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { SharedContext } from "../utils/Session";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { DatePicker } from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const INITIAL_STATE = {
  createOrUpdate: "create",
  title: "",
  description: "",
  location: "",
  dates: "",
  startingDate: moment(),
  expiration: moment(),
  uploadURL: "",
  imageURL: "",
  id: "",
  lat: 0.0,
  lng: 0.0,
  loading: false,
  file: null,
  file_preview: null,
  urlExpositors: "",
  urlMap: "",
  urlDirection: "",
  availableFairs: [],
  businessID: "",
};

function MediaCard(url) {
  return (
    <Card style={{ maxWidth: 580, textAlign: "center" }}>
      <CardActionArea>
        <CardMedia
          style={{ height: 200, width: 200, margin: "0 auto" }}
          image={url}
          title="image"
        />
      </CardActionArea>
    </Card>
  );
}

class FormFiera extends React.Component {
  static contextType = SharedContext;

  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleChangeSelect = (event) => {
    this.setState({ businessID: event.target.value });
  };

  componentDidMount() {
    //recupero i business di tipo FAIR per selezionarne uno da associare al content
    this.props.bmarkenAPI
      .getMerchantByType("Fair", () => {
        return this.props.firebase.doRefreshToken(true);
      })
      .then((fairs) => {
        if (fairs == null) fairs = [];
        this.setState({ loading: false, availableFairs: fairs });
      })
      .catch((error) => {
        this.setState({ loading: false });
        error = translateError(error);
        this.context.dispatchMessage(error.message, "error");
      });

    if (this.props.fair != null) {
      const f = this.props.fair;

      let cf = {
        urlExpositors: "",
        urlMap: "",
        urlDirection: "",
        lat: 0.0,
        lng: 0.0,
        location: "",
        dates: "",
        startingDate: moment().format("DD-MM-YYYY"),
      };
      if (f.custom_fields !== "") cf = JSON.parse(f.custom_fields);

      this.setState({
        id: f.id,
        title: f.title,
        location: cf.location,
        dates: cf.dates,
        description: f.description,
        lat: cf.lat,
        lng: cf.lng,
        startingDate:
          cf.startingDate === undefined
            ? moment()
            : moment(cf.startingDate, "DD-MM-YYYY"),
        expiration: moment(f.expiration_date),
        imageURL: f.url,
        createOrUpdate: "update",
        urlExpositors: cf.urlExpositors,
        urlMap: cf.urlMap,
        urlDirection: cf.urlDirection,
        businessID: f.business_id,
      });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getCFs = () => {
    const customFields = {
      lat: this.state.lat,
      lng: this.state.lng,
      location: this.state.location,
      dates: this.state.dates,
      startingDate: this.state.startingDate.format("DD-MM-YYYY"),
      urlExpositors: this.state.urlExpositors,
      urlMap: this.state.urlMap,
      urlDirection: this.state.urlDirection,
    };

    //console.log(JSON.stringify(customFields))

    return JSON.stringify(customFields);
  };

  validateFields = () => {
    if (this.state.title == "") {
      return "Inserire un nome per la fiera";
    }

    if (this.state.startingDate.isAfter(this.state.expiration)) {
      return "Le date di inizio e fine fiera non sono valide";
    }

    /*if(this.state.lat==="" || !validateFloat(this.state.lat)){
            return "Inserire una latitudine corretta per la fiera"
        }

        if(this.state.lng==="" || !validateFloat(this.state.lng)){
            return "Inserire una longitudine corretta per la fiera"
        }*/

    if (this.state.businessID === "") {
      return "Selezionare un operatore dalla lista operatori";
    }

    return null;
  };

  submit = (event) => {
    event.preventDefault();

    const err = this.validateFields();
    if (err !== null) {
      this.context.dispatchMessage(err, "error");
      return;
    }

    this.setState({ loading: true });
    if (this.state.createOrUpdate === "update" && this.state.file === null) {
      const { id, title, description, businessID } = this.state;
      const expiration = this.state.expiration.format("YYYY-MM-DD");

      let cf = this.getCFs();

      this.props.bmarkenAPI
        .updateContent(
          id,
          title,
          description,
          expiration,
          "5",
          "FAIR",
          cf,
          businessID,
          () => {
            return this.props.firebase.doRefreshToken(true);
          }
        )
        .then(() => {
          //ok
          this.setState({ loading: false });
          this.context.dispatchMessage("Azione eseguita con successo", "info");
          this.props.cancel();
        })
        .catch((error) => {
          this.setState({ loading: false });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    } else {
      let cf = this.getCFs();

      //recupero info prodotto
      this.props.bmarkenAPI
        .getUploadContentsUrl(() => {
          return this.props.firebase.doRefreshToken(true);
        })
        .then((c) => {
          let uploadURL = c.upload_url;

          let formData = new FormData();
          formData.append("file", this.state.file);
          formData.append("id", this.state.id);
          formData.append("business_id", this.state.businessID);
          formData.append("type", "FAIR");
          formData.append("priority", "5");
          formData.append("title", this.state.title);
          formData.append("custom_fields", cf);
          formData.append("description", this.state.description);
          formData.append(
            "expiration",
            this.state.expiration.format("YYYY-MM-DD")
          );
          return this.props.bmarkenAPI.uploadContentRequest(
            uploadURL,
            formData
          );
        })
        .then(() => {
          //ok
          this.setState({ loading: false });
          this.context.dispatchMessage("Azione eseguita con successo", "info");
          this.props.cancel();
        })
        .catch((error) => {
          this.setState({ loading: false });
          error = translateError(error);
          this.context.dispatchMessage(error.message, "error");
        });
    }
  };

  handleDateChange = (date) => {
    this.setState({ expiration: date });
  };

  handleCapture = ({ target }) => {
    const file = target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      this.setState({
        file_preview: e.target.result,
        file: file,
      });
    };
  };

  handleChangePriority = (event) => {
    this.setState({
      priority: event.target.value,
    });
  };

  render() {
    const enabled =
      this.state.createOrUpdate === "update" ||
      (this.state.title !== "" && this.state.file !== null);
    return (
      <main>
        <Paper>
          <form className="form-fiera" onSubmit={this.submit}>
            {this.state.createOrUpdate === "create" &&
            this.state.file_preview !== null ? (
              MediaCard(this.state.file_preview)
            ) : this.state.createOrUpdate === "create" &&
              this.state.file_preview == null ? (
              <Typography variant="subtitle1" gutterBottom>
                Carica un'immagine per vederne qui l'anteprima. Infine premi
                conferma per creare il contenuto
              </Typography>
            ) : this.state.createOrUpdate === "update" &&
              this.state.file_preview !== null ? (
              MediaCard(this.state.file_preview)
            ) : this.state.createOrUpdate === "update" &&
              this.state.file_preview === null ? (
              MediaCard(this.state.imageURL)
            ) : null}

            <TextField
              id="title"
              name="title"
              label="Titolo"
              value={this.state.title}
              onChange={this.handleChange}
              margin="normal"
            />
            <br />
            <TextField
              id="description"
              name="description"
              label="Descrizione"
              value={this.state.description}
              onChange={this.handleChange}
              multiline
              rows="4"
              margin="normal"
            />

            <br />
            <br />
            <FormControl>
              <InputLabel
                shrink
                id="demo-simple-select-placeholder-label-label"
              >
                Operatore
              </InputLabel>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                value={this.state.businessID}
                onChange={this.handleChangeSelect}
                displayEmpty
              >
                <MenuItem value="">
                  <em>Seleziona un operatore</em>
                </MenuItem>
                {this.state.availableFairs.map((f, i) => {
                  return (
                    <MenuItem value={f.id}>
                      {f.manager_email + " (" + f.name + ")"}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <br />
            <TextField
              id="urlMap"
              name="urlMap"
              label="URL mappa fiera"
              value={this.state.urlMap}
              onChange={this.handleChange}
              multiline
              rows="3"
              margin="normal"
            />
            <br />
            <TextField
              id="urlDirection"
              name="urlDirection"
              label="URL come raggiungere fiera"
              value={this.state.urlDirection}
              onChange={this.handleChange}
              multiline
              rows="3"
              margin="normal"
            />
            <br />
            <TextField
              id="urlExpositors"
              name="urlExpositors"
              label="URL lista espositori"
              value={this.state.urlExpositors}
              onChange={this.handleChange}
              multiline
              rows="3"
              margin="normal"
            />
            <br />
            <TextField
              id="location"
              name="location"
              label="Luogo"
              value={this.state.location}
              onChange={this.handleChange}
              margin="normal"
            />
            <br />
            <TextField
              id="dates"
              name="dates"
              label="Quando"
              value={this.state.dates}
              onChange={this.handleChange}
              margin="normal"
            />
            <br />
            {/*<TextField
                            id="lat"
                            name="lat"
                            label="Latitudine"
                            value={this.state.lat}
                            onChange={this.handleChange}
                            margin="normal"
                        />
<br/>
                        <TextField
                            id="lng"
                            name="lng"
                            label="Longitudine"
                            value={this.state.lng}
                            onChange={this.handleChange}
                            margin="normal"
                        />
                        <br/>*/}
            <DatePicker
              disablePast={true}
              format="DD/MM/YYYY"
              label="Valida dal"
              views={["year", "month", "date"]}
              value={this.state.startingDate}
              onChange={(data) => this.setState({ startingDate: moment(data) })}
              inputVariant="outlined"
              emptyLabel=""
              fullWidth={true}
              cancelLabel="cancella"
              style={{ marginTop: "15px" }}
            />
            <br />
            <DatePicker
              disablePast={true}
              format="DD/MM/YYYY"
              label="Valida fino al"
              views={["year", "month", "date"]}
              value={this.state.expiration}
              onChange={(data) => this.setState({ expiration: moment(data) })}
              inputVariant="outlined"
              emptyLabel=""
              fullWidth={true}
              cancelLabel="cancella"
              style={{ marginTop: "15px" }}
            />

            <br />
            <br />

            <input
              accept="image/*"
              style={{ display: "none" }}
              id="icon-button-photo"
              onChange={this.handleCapture}
              type="file"
            />

            <div className="admin-toolbar-btn">
              <Button type="button" variant="outlined" color="secondary">
                <label htmlFor="icon-button-photo">Seleziona Immagine</label>
              </Button>

              <Button
                disabled={!enabled}
                type="submit"
                variant="contained"
                color="primary"
              >
                Conferma
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.props.cancel()}
              >
                Annulla
              </Button>
            </div>
          </form>
        </Paper>
        <LoadingOverlay show={this.state.loading} />
      </main>
    );
  }
}

export default withFirebase(withBmarkenAPI(FormFiera));
