import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import "moment/locale/it";
import sposiClubTheme from "./styles";
import { ROUTES } from "./constants";
import MessageComponent from "./common_components/MessageComponent";
import {
  AuthUserContext,
  withAuthentication,
  SharedContextProvider,
} from "./utils/Session";
import { validateRoles, getUserInfo, translateError } from "./utils/utils";
import { withBmarkenAPI } from "./utils/Api";
import pages from "./pages";
import { Context } from "./utils/Session/sharedContext";

const App = (props) => {
  const [initialCategories, setInitialCategories] = useState([]);

  useEffect(() => {
    props.bmarkenAPI
      .getGenListCategories()
      .then((resp) => {
        if (!resp?.items) {
          resp = [];
        } else {
          resp = resp.items.map((c) => ({
            id: c.id,
            label: c.str_value,
            name: c.str_value,
          }));
        }
        setInitialCategories(resp);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const UnauthRouter = (props) => {
    return (
      <Router>
        <Switch>
          <Route exact path={ROUTES.accesso} component={pages.Accesso} />
          <Route
            exact
            path={ROUTES.operatore}
            component={pages.LoginOperatori}
          />
          <Route exact path={ROUTES.base} component={pages.Accesso} />
          <Route exact path={ROUTES.login} component={pages.Login} />
          <Route exact path={ROUTES.password} component={pages.ResetPassword} />
          <Route
            exact
            path={ROUTES.registrazione}
            component={pages.Registrazione}
          />
          <Route exact path={ROUTES.privacy_policy} component={pages.Privacy} />
          <Route
            exact
            path={ROUTES.regolamento}
            component={pages.Regolamento}
          />
          <Route
            exact
            path={ROUTES.termini_servizio}
            component={pages.TerminiServizio}
          />
          <Route
            exact
            path={ROUTES.come_funziona}
            component={pages.ComeFunziona}
          />
          <Route
            path={ROUTES.fornitori_public}
            component={pages.FornitoriPublic}
          />
          <Route exact path={ROUTES.fiera} component={pages.NewFiera} />
          <Route
            exact
            render={(routeProps) => (
              <pages.Notfound {...routeProps} user={props.user} />
            )}
          />
          exact
        </Switch>
      </Router>
    );
  };

  const AuthRouter = (props) => {
    if (validateRoles("M", getUserInfo().role, true)) {
      return MerchantAuthRouter(props);
    } else if (validateRoles("TM", getUserInfo().role, true)) {
      return SuperAdminAuthRouter(props);
    } else {
      return UserAuthRouter(props);
    }
  };

  const MerchantAuthRouter = (props) => (
    <Router>
      <Switch>
        <Route exact path={ROUTES.base} component={pages.HomeOperatore} />
        <Route exact path={ROUTES.operatore} component={pages.HomeOperatore} />
        <Route exact path={ROUTES.scansiona} component={pages.Scanner} />
        <Route exact path={ROUTES.assistenza} component={pages.Assistenza} />
        <Route
          exact
          path={ROUTES.assistenza_merchant}
          component={pages.AssistenzaMerchant}
        />
        <Route exact path={ROUTES.interazioni} component={pages.Interazioni} />
        <Route
          exact
          path={ROUTES.faq_merchant}
          component={pages.InfoOperatori}
        />
        <Route
          render={(routeProps) => (
            <pages.Notfound {...routeProps} user={props.user} />
          )}
        />
      </Switch>
    </Router>
  );

  const SuperAdminAuthRouter = (props) => (
    <Router>
      <Switch>
        <Route
          exact
          path={ROUTES.login}
          render={() => <pages.Redirect path={props.path} />}
        />
        <Route exact path={ROUTES.base} component={pages.HomeSuperadmin} />
        <Route
          exact
          path={ROUTES.gestione_fiere}
          component={pages.GestioneFiere}
        />
        <Route
          exact
          path={ROUTES.gestione_recensioni}
          component={pages.GestioneRecensioni}
        />
        <Route exact path={ROUTES.stats} component={pages.Statistiche} />
        <Route
          exact
          path={ROUTES.stats_users}
          component={pages.StatisticheUtenti}
        />
        <Route
          exact
          path={ROUTES.stats_merchants}
          component={pages.StatisticheOperatori}
        />
        <Route
          exact
          path={ROUTES.stats_prizes}
          component={pages.StatistichePremi}
        />
        <Route
          render={(routeProps) => (
            <pages.Notfound {...routeProps} user={props.user} />
          )}
        />
      </Switch>
    </Router>
  );

  const UserAuthRouter = (props) => (
    <Router>
      <Switch>
        <Route
          exact
          path={ROUTES.login}
          render={() => <pages.Redirect path={props.path} />}
        />
        <Route
          exact
          path={ROUTES.registrazione}
          render={() => <pages.Redirect path={props.path} />}
        />
        <Route exact path={ROUTES.base} component={pages.Home} />
        <Route path={ROUTES.visita} component={pages.Visita} />
        <Route exact path={ROUTES.fiera} component={pages.NewFiera} />
        <Route path={ROUTES.acquisto} component={pages.Acquisto} />
        <Route path={ROUTES.recensione} component={pages.Recensione} />
        <Route exact path={ROUTES.punti} component={pages.Punti} />
        <Route exact path={ROUTES.premi} component={pages.Premi} />
        <Route exact path={ROUTES.faq} component={pages.Faq} />
        <Route exact path={ROUTES.assistenza} component={pages.Assistenza} />
        <Route path={ROUTES.contact_form} component={pages.EmailContact} />
        <Route exact path={ROUTES.profilo} component={pages.Profilo} />
        <Route exact path={ROUTES.regolamento} component={pages.Regolamento} />
        <Route path={ROUTES.fornitori} component={pages.Fornitori} />
        <Route exact path={ROUTES.convenzioni} component={pages.Convenzioni} />
        <Route exact path={ROUTES.privacy_policy} component={pages.Privacy} />
        <Route
          exact
          path={ROUTES.termini_servizio}
          component={pages.TerminiServizio}
        />
        <Route
            path={ROUTES.fornitori_public}
            component={pages.FornitoriPublic}
          />
        <Route
          render={(routeProps) => (
            <pages.Notfound {...routeProps} user={props.user} />
          )}
        />

      </Switch>
    </Router>
  );

  const PrivacyRouter = (props) => {
    return (
      <Router>
        <Switch>
          <Route exact path={ROUTES.base} component={pages.Profilo} />
          <Route exact path={ROUTES.privacy_policy} component={pages.Privacy} />
          <Route
            exact
            path={ROUTES.regolamento}
            component={pages.Regolamento}
          />
          <Route
            exact
            path={ROUTES.termini_servizio}
            component={pages.TerminiServizio}
          />
          <Route
            exact
            path={ROUTES.login}
            render={() => <pages.Redirect path={props.path} />}
          />
          <Route
            exact
            path={ROUTES.registrazione}
            render={() => <pages.Redirect path={props.path} />}
          />
          <Route
            exact
            render={(routeProps) => (
              <pages.Notfound {...routeProps} user={props.user} />
            )}
          />
        </Switch>
      </Router>
    );
  };

  const RefreshRouter = (props) => {
    return (
      <Router>
        <Switch>
          <Route render={pages.Refresh} />
        </Switch>
      </Router>
    );
  };

  return (
    <ThemeProvider theme={sposiClubTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils} locale={"it"}>
        <AuthUserContext.Consumer>
          {(data) => {
            let authUser = null;
            let path = "/";
            if (data !== undefined) {
              authUser = data.authUser;
              path = data.path;
            }
            /*console.log(
              "aggiorno Router con AuthUser ",
              authUser,
              " Path: ",
              path
            );*/

            return authUser === "refresh" ? (
              <div>
                <SharedContextProvider
                  initialData={{ categories: initialCategories }}
                >
                  <MessageComponent />
                  <RefreshRouter />
                </SharedContextProvider>
              </div>
            ) : authUser === "privacy" ? (
              <div>
                <SharedContextProvider
                  initialData={{ categories: initialCategories }}
                >
                  <MessageComponent />
                  <PrivacyRouter path={path} user={authUser} />
                </SharedContextProvider>
              </div>
            ) : authUser && authUser !== null ? (
              <div>
                <SharedContextProvider
                  initialData={{ categories: initialCategories }}
                >
                  <MessageComponent />
                  <AuthRouter path={path} user={authUser} />
                </SharedContextProvider>
              </div>
            ) : (
              <div>
                <SharedContextProvider
                  initialData={{ categories: initialCategories }}
                >
                  <MessageComponent />
                  <UnauthRouter user={authUser} />
                </SharedContextProvider>
              </div>
            );
          }}
        </AuthUserContext.Consumer>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default withBmarkenAPI(withAuthentication(App));
