import React from 'react'
import { Link } from 'react-router-dom'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import BackGroundComponent from '../common_components/BackGroundComponent'
import { makeStyles } from '@material-ui/core/styles';
import { fullMobileWidth, boldCenteredText, buttonStyle } from '../styles/helpers'
import SposiIcon from '../common_components/SposiIcon'
import LogoHeader from '../common_components/LogoHeader'
import Button from '@material-ui/core/Button'
import { ROUTES } from '../constants';
import { colors } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const LinkWithIcon = (props) => {
  const linkTo = props.page;
  return(
    <div key="icon-link" style={{ display: 'flex', justifyContent: 'center', marginTop: '0px', cursor: 'pointer'}} >
      <Link to={`/${linkTo}`} style={{marginTop: '12px'}}>
        <SposiIcon forceHeight="55px" iconName={props.iconName} active={true}/>
      </Link>
      <Link to={`/${linkTo}`} style={{textDecoration: 'none'}}>
        <div style={{marginLeft: '15px', color: '#fff', padding: '3px 15px', marginTop: '13px', textAlign: 'left', width: '200px', border: '1px solid #ffffff', borderRadius: '10px'}}>
            <div style={{textTransform: 'uppercase', color: '#fff', textAlign: 'center'}}>{props.mainText}</div>
            <div style={{marginTop: '0px', color: '#fff', textAlign: 'center'}}>{props.subText}</div>
        </div>
      </Link>
    </div>
  )
}

const HomeSuperadmin = (props) => {
    const classes = useStyles();

    return (
    <div>
      <Topbar history={props.history} from={props.path} goBack={props.history.goBack}/>
      <BackGroundComponent background={'home'}/>
      <div key={'content'} style={{ textAlign: 'center', fontWeight: '600', marginBottom:'20px'}}>
        
        <LogoHeader white={true}/>
        <div key="title" style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#fff'}}>Gestisci le tue attività</div>
        <div style={{marginTop: '30px'}}>
          <Button onClick={() => props.history.push({ pathname: ROUTES.gestione_fiere })} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px', borderColor:"white", color:"white"}} variant="outlined" >
            Gestione Fiere
          </Button>
          <br/>
          <br/>
          <Button onClick={() => props.history.push({ pathname: ROUTES.gestione_recensioni })} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px', borderColor:"white", color:"white"}} variant="outlined" >
            Gestione Recensioni
          </Button>
          <br/>
          <br/>
          <Button onClick={() => props.history.push({ pathname: ROUTES.stats })} style={{...buttonStyle, marginTop: '25px', marginBottom: '20px', borderColor:"white", color:"white"}} variant="outlined" >
            Statistiche
          </Button>
          
        </div>
      </div>
    </div>
    );
}

export default HomeSuperadmin