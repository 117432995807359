import React from 'react'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import { makeStyles } from '@material-ui/core/styles';
import { fullMobileWidth, boldCenteredText } from '../styles/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const TerminiServizio = (props) => {
    const classes = useStyles();

    return (
    <div>
        <Topbar history={props.history} from={props.path} />
        <div>
            <div style={{ ...boldCenteredText, ...fullMobileWidth, fontWeight: 'normal', color: '#DC005A'}}>
                <div>Termini di servizio</div>
            </div>

            <div className={classes.root}>
              <div className="termini-servizio-text">
            <p>
            Per utilizzare il servizio è necessaria la connessione Internet (costi in base al piano tariffario del tuo operatore. Devi aver compiuto 18 anni di età: se non hai ancora compiuto 18 anni sei pregato di non usare questo servizio e di uscire dalla procedura.
              </p>
              <p>
1. VINCOLI D’USO: Con l’iscrizione alla piattaforma SposiClub la parte contraente (“Utente”) accetta di essere vincolato da questi Termini e Condizioni d’Uso Generale con l’esclusione di tutti i termini e le condizioni generali dell’Utente.
</p><p>
2. AZIENDE REFERENTI: SposiClub è un servizio erogato per conto di Emmebie srl, di seguito “Editore”, con sede in Torino – Italia, e registrata presso la Camera di Commercio di Torino, reso operabile tramite il supporto di LiberActa Srl, (di seguito “LiberActa”), con sede in Milano – Italia, e registrata presso la Camera di Commercio di Milano.
</p><p>
3. VINCOLO DI NULLITA’: La nullità o l’inefficacia di una o più clausole del presenti condizioni non determina la nullità delle restanti che rimarranno vincolanti.
</p><p>
4. MODIFICHE: L’Editore si riserva la facoltà di apportare modifiche al presente regolamento che verranno comunicate via email (o stesso contatto utilizzato per la registrazione al servizio) oppure con opportuna informativa al primo utilizzo di SposiClub e si intenderanno implicitamente accettate. La modifica delle condizioni è considerata accettata in assenza di opposizione scritta entro trenta giorni dal ricevimento delle condizioni generali modificate.
</p><p>
5. DESCRIZIONE DEL SERVIZIO: SposiClub è un servizio di promozione e fidelizzazione, informativo e promozionale che consente di ricevere e utilizzare coupon promozionali, giftcard e altri tipi di voucher promozionali e aderire a programmi di fidelizzazione con il proprio dispositivo mobile nei pubblici esercizi commerciali aderenti tramite l’applicazione scaricabile e utilizzabile gratuitamente dall’utente sul proprio dispositivo smartphone o tablet o tramite uno dei browser più diffusi utilizzati su un dispositivo PC.

SposiClub consente altresì di partecipare a giochi e concorsi a premi, di inviare commenti, di ricevere informazioni pubblicitarie e promozionali da parte dell’Editore o di terzi.

Il servizio è consentito solo ad utenti registrati.

6. REGISTRAZIONE: la registrazione dell’utente è condizione necessaria per l’utilizzo del servizio. La registrazione avviene tramite diversi canali, ad esempio attraverso il proprio account social (come Google, Facebook, Twitter, ...), attraverso email o tramite altro metodo ritenuto idoneo all’identificazione dell’utente.
</p><p>
7. PRIVACY: Con la registrazione l’utente prende visione delle norme della privacy a cui si rimanda per i dettagli e che sono sempre disponibili sula pagina indicata nel link riportato più avanti.
</p><p>
8. MODALITÀ DI UTILIZZO E RESPONSABILITÀ. L’utilizzo dell’applicazione deve essere limitato agli usi per cui è stato concepito e messo a disposizione da L’Editore. Ogni utilizzo diverso è espressamente vietato. L’applicazione si aggiunge e non sostituisce alcun servizio e pertanto L’Editore declina ogni responsabilità derivante dal non corretto funzionamento.

Poiché il corretto funzionamento dell’applicazione dipende anche dal funzionamento di servizi e prodotti forniti da società terze (es: browser utilizzato) si declina ogni responsabilità di malfunzionamento riconducibili a detti soggetti.

In particolare poiché il servizio si basa in parte su sistemi e/o reti di terzi (servizi di telecomunicazione), non è in grado di garantire la disponibilità ininterrotta del servizio.
</p><p>
9. PROPRIETÀ INTELLETTUALE. Il marchio SposiClub è di proprietà di Emmebie Srl, mentre l’applicazione che lo eroga sono di proprietà di LiberActa; entrambe le società si riservano la facoltà di tutelare in ogni sede i propri diritti. Ogni utilizzo dell’utente diverso dalle modalità previste nel presente regolamento è vietata all’utente.

Tutti i diritti di proprietà intellettuale restano di proprietà di Emmebie, che riceve dalle aziende presenti sulla piattaforma la liberatoria per l’utilizzo di contenuti soggetti a diritti d’autore, brevetti, marchi, disegni, registrati o non registrati, testi e grafica, foto , video, musica, suono. Il codice sorgente e il software (compresi applet e script) restano di proprietà di liberata.

Pertanto SposiClub non può essere riprodotto o ridistribuito o copiato, distribuito, ripubblicato, scaricato, visualizzato, pubblicato o trasmesso in qualsiasi forma o con qualsiasi mezzo, o in qualsiasi modo sfruttato senza la preventiva espressa autorizzazione scritta di Emmebie e LiberActa.
</p><p>
10. LEGGE APPLICABILE E FORO COMPETENTE: La legge applicabile al presente contratto è quella italiana e il foro competente per ogni e qualsiasi controversia è quello di Torino – Italia con espressa esclusione di ogni ulteriore foro.
</p>      
</div>
            </div>
            
        </div>
    </div>
    );
}

export default TerminiServizio