import React, { useEffect, useState, useContext } from "react";
import Topbar from "../common_components/Topbar";
import Bottombar from "../common_components/Bottombar";
import { getUserInfo, setUserInfo, checkUnauthError } from "../utils/utils";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import ImgNotFound from "../styles/images/img_not_found.png";
import { SharedContext } from "../utils/Session";
import { translateError } from "../utils/utils";
import { ROUTES } from "../constants";
import Button from "@material-ui/core/Button";
import { buttonStyle } from "../styles/helpers";
import { Link } from "react-router-dom";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { fullMobileWidth, centeredBoldParagraph } from "../styles/helpers";
import Card from "@material-ui/core/Card";
import BottombarMerchant from "../common_components/BottombarMerchant";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import moment from "moment";

var QRCode = require("qrcode.react");

const HomeOperatore = (props) => {
  const [business, setBusiness] = useState(null);
  const [view, setView] = useState("home");
  const [campaigns, setCampaigns] = useState([]);
  const [defaultImg, setDefaultImg] = useState(null);
  const [performance, setPerformance] = useState(null);
  const [loading, setLoading] = useState(false);
  const { dispatchMessage } = useContext(SharedContext);

  useEffect(() => {
    const bme = getUserInfo();
    setLoading(true);
    props.bmarkenAPI
      .getBusiness(bme.user_id, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then((bs) => {
        if (bs && bs.length > 0) {
          bme.business = bs[0].id;
          bme.business_name = bs[0].name;
          bme.business_type = bs[0].type;
          setUserInfo(bme);
          setBusiness(bs[0]);

          if (bs[0].images.length > 0) setDefaultImg(bs[0].images[0].image_url);
          else setDefaultImg(null);

          //cerco se ha delle campagne associate
          props.bmarkenAPI
            .getCampaigns(bme.business, () => {
              return props.firebase.doRefreshToken(true);
            })
            .then((campaigns) => {
              setLoading(false);
              if (campaigns !== null) {
                setCampaigns(campaigns);
              } else {
                setCampaigns([]);
              }
            })
            .catch((error) => {
              setLoading(false);
              if (
                checkUnauthError(
                  error.http_code,
                  props.firebase,
                  props.bmarkenAPI
                )
              )
                return;
              error = translateError(error);
              dispatchMessage(error.message, "error");
            });
        } else {
          setLoading(false);
          dispatchMessage(
            "Nessun negozio trovato associato a questo account! Contatta l'assistenza per risolvere il problema",
            "error"
          );
        }
      })
      .catch((error) => {
        setLoading(false);
        if (checkUnauthError(error.http_code, props.firebase, props.bmarkenAPI))
          return;
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  }, []);

  const cellStyle = {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "5px",
    borderBottom: "solid 1px",
    marginTop: "5px",
  };

  const getStats = (c) => {
    const bme = getUserInfo();
    props.bmarkenAPI
      .getCampaignPerformance(c.campaign_id, bme.business, () => {
        return props.firebase.doRefreshToken(true);
      })
      .then((performance) => {
        setPerformance(performance);
        setView("statsView");
      })
      .catch((error) => {
        setLoading(false);
        error = translateError(error);
        dispatchMessage(error.message, "error");
      });
  };

  const statsView = () => {
    return (
      <div>
        <Topbar
          history={props.history}
          from={props.path}
          goBack={() => {
            setView("prizeList");
          }}
        />
        <div style={{ ...fullMobileWidth, textAlign: "center" }}>
          {[
            { label: "Usati:", content: performance.used_qty },
            { label: "Emessi:", content: performance.issued_qty },
            {
              label: "Disponibili:",
              content: performance.issued_qty - performance.used_qty,
            },
          ].map((el) => (
            <div
              key={performance.id + el.label}
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: "13px",
                textAlign: "left",
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "85px", fontWeight: "bold" }}>
                {el.label}
              </div>
              <div style={{ width: "250px" }}>{el.content}</div>
            </div>
          ))}
        </div>
        <LoadingOverlay show={loading} />
      </div>
    );
  };

  const homeView = () => {
    return (
      <div>
        <Topbar
          history={props.history}
          from={props.path}
          goBack={props.history.goBack}
        />
        <div style={{ ...fullMobileWidth, textAlign: "center" }}>
          {business !== null ? (
            <div
              style={{
                ...centeredBoldParagraph,
                color: "#dc005a",
                marginTop: "25px",
              }}
            >
              {business.name}
            </div>
          ) : null}
          <br />

          {/*defaultImg !== null
                        ? (<img style={{ height: '200px', width: '300px' }} src={defaultImg} alt="main-image" />)
                        : (<img style={{ height: '200px', width: '300px' }} src={ImgNotFound} alt="no-image" />)
                    */}

          {business !== null ? (
            //mostro QRCode al posto della foto
            <QRCode
              value={"MERCHANT_" + business.id}
              size={200}
              bgColor="white"
              fgColor="black"
            />
          ) : null}

          <br />
          {business !== null ? (
            <div style={{ marginTop: "20px", padding: "5px" }}>
              <div style={cellStyle}>
                <div style={{ fontWeight: "bold" }}>Nome:</div>
                <div>{business.manager_name}</div>
              </div>
              <div style={cellStyle}>
                <div style={{ fontWeight: "bold" }}>Cellulare di contatto:</div>
                <div>{business.mobile}</div>
              </div>
              <div style={cellStyle}>
                <div style={{ fontWeight: "bold" }}>Email:</div>
                <div>{business.manager_email}</div>
              </div>
            </div>
          ) : null}

          <br />
          <Link to={ROUTES.interazioni} style={{ textDecoration: "none" }}>
            <Button
              style={{ ...buttonStyle, marginTop: "25px" }}
              variant="outlined"
              color="secondary"
            >
              Vedi le interazioni
            </Button>
          </Link>

          {/*
                        campaigns.length > 0
                        ?(
                            <div>
                                <br/>
                                <Button
                                    style={{...buttonStyle, marginTop: '25px'}}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => setView("prizeList")}
                                >
                                Le tue campagne
                                </Button>
                            </div>
                        ):null
                        */}
        </div>
        <BottombarMerchant from={props.location.pathname} />
        <LoadingOverlay show={loading} />
      </div>
    );
  };

  const prizeListView = () => {
    return (
      <div>
        <Topbar
          history={props.history}
          from={props.path}
          goBack={() => setView("home")}
        />
        <div style={{ ...fullMobileWidth, textAlign: "center" }}>
          {business !== null ? (
            <div
              style={{
                ...centeredBoldParagraph,
                color: "#dc005a",
                marginTop: "25px",
              }}
            >
              {business.name}
            </div>
          ) : null}
          <br />

          <main>
            <List>
              {campaigns.map((c) => {
                if (c.loop_campaign) {
                  return null;
                }

                return (
                  <Card key={c.id} style={{ margin: "20px" }}>
                    <CardContent>
                      {[
                        { label: "Nome:", content: c.name },
                        {
                          label: "Data scadenza:",
                          content: moment(c.expiration_date).format("DD-MM-YY"),
                        },
                      ].map((el) => (
                        <div
                          key={c.id + el.label}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "13px",
                            textAlign: "left",
                            marginBottom: "10px",
                          }}
                        >
                          <div style={{ width: "85px", fontWeight: "bold" }}>
                            {el.label}
                          </div>
                          <div style={{ width: "250px" }}>{el.content}</div>
                        </div>
                      ))}
                    </CardContent>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => getStats(c)}
                      >
                        Vedi Statistiche
                      </Button>
                    </div>
                  </Card>
                );
              })}
            </List>
          </main>
        </div>

        <LoadingOverlay show={loading} />
      </div>
    );
  };

  if (view === "prizeList") {
    return prizeListView();
  } else if (view === "statsView") {
    return statsView();
  } else {
    return homeView();
  }
};

export default withFirebase(withBmarkenAPI(HomeOperatore));
