import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { getCompleteAddress } from "../utils/utils";

const byParams = (a, b) => {
  if (a.priority < b.priority) {
    return 1;
  } else if (a.priority > b.priority) {
    return -1;
  } else {
    // Se priority è uguale, ordina per nome in ordine crescente
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    }
  }
};

const MerchantList = (props) => {
  return (
    <React.Fragment>
      <List>
        {props.items?.sort(byParams).map((item) => (
          <ListItem
            style={{ padding: "5px 0" }}
            key={item.id}
            button={props.button}
            divider
            onClick={() => props.onClick(item)}
          >
            {!props.list && (
              <React.Fragment>
                {item.media_contents?.find(
                  (image) =>
                    image.media_type === "image" &&
                    image.media_subtype === "thumb"
                ) ? (
                  <img
                    style={{
                      height: "60px",
                      marginRight: 10,
                    }}
                    src={
                      item.media_contents?.find(
                        (image) =>
                          image.media_type === "image" &&
                          image.media_subtype === "thumb"
                      )?.url
                    }
                  />
                ) : (
                  <div
                    style={{
                      height: "60px",
                      minWidth: "80px",
                      backgroundColor: "lightgrey",
                      marginRight: 10,
                    }}
                  />
                )}
              </React.Fragment>
            )}
            <ListItemText
              primary={<Typography color="primary">{item.name}</Typography>}
              secondary={<Typography>{getCompleteAddress(item)}</Typography>}
            />
            <ListItemIcon style={{ justifyContent: "flex-end" }}>
              <ArrowForward />
            </ListItemIcon>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  );
};

export default MerchantList;
