import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

export const Context = createContext({});

export const Provider = props => {
    const {
        children, initialData = {}
    } = props;
    
    // Use State to keep the values
    const [user, setUser] = useState({});
    const [messages, setMessages] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (initialData.categories) {
            setCategories(initialData.categories);
        }
    }, [initialData.categories]);
    
    const dispatchMessage = (text, type) => {
        const msg = {text: `${text}`, type: type}
        const newMessages = [...messages, msg]
        setMessages(newMessages)
    }

    // Make the context object:
    const sharedContext = {
        user,
        messages,
        categories,
        setUser,
        setMessages,
        setCategories,
        dispatchMessage,
    };

    // pass the value in provider and return
    return <Context.Provider value={sharedContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.propTypes = {
    user: PropTypes.object
};

Provider.defaultProps = {
    user: {},
};
