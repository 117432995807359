import React from 'react'

export function renderPage() {
  
    return {
      __html: `
      <style>


      @import url(https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans:400,600,700);	  
	  
      body {
       font-family: 'Open Sans', sans-serif !important;
       color:#8c8c8c;
	   -webkit-font-smoothing: antialiased !important;
       }
      
      .btn-info {
         color: #fff;
         background-color: #ea1b66 !important;
         border-color: #ea1b66 !important;
      }
	  
	  .btn{
		  padding:.395rem 1.55rem !important;
		  border-radius:.75rem !important;
		  }
            
      @media screen and (max-width: 299px) { /*page <= 299px, less than 300*/
          h2 {
              font-size: 35px;
          }
        h3 {
              font-size: 35px;
          }
        h4 {
              font-size: 30px;
          }
        h5 {
              font-size: 35px;
          }
        h6 {
              font-size: 20px;
          font-weight: normal;
          }
        p {
              font-size: 30px;
          }
        .click_webapp {
              font-size: 30px;
          }
        .logo_white {
          width:300px;
        }
        .logo_green {
          width:300px;
        }
      }

      @media screen and (min-width: 300px) and (max-width: 399px) { /*page is 300 to 399px*/
          h2 {
              font-size: 35px;
          }
        h3 {
              font-size: 35px;
          }
        h4 {
              font-size: 30px;
          }
        h5 {
              font-size: 35px;
          }
        h6 {
              font-size: 20px;
          font-weight: normal;
          }
        p {
              font-size: 30px;
          }
        .click_webapp {
              font-size: 30px;
          }
        .logo_white {
          width:300px;
        }
        .logo_green {
          width:300px;
        }
      }

      @media screen and (min-width: 400px) and (max-width: 1080px) { /*page is 400 to 1080px*/
          h2 {
              font-size: 40px;
          }
        h3 {
              font-size: 35px;
          }
        h4 {
              font-size: 30px;
          }
        h5 {
              font-size: 40px;
          }
        h6 {
              font-size: 20px;
          font-weight: normal;
          }
        p {
              font-size: 30px;
          }
        .click_webapp {
              font-size: 35px;
          }
        .logo_white {
          width:300px;
        }
        .logo_green {
          width:300px;
        }
      }

      @media screen and (min-width: 1081px) { /*page >= 1081 px*/
          h2 {
              font-size: 35px;
          }
        h3 {
              font-size: 19px;
          }
        h4 {
              font-size: 16px;
          }
        h5 {
              font-size: 30px;
          }
        h6 {
              font-size: 25px;
          font-weight: normal;
          }
        p {
              font-size: 16px;
          }
        .click_webapp {
              font-size: 35px;
          }
        .logo_white {
          width:600px;
        }
        .logo_green {
          width:600px;
        }
        .nascondi {
          position: absolute;
          left: -9999px;
      }
      }
	  
      </style>
     
       
<div>    

<div class="container-fluid" style="background-color:#f600a4;">
    <div class="row" style="background-color: rgba(37, 37, 37, 0.5);">
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align:center; overflow: hidden;"><a href="/"><img class="logo_white" style=" width: 375px; margin-top: 50px;
margin-bottom: 10px;" src="/static/cortesia/images/sposiclub-logo_white.png" /></a></div> 
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align:center; overflow: hidden;"><div style="color:#ea1b66; text-align:center; padding-top:15px; padding-bottom:25px;"><h2><strong>ORGANIZZA LE TUE NOZZE E VINCI!</strong></h2></div></div>
   
    </div>
</div>

       


<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important; text-align:center;">
<div class="container">
 <div class="row">
   <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:10px;"><img style="width: 75px; padding-top:25px; padding-bottom:10px;" src="/static/cortesia/images/comefunziona_sposi.png" />
     <h2 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Ti stai per sposare?</strong></h2>
 <h5>Utilizza Sposi Club <br />
   e vinci premi sicuri</h5>
     
 <div style="padding-top:25px; padding-bottom:25px;"><a href="https://youtu.be/aWvy44BO82Y" target="_blank" style="color:#ffffff;" class="btn btn-info click_webapp" role="button">Guarda come funziona</a></div> 
 
 </div>
   <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="padding-top:20px;padding-bottom:20px;"><img style="width: 75px; padding-top:5px; padding-bottom:10px;" src="/static/cortesia/images/comefunziona_aziende.png" />
     <h2 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Sei un'azienda?</strong></h2>
 <h5>Entra nel circuito SposiClub.it <br />
e ottieni molteplici vantaggi</h5>
 
 <div style="padding-top:25px; padding-bottom:25px;"><a href="https://youtu.be/FceVOAqJ-74" target="_blank" style="color:#ffffff;" class="btn btn-info click_webapp" role="button">Guarda come funziona</a></div> 
 
 </div>
 </div>
 </div>
 </div>       








<div class="container-fluid" style="background-color:#eeeeee;">
  <div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="text-align:center; background-image:url(static/cortesia/images/sfumatura.png); background-repeat: repeat-x;"><a href="/"><img class="logo_green" style="width: 375px;margin-top:50px; margin-bottom:35px;" src="/static/cortesia/images/sposiclub-logo.png" /></a></div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="margin-bottom:25px;">
  <div style="text-align:center;">
  <p style="text-transform: uppercase;">La <strong>WebApp</strong> che ti permette DI<br />
  vincere premi sicuri organizzando<br />
   il tuo matrimonio.<br /><br />
  
  <p><img style="padding-top:5px; padding-bottom:0px; width:50px;" src="/static/cortesia/images/separatore.png" /></p>
  <p>Visiti, scegli e fai recensioni sui tuoi operatori <br />
preferiti aderenti al circuito.</p>
  <p><img style="padding-top:10px; padding-bottom:0px; width:50px;" src="/static/cortesia/images/separatore.png" /></p>
  <p>Accumuli i punti.</p>
  <p><img style="padding-top:10px; padding-bottom:0px; width:50px;" src="/static/cortesia/images/separatore.png" /></p>
  <p>Li converti in premi sicuri.</p>      
  </div>
  </div>
  </div>
</div>


<div class="container-fluid">
  <div class="row">
  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="background-color:#ea1b66; text-align:center;"><h2 style="color:#fff; margin-top:20px; padding-bottom:15px;">FACILE, SICURO, GRATUITO.</h2></div>
  </div>
</div>    
   
  
<div class="container-fluid" style="padding-bottom:10px;padding-top:50px; background-color: #fff !important;">
    <div class="row">
    
     <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
      
     <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft01-2019.png" /></div>
                 
<div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><a name="comefunziona"></a>
        <h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Accedi <br />
con il tuo <br />
smartphone</strong></h3><br />
    <p >alla webapp all&rsquo;indirizzo <strong><a href="#">www.sposiclub.it</a></strong>. Inserisci i tuoi dati oppure utilizza l&rsquo;accesso con il tuo account social Facebook o Google.</p></div>
    
    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
      
    </div>
    </div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
      
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
      
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Scegli <br />
l&rsquo;interazione</strong></h3><br />
<p>che hai effettuato con un operatore: <strong>visita, recensione o acquisto</strong> per accumulare i tuoi punti. Potrai segnalare anche le <strong>visite in fiera</strong> ai fornitori convenzionati per incrementare i punti.</p></div>
       
      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft02-2019.png" /></div>
      
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

  </div>
</div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
    
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft03-2019.png" /></div>
                   
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Indica il <br />
fornitore</strong></h3><br />
<p><strong>con cui vuoi caricare i tuoi punti.</strong> Puoi scegliere se selezionarlo digitando il nome, consultando una mappa dei fornitori vicino a te oppure selezionandolo in base a categoria e localit&agrave;. &Egrave; facile e intuitivo.</p></div>

      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

    </div>
</div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
    
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
      
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Carica <br />
i tuoi punti</strong></h3><br />
<p>una volta giunto alla scheda del fornitore. Eseguita questa operazione i tuoi punti sono <strong>subito disponibili:</strong> il fornitore ricever&agrave; una notifica della tua azione e potr&agrave; annullarli se non coerenti con la realt&agrave;.</p></div>

      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft04-2019.png" /></div>

      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

    </div>
</div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
    
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft05-2019.png" /></div>
                   
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Raddoppia <br />
i tuoi punti</strong></h3><br />
<p>Una volta caricata l&rsquo;interazione (visita, acquisto o recensione), potrai generare il <strong>QR-code</strong> che, letto dal <strong>fornitore</strong> con la sua app, far&agrave; in modo che i tuoi punti vengano subito raddoppiati.</p></div>

    <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

    </div>
</div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
    
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
      
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Controlla <br />
il saldo punti</strong></h3><br />
<p>gi&agrave; quando avrai raggiunto la soglia dei <strong>1000 punti</strong> potrai riscattarli e ricevere uno dei tanti premi a disposizione, oppure continuare ad accumularli per ottenere un premio di maggior valore.</p></div>

      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft06-2019.png" /></div>

      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

    </div>
</div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
    
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft07-2019.png" /></div>             
      
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Scegli <br />
il premio</strong></h3><br />
<p>Puoi scegliere tra una gift-card Amazon oppure uno dei <strong>centinai di premi</strong> a disposizione, utilizzabili per il tuo matrimonio o per il tempo libero di coppia. Sono <strong>premi sicuri,</strong> non a estrazione.</p></div>

      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>

    </div>
</div>       <div style="padding-bottom:15px;padding-top:18px;"><hr width="75%" /></div>
<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #fff !important;">
    <div class="row">
    
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>
      
      <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7"><h3 style="color:#ea1b66; margin-top:15px; text-transform: uppercase;"><strong>Ottimizza <br />
le tue visite</strong></h3><br />
<p>Nella sezione dedicata alla ricerca degli operatori convenzionati, potrai reperirli con una <strong>mappa interattiva</strong> e ottenere le indicazioni stradali per raggiungerli o contattarli per telefono, e-mail o whatsapp.</p></div>
      
      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"><img class="img-fluid" style="text-align:left;" src="/static/cortesia/images/sposiclub-ft08-2019.png" /></div>
      
      <div class="col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1"></div>            

    </div>
</div>



<div style="padding-bottom:10px;padding-top:10px;"></div>



<div class="container-fluid">
    <div class="row">
    <p>&nbsp;</p>
      <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="background-color: #ea1b66; text-align:center;"><h2 style="color:#fff; margin-top:20px; padding-bottom:15px;"><strong>ECCO I PREMI CHE POTRAI VINCERE</strong></h2></div>
    </div>
</div>





<div class="container-fluid" style="background-color: #f600a4; text-align:center; color:#fff;">
    <div class="row" style="background-color: rgba(37, 37, 37, 0.5); padding-top:50px; padding-bottom:50px;"> 
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio001.png" /><h4 style="text-transform: uppercase;"><strong>Servizio Foto <br />
      in Studio</strong></h4><p>della durata <br />
      di 60 minuti</p></div>
      
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio02.png" /><h4 style="text-transform: uppercase;"><strong>Una Gift Card <br />
      Amazon.it</strong></h4><p>del valore <br />
      di 25,00 euro</p></div>


      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio03.png" /><h4 style="text-transform: uppercase;"><strong>Noleggio di un <br />
      Maggiolino VW</strong></h4><p>nel giorno <br />
      del matrimonio</p></div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio04.png" /><h4 style="text-transform: uppercase;"><strong>Abbonamento <br />
      per un anno</strong></h4><p>e 300 minuti <br />
      di utilizzo</p></div>


      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio05.png" /><h4 style="text-transform: uppercase;"><strong>Cena romantica <br />
      per 2 persone</strong></h4><p>presso <br />
      ristorante tipico</p></div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio06.png" /><h4 style="text-transform: uppercase;"><strong>Gift Card per auto <br />
      da cerimonia</strong></h4><p>da 100,00 euro <br />
      per un noleggio</p></div>

      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio07.png" /><h4 style="text-transform: uppercase;"><strong>Servizio foto <br />
      in studio</strong></h4><p>della durata <br />
      di 30 minuti</p></div>
      
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio08.png" /><h4 style="text-transform: uppercase;"><strong>Un massaggio <br />
      corpo</strong></h4><p>della durata <br />
      di 30 minuti</p></div>

      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio09.png" /><h4 style="text-transform: uppercase;"><strong>Un trattamento <br />
      estetico viso</strong></h4><p>della durata <br />
      di 60 minuti</p></div>
      
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 nascondi"><img class="img-fluid mx-auto d-block" style="margin-top:15px;" src="/static/cortesia/images/premio10.png" /></div>
      
    </div>
</div>




      

<div style="padding-bottom:10px;padding-top:10px;"></div>

<div style="width:100%; text-align:center; padding:10px;"><p>&nbsp;</p><h5>Non aspettare oltre, <strong>raccogli i tuoi <br />
punti e vinci premi sicuri</strong></h5><p>&nbsp;</p>
<p><a style="color:#fff; font-size:45px;" class="btn btn-info click_webapp" href="/">Accedi o Iscriviti ora</a></p><p>&nbsp;</p>
</div>


<div class="container-fluid" style="padding-bottom:10px;padding-top:10px; background-color: #eeeeee !important;">
    <div class="row">
<div class="text-center" style="margin-bottom:0; margin-top:20px; width:100%;">
  <h6 style="padding-left:25px; padding-right:25px;"><strong>Sposi Club &egrave; un marchio registrato.</strong><br />
   L&rsquo;utilizzo o la riproduzione, anche parziale, di testi e immagini contenute in queste pagine, &egrave; rigorosamente vietato e perseguibile a termini di legge.</h6>
  <h6 style="">Emmebie srl<br />
    Corso Moncalieri, 506/28 - 10133 Torino - Italy <br />
P.IVA 08325390014 - <a title="Legal &amp; privacy" href="https://www.emmebie.it/condizioni-di-utilizzo/" target="_blank">Legal &amp; privacy</a></h6></div></div>
</div>
</div>
       
      `,
    };
  }

class ComeFunziona extends React.Component {
    
    render(){
        return(
        <>
        <link rel="stylesheet" href="//maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" />
        <link rel="stylesheet" href="//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&amp;lang=en" />
        <link rel="stylesheet" href="/css/style_sposiclub-it" />
        <div dangerouslySetInnerHTML={renderPage()} />
        </>)
    }
}


export default  ComeFunziona