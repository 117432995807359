import React, { Component } from 'react'
import Autocomplete from '../common_components/Autocomplete'
import TextField from '@material-ui/core/TextField';
import Script from 'react-load-script';


class PlacesAutocomplete extends Component {

  state = {
    query: '',
  }

  initScript = () => {
    /*global google*/ // To disable any eslint 'google not defined' errors
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById(this.props.id),
      {types: ['(cities)'],},
    );
    this.autocomplete.setComponentRestrictions(
      {'country': ['it']});
    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
    this.autocomplete.setFields(['address_components','geometry']);
  }

  handlePlaceSelect = () => {
    const addressObject = this.autocomplete.getPlace();
    const lat = addressObject.geometry.location.lat()
    const lng = addressObject.geometry.location.lng()
    const address = addressObject.address_components;
    this.setState({query: address ? addressObject.formatted_address : ""});
    if (this.props.onSelected)
      this.props.onSelected(address ? address : null, lat ? lat : null, lng ? lng : null)

  }

  render() {
    const { currValue } = this.state
    return (
      <div>
        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyB1-i5dTH99rhtOgTLtom6V6SbIfBA38e4&libraries=places"
      onLoad={this.initScript}
    />  
      <TextField
        label={this.props.label}
        variant={this.props.variant}
        margin={this.props.margin}
        style={this.props.style}
        id={this.props.id}
        
      />
      </div>
    )
  }
}
 
export default PlacesAutocomplete;