import React, { useState, useContext } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import LogoHeader from "../common_components/LogoHeader";
import LoadingOverlay from "../common_components/LoadingOverlay";
import { validateEmail } from "../constants";
import {
  fullMobileWidth,
  boldCenteredText,
  buttonStyle,
} from "../styles/helpers";
import { withFirebase } from "../utils/Firebase";
import { withBmarkenAPI } from "../utils/Api";
import { translateError } from "../utils/utils";
import { Link } from "react-router-dom";
import { SharedContext } from "../utils/Session";

const Login = (props) => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [userError, setUserError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { dispatchMessage } = useContext(SharedContext);

  const submitFields = async () => {
    const passwordIsValid = password.length >= 8;
    const userIsValid = validateEmail(user);
    if (!passwordIsValid) setPasswordError(true);
    if (!userIsValid) setUserError(true);

    if (passwordIsValid && userIsValid) {
      setSubmitLoading(true);

      return (
        props.bmarkenAPI
          .signupMerchant(user, password)
          .then(() => {
            localStorage.setItem("LoginMerchant", true);
            return props.firebase.doSignInWithEmailAndPassword(user, password);
          })
          .then(() => {
            //deve scattare il listener
          })
          /*.then(() => {
                //recupero il token 
                console.log("2")
                return props.firebase.doRefreshToken(false)
            })
            .then((token) => {
                console.log("3")
                props.bmarkenAPI.saveToken(token)
            })
            .then(() => {
                //recupero il token 
                console.log("4")
                //setSubmitLoading(false)
                return props.firebase.doRefreshToken(true)
            })
            .then((newToken) => {
                props.bmarkenAPI.saveToken(newToken)
                //window.location.reload();
            })*/
          .catch((error) => {
            //NB: se ci sono errori per sicurezza sloggo user
            localStorage.removeItem("LoginMerchant");
            props.firebase.doSignOut();
            error = translateError(error);
            dispatchMessage(error.message, "error");
            setSubmitLoading(false);
          })
      );
    }
  };

  return (
    <div style={{ ...boldCenteredText }}>
      <LogoHeader />
      <div style={{ marginBottom: "25px" }}>
        Accedi con il tuo account SposiClub
      </div>
      <div>
        <TextField
          id="outlined-email-input"
          label="La tua email"
          type="email"
          name="email"
          autoComplete="email"
          margin="normal"
          variant="outlined"
          style={fullMobileWidth}
          onChange={(event) => setUser(event.target.value)}
          error={userError}
          onClick={() => setUserError(false)}
        />
      </div>
      <div>
        <TextField
          id="outlined-password-input"
          label="La tua password"
          autoComplete="current-password"
          margin="normal"
          variant="outlined"
          type="password"
          style={fullMobileWidth}
          onChange={(event) => setPassword(event.target.value)}
          error={passwordError}
          onClick={() => setPasswordError(false)}
        />
      </div>
      <Button
        onClick={submitFields}
        style={buttonStyle}
        variant="outlined"
        color="primary"
      >
        Invia
      </Button>

      <div
        style={{
          marginBottom: "10px",
          marginTop: "15px",
          fontWeight: "initial",
          fontSize: "15px",
        }}
      >
        Password dimenticata?
        <br />
        Clicca sul link sottostante per essere indirizzato sul portale
        GuidaSposi.it dove potrai resettare la tua password tramite l'apposito
        link <br />
      </div>
      <a
        href="https://www.guidasposi.it/backoffice-operatori#boxAccediBoo"
        target="_blank"
        style={{
          marginBottom: "25px",
          fontWeight: "initial",
          fontSize: "15px",
        }}
      >
        Vai su GuidaSposi.it
      </a>
      <LoadingOverlay show={submitLoading} />
    </div>
  );
};

export default withFirebase(withBmarkenAPI(Login));
