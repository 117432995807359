

import React, { useState, useContext, useEffect } from 'react'
import Topbar from '../common_components/Topbar'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { SharedContext } from '../utils/Session';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ROUTES } from '../constants';
import Card from '@material-ui/core/Card';
import { centeredText, centeredBoldParagraph, buttonStyle } from '../styles/helpers';
import { translateError, parseBmeIntToInt, setUserInfo } from '../utils/utils'
import iconUser from '../styles/images/icon-user.png'
import { withBmarkenAPI } from '../utils/Api';
import Button from '@material-ui/core/Button';
import { withFirebase } from '../utils/Firebase';
import BottombarMerchant from '../common_components/BottombarMerchant'
import TablePagination from '@material-ui/core/TablePagination';
import ActionsTable from '../common_components/ActionsTable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment'
import PrizeTable from '../common_components/PrizeTable';
import ConfirmPopUp from '../common_components/ConfirmPopUp'


const StatistichePremi = (props) => {

    const [prizeStat, setPrizeStat] = useState(null)
    const [selectedUser, setSelectedUser] = useState(null)
    const [actions, setActions] = useState([])
    const [openConfirm, setOpenConfirm] = useState(false)
    const [view, setView] = useState("main")
    const [count, setCount] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0)
    const [orderBy, setOrderBy] = useState("-Received")
    const { dispatchMessage } = useContext(SharedContext);
    const [submitLoading, setSubmitLoading] = useState(false)
    const [userPrizes, setUserPrizes] = useState([])
    const [points, setPoints] = useState(0)

    const getData = (p, r, o) => {
        setSubmitLoading(true)

        props.bmarkenAPI.getPrizeStats(p, r, o, () => {
            return props.firebase.doRefreshToken(true)
        }).then((prizeStat) => {
            if (prizeStat && prizeStat.total_prizes > 0) {
                setPrizeStat(prizeStat)
                setCount(prizeStat.total_prizes)
                setSubmitLoading(false)
            } else {
                setSubmitLoading(false)
                dispatchMessage("Nessun Premio trovato!", 'error')
            }

        })
            .catch(error => {
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
    }


    useEffect(() => {
        setSubmitLoading(true)
        getData(page, rowsPerPage, orderBy)

    }, [])

    const download = () => {
        setSubmitLoading(true)
        props.bmarkenAPI.downloadPrizeStats(() => props.firebase.doRefreshToken())
            .then((s) => {
                setSubmitLoading(false)
            })
            .catch(error => {
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
    }

    const changeStatus = (action, userID) => {
        setSubmitLoading(true)
        props.bmarkenAPI.changeUserStatus(userID, action, () => props.firebase.doRefreshToken())
            .then(() => {
                setSubmitLoading(false)
                setView("main")
                getData(page, rowsPerPage, orderBy)
                dispatchMessage("Azione eseguita con successo", 'info')
            })
            .catch(error => {
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
    }

    const getWelcomeRow = (createdAt) => {

        const row = {
            "action_date": createdAt,
            "business_name": "SposiClub.it",
            "created_at": createdAt,
            "id": "welcome-0000",
            "status": "CONFIRMED",
            "type": "GIFT",
            "reward": 1000,
        }

        return row
    }

    const showActions = (userId) => {
        let user
        props.bmarkenAPI.getUser(userId, () => {
            return props.firebase.doRefreshToken(true)
        })
            .then(u => {
                user = u
                return props.bmarkenAPI.getAllRewardingActions(u.id, () => props.firebase.doRefreshToken())
            })
            .then((actions) => {
                if (actions && actions !== null) {
                    //aggiungo l'interazione finta di benvenuto con 10 punti
                    actions.push(getWelcomeRow(user.created_at))
                    setActions(actions)
                } else {
                    let actions = []
                    actions.push(getWelcomeRow(user.created_at))
                    setActions(actions)
                }

                return props.bmarkenAPI.getProducts(user.id, () => props.firebase.doRefreshToken())
            })
            .then((products) => {
                let card = null
                let userPrizes = []

                products.map((product, index) => {
                    if (product.default) {
                        card = product
                    } else {
                        userPrizes.push(product)
                    }
                })
                setSelectedUser(user)
                setPoints(card !== null ? parseBmeIntToInt(card.available_value) : 0)
                setUserPrizes(userPrizes)
                setView("actions")
                setSubmitLoading(false)
            })
            .catch(error => {
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
    }

    const checkIfEnabled = (date) => {
        const created = moment(date);
        const today = moment();
        return today.diff(created, 'days') > 7
    }

    const getIconColor = (status, date) => {
        let color;
        const isEnabled = checkIfEnabled(date)

        if (status == 0 && isEnabled) {
            color = 'orange'
        } else if (status == 0 && !isEnabled) {
            color = 'red'
        } else if (status == 3) {
            color = 'green'
        } else {
            color = 'red'
        }

        return color
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        getData(newPage, rowsPerPage, orderBy)
    };

    const handleChangeRowsPerPage = event => {
        const newRowsPerPage = parseInt(event.target.value, 10)
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        getData(page, newRowsPerPage, orderBy)
    };

    const handleChangeOrderBy = (event) => {
        setOrderBy(event.target.value)
        setPage(0);
        getData(0, rowsPerPage, event.target.value)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    if (view === "actions") {
        const changeStatusLabel = selectedUser.status == 0 ? "Disabilita Utente" : "Abilita Utente"
        const changeStatusMessage = selectedUser.status == 0 ? "disabilitare" : "abilitare"
        const action = selectedUser.status == 0 ? "DISABLE" : "ENABLE"
        return (
            <div>
                <Topbar history={props.history} goBack={() => setView("main")} />
                <div className="main-content" style={centeredText}>
                    <br />
                    <div className='col-user'>
                        <span className='col-user-img'>
                            <img style={{ width: 90, height: 90 }} src={selectedUser.picture ? selectedUser.picture : iconUser} />
                        </span>
                        <span className='col-user-name'>{selectedUser.complete_name + " " + selectedUser.last_name}</span>
                        <span className='col-user-name'>{"(" + selectedUser.email + ")"}</span>
                    </div>
                    <Button onClick={() => setOpenConfirm(true)} style={{ ...buttonStyle, marginTop: '25px', marginBottom: '20px' }} variant="outlined" color="primary">
                        {changeStatusLabel}
                    </Button>
                    <PrizeTable prizes={userPrizes} />
                    <br /><br />
                    <ActionsTable showQRCode={null} title={null} actions={actions} />
                    <ConfirmPopUp
                        open={openConfirm}
                        onClose={() => setOpenConfirm(false)}
                        onConfirm={() => changeStatus(action, selectedUser.id)}
                        text={"Vuoi " + changeStatusMessage + " l'accesso e l'utilizzo dell'applicazione all'utente " + selectedUser.email + "?"}
                        title="Conferma"
                    />
                </div>
            </div>
        )
    } else {
        return (

            <div>
                <Topbar history={props.history} goBack={() => props.history.push(ROUTES.base)} />
                <div style={centeredText}>
                    <div style={{ ...centeredBoldParagraph, color: "#9CA1A1", marginBottom: '10px', marginTop: '10px' }}>
                        Statistiche Premi
                    </div>

                    <Grid
                        justify="space-between"
                        container
                    >
                        <Grid item>
                            <FormControl>
                                <InputLabel id="demo-simple-select-helper-label">Ordina per</InputLabel>
                                <Select
                                    id="select-orderby"
                                    value={orderBy}
                                    onChange={handleChangeOrderBy}
                                >
                                    <MenuItem value={"Email"}>Email (crescente)</MenuItem>
                                    <MenuItem value={"-Email"}>Email (decrescente)</MenuItem>
                                    <MenuItem value={"Prize"}>Nome Premio (crescente)</MenuItem>
                                    <MenuItem value={"-Prize"}>Nome Premio (decrescente)</MenuItem>
                                    <MenuItem value={"Received"}>Data richiesta (crescente)</MenuItem>
                                    <MenuItem value={"-Received"}>Data richiesta  (decrescente)</MenuItem>
                                    <MenuItem value={"Status"}>Stato (crescente)</MenuItem>
                                    <MenuItem value={"-Status"}>Stato  (decrescente)</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item>
                            <div>
                                <Button onClick={() => download()} style={{ ...buttonStyle, marginTop: '25px', marginBottom: '20px' }} variant="outlined" color="primary">
                                    Scarica Report
                                </Button>
                            </div>
                        </Grid>
                    </Grid>

                    <div className="legenda-punti">
                        <div><span className="info-circle green"></span> Premi utilizzati</div>
                        <div><span className="info-circle orange"></span> Premi Utilizzabili</div>
                        <div><span className="info-circle red"></span> Premi non ancora utilizzabili</div>
                    </div>

                    <Card style={{ marginTop: '25px', paddingLeft: '5px', paddingRight: '5px', overflow: 'scroll' }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Nome</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Premio</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Data richiesta</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Utilizzabile dal</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {prizeStat != null && prizeStat.prizes.map(p => {
                                    return (
                                        <TableRow onClick={() => showActions(p.user_id)} key={p.prize_id} style={{ cursor: 'pointer' }} hover={true}>
                                            <TableCell align="right"><span className={'info-circle ' + getIconColor(p.status, p.prize_received_date)}></span></TableCell>
                                            <TableCell>{p.user_name + " " + p.user_last_name}</TableCell>
                                            <TableCell>{p.user_email}</TableCell>
                                            <TableCell>{p.prize_name}</TableCell>
                                            <TableCell>{moment(p.prize_received_date).format('DD-MM-YY')}</TableCell>
                                            <TableCell>{moment(p.prize_usable_date).format('DD-MM-YY')}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={4} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Card>
                    <LoadingOverlay show={submitLoading} />
                </div>
                <BottombarMerchant from={props.location.pathname} />
            </div>
        )
    }
}

export default withFirebase(withBmarkenAPI(StatistichePremi))