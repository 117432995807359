import React from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
import MarkerIcon from '../styles/images/Marker.svg'

class SearchMapMarker extends React.Component {



    render() {
      return (
      <div className={"marker"} tabIndex="1" onClick={() => this.props.onClickCallback(this.props.merchant)}>
        <div className={"markerbox"}>
          <h3>{this.props.merchant.name}</h3>
        </div>
        <div className={"markerpin"} style={{ backgroundImage: `url(${MarkerIcon})`}} ></div>
      </div>
      )
  }
}

export default SearchMapMarker