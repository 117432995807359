import React, { useState } from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment'
import { fullMobileWidth, centeredBoldParagraph, buttonStyle, centeredText } from '../styles/helpers'
import { parseBmeIntToInt, parseBmeIntToFloat, translateAction, traslateStatus } from '../utils/utils';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';


const PrizeTable = (props) => {

    const [view, setView] = useState("main")


    const checkIfEnabled = (date) => {
        const created = moment(date);
        const today = moment();
        return today.diff(created, 'days') > 7
    }


    const getIconColor = (status, date) => {
        let color;
        const isEnabled = checkIfEnabled(date)

        if (status == 0 && isEnabled) {
            color = 'orange'
        } else if (status == 0 && !isEnabled) {
            color = 'red'
        } else if (status == 3) {
            color = 'green'
        } else {
            color = 'red'
        }

        return color
    }

    const mainView = () =>
        <div style={fullMobileWidth}>
            <Card style={{ marginTop: '25px', paddingLeft: '5px', paddingRight: '5px', overflow: 'scroll' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Premio</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Campagna</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Ricevuto il</TableCell>
                            <TableCell style={{ fontWeight: 'bold' }}>Utilizzabile dal</TableCell>
                        </TableRow>
                    </TableHead>
                    {
                        props.prizes.length > 0
                            ? (
                                <TableBody>
                                    {props.prizes.map(p => {
                                        return (
                                            <TableRow key={p.id} hover={true}>
                                                <TableCell align="right"><span className={'info-circle ' + getIconColor(p.status, p.created_at)}></span></TableCell>
                                                <TableCell>{p.business_name}</TableCell>
                                                <TableCell>{p.campaign_name}</TableCell>
                                                <TableCell>{moment(p.created_at).format('DD-MM-YY')}</TableCell>
                                                <TableCell>{moment(p.created_at).add(8, 'days').format('DD-MM-YY')}</TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            )
                            : (
                                <TableBody>
                                    <TableRow >
                                        <TableCell colSpan={4}>Non sono ancora stati richiesti premi</TableCell>
                                    </TableRow>
                                </TableBody>
                            )
                    }
                </Table>
            </Card>

            <div className="legenda-punti">
                <div><span className="info-circle green"></span> Premi utilizzati</div>
                <div><span className="info-circle orange"></span> Premi Utilizzabili</div>
                <div><span className="info-circle red"></span> Premi non ancora utilizzabili</div>
            </div>
        </div>

    switch (view) {
        default: return mainView()
    }
}

export default PrizeTable;