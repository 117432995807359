import React, { Fragment, useState } from "react"
import { DatePicker } from "@material-ui/pickers"
import * as moment from 'moment'

function BasicDatePicker(props) {
  const [selectedDate, handleDateChange] = useState(props.value)

  const changeDataAndCallback = (value) => {
    handleDateChange(value)

    //const formattedValue = moment(value).format('YYYY-MM-DD')

    if (props.onChange)
      props.onChange(moment(value))
  }

  return (
    <Fragment>
      <div>
        <DatePicker
          disableFuture={true}
          format="DD/MM/YYYY"
          label={props.label}
          views={["year", "month", "date"]}
          value={selectedDate}
          onChange={changeDataAndCallback}
          inputVariant="outlined"
          emptyLabel=""
          fullWidth={true}
          cancelLabel="cancella"
        />
      </div>
    </Fragment>
  );
}

export default BasicDatePicker