import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// ESEMPIO DI UTILIZZO:
//   <ConfirmPopUp
//     open={true}
//     onConfirm={() => console.log('prova')}
//     text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sed ullamcorper morbi tincidunt ornare massa eget. Ullamcorper malesuada proin libero nunc consequat interdum varius. Lectus sit amet est placerat. Hendrerit gravida rutrum quisque non tellus orci ac. Sollicitudin tempor id eu nisl nunc. Sodales ut eu sem integer vitae. Sem integer vitae justo eget magna fermentum iaculis. Tristique nulla aliquet enim tortor at auctor urna nunc. Semper auctor neque vitae tempus. Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel. Adipiscing commodo elit at imperdiet dui accumsan. Massa tempor nec feugiat nisl pretium fusce. Duis ut diam quam nulla porttitor massa id. Ac turpis egestas integer eget. Sapien faucibus et molestie ac feugiat.<br/><br/>
//     Sit amet venenatis urna cursus eget nunc."
//     title="Titolo di prova"
//   />

export default function ConfirmPopUp (props) {
  const [open, setOpen] = React.useState(props.open);

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const handleConfirm = () => {
    if (props.onConfirm) props.onConfirm()
    //setOpen(false) deve essere chiuso da "fuori" altrimenti non si riapre più
    props.onClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={()=>props.onClose()}
      >
        {props.title && <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.text}
          </DialogContentText>
        </DialogContent>
          <div style={{display: 'flex', padding: '15px', justifyContent: 'space-between'}}>
            <Button onClick={()=>props.onClose()} color="primary">
              Annulla
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Conferma
            </Button>
          </div>
      </Dialog>
    </div>
  );
}