import React from 'react'
import moment from 'moment'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import LogoHeader from '../common_components/LogoHeader'
import { centeredText, fullMobileWidth, boldCenteredText } from '../styles/helpers'
import { ROUTES } from '../constants'
import conversioniIMG from '../styles/images/convenzioni'

const mockData = [
  {title: 'Convenzione Uno', description: 'descrizione della prima convenzione, testo di prova', expiration_date: 1565528966895, url: conversioniIMG.conv1},
  {title: 'Convenzione Due', description: 'descrizione della seconda convenzione, testo di prova', expiration_date: 1565527966895, url: conversioniIMG.conv1},
  {title: 'Convenzione Tre', description: 'descrizione della terza convenzione, testo di prova', expiration_date: 1565526966895, url: conversioniIMG.conv1},
  {title: 'Convenzione Quattro', description: 'descrizione della quarta convenzione, testo di prova', expiration_date: 1565525966895, url: conversioniIMG.conv1}
]

const Convenzioni = (props) => {
  const [clickedElement, setClickedElement] = React.useState(undefined)

  const goBack = () => {
    if (clickedElement) {
      setClickedElement(undefined)
      return
    }
    props.history.push({ pathname: ROUTES.base });
  }

  const HandleMainView = () => (
    <div>
      <LogoHeader />
      {mockData.length > 0
        ? <Convenzioni/>
        : <Nodata/>}
    </div>
  )

  const Nodata = () => (
    <div>Siamo spiacenti, in questo momento non ci sono convenzione attive</div>
  )

  const Convenzioni = () => (
    <div>
      <div style={{...boldCenteredText, color: '#dc005a', marginBottom: '15px'}}>CONVENZIONI PER GLI UTENTI</div>
      <div style={{padding: '5px'}}>
        {mockData.map((el, index) => (
          <div key={`conv-${index}`} onClick={() => setClickedElement(el)}>
            <div>
              <img src={el.url} width="100%"/>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{...boldCenteredText, color: '#dc005a', marginBottom: '35px'}}>{el.title}</div>
                <div>Scade: {moment(el.expiration_date).format('DD/MM/YY')}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const SingleView = (props) => (
    <div style={{padding: '5px'}}>
      <div style={{...boldCenteredText, color: '#dc005a', marginBottom: '15px'}}>{props.element.title}</div>
      <img src={props.element.url} width="100%"/>
      <div style={{marginTop: '25px'}}>
        {props.element.description}
      </div>
    </div>
  )

  const viewController = () => {
    switch (clickedElement) {
      case undefined:
        return <HandleMainView />
      default:
        return <SingleView element={clickedElement}/>
    }
  }

  return (
    <div style={centeredText}>
      <Topbar history={props.history} from={props.path} goBack={goBack}/>
        <div style={{...fullMobileWidth}}>
          {viewController()}
        </div>
      <Bottombar from={props.location.pathname}/>
    </div>
  )
}

export default Convenzioni