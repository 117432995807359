import React, { useState, useContext, useEffect } from 'react'
import Topbar from '../common_components/Topbar'
import LoadingOverlay from '../common_components/LoadingOverlay'
import { SharedContext } from '../utils/Session';
import ActionsTableMerchant from '../common_components/ActionsTableMerchant';
import { setUserInfo, getUserInfo } from '../utils/utils';
import { ROUTES } from '../constants';
import { centeredText } from '../styles/helpers';
import {translateError} from '../utils/utils'
import { withBmarkenAPI } from '../utils/Api';
import { withFirebase } from '../utils/Firebase';
import BottombarMerchant from '../common_components/BottombarMerchant'

const Interazioni = (props) => {

    const [actions, setActions] = useState([])
    const { dispatchMessage } = useContext(SharedContext);
    const [submitLoading, setSubmitLoading] = useState(false)

    const getActions = (businessID, status) => {
        setSubmitLoading(true)
        props.bmarkenAPI.getBusinessRewardingAction(businessID, status, () => {
            return props.firebase.doRefreshToken(true)
        })
        .then(actions => {
            if(actions != null)
                setActions(actions)
            else
                setActions([])

            setSubmitLoading(false)
        })
        .catch(error => {
            setSubmitLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    useEffect(() => {
        
        const bme = getUserInfo()
        const businessID = bme.business
        //console.log("BUSINESS ID: ", businessID)

        if(businessID && businessID!==""){

            getActions(businessID, "")
        
        }else{
            setSubmitLoading(true)
            props.bmarkenAPI.getBusiness(bme.user_id, () => {
                return props.firebase.doRefreshToken(true)
            })
            .then((bs) => {
                if(bs.length > 0){
                    bme.business = bs[0].id
                    bme.business_name = bs[0].name
                    setUserInfo(bme)
                    setSubmitLoading(false)
                    getActions(bme.business, "")
            
                }else{
                    setSubmitLoading(false)
                    dispatchMessage("Nessun negozio trovato associato a questo account! Contatta l'assistenza per risolvere il problema", 'error')
                }

            })
            .catch(error => {
                setSubmitLoading(false)
                error = translateError(error)
                dispatchMessage(error.message, 'error')
            })
        }
    }, [])

    const confirmAction = (idAction) => {
        setSubmitLoading(true)
        const bme = getUserInfo()
        props.bmarkenAPI.confirmAction("",idAction, bme.business, () => props.firebase.doRefreshToken())
        .then(() => {
            setSubmitLoading(false)
            getActions(bme.business, "")
            dispatchMessage("Azione confermata con successo", 'info')
        })
        .catch(error => {
            setSubmitLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }

    const cancelAction = (idAction) => {
        setSubmitLoading(true)
        const bme = getUserInfo()
        props.bmarkenAPI.cancelAction(idAction, bme.business, () => props.firebase.doRefreshToken())
        .then(() => {
            setSubmitLoading(false)
            getActions(bme.business, "")
            dispatchMessage("Azione confermata con successo", 'info')
        })
        .catch(error => {
            setSubmitLoading(false)
            error = translateError(error)
            dispatchMessage(error.message, 'error')
        })
    }


    return (
        <div>
			<Topbar history={props.history} goBack={() => props.history.push(ROUTES.base)}/>
			<div style={centeredText}>
				
				<ActionsTableMerchant approveAction={confirmAction} cancelAction={cancelAction} actions={actions}/>
                <LoadingOverlay show={submitLoading} />
			</div>
            <BottombarMerchant from={props.location.pathname} />
		</div>
    )
}

export default  withFirebase(withBmarkenAPI(Interazioni))