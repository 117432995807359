import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Topbar from '../common_components/Topbar'
import Bottombar from '../common_components/Bottombar'
import {CheckIfDateIsGONull, getPrivacy, setPrivacy, validateMobileNumber} from '../utils/utils'
import { fullMobileWidth, boldCenteredText, buttonStyle } from '../styles/helpers'
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import LoadingOverlay from '../common_components/LoadingOverlay'
import {translateError} from '../utils/utils'
import { withFirebase } from '../utils/Firebase';
import { withBmarkenAPI } from '../utils/Api'
import { DatePicker } from "@material-ui/pickers"
import SelectFromOptions from '../common_components/SelectFromOptions'
import { PROVINCIE } from '../constants'
import { SharedContext } from '../utils/Session';
import PersonIcon  from '@material-ui/icons/Person';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { Link } from 'react-router-dom'
import { ROUTES, validateEmail } from '../constants'
import EditIcon from '@material-ui/icons/Edit';
const moment = require('moment');

const Regolamento = () => (
<div>Dichiaro di avere letto e accettato il
	<Link to={ROUTES.regolamento} target="_blank" style={{color: '#8b8b8b'}}> REGOLAMENTO</Link> e i
	<Link to={ROUTES.termini_servizio} target="_blank" style={{color: '#8b8b8b'}}> TERMINI DI UTILIZZO DEL SERVIZIO</Link>
</div>
)

const Privacy = () => (
<div>Dichiaro di avere letto e accettato le norme che regolano il
	<Link to={ROUTES.privacy_policy} target="_blank" style={{color: '#8b8b8b'}}> TRATTAMENTO DEI DATI PERSONALI (PRIVACY)</Link>
</div>
)

const Marketing = () => (
<div> 
	Acconsento al trattamento dei dati personali per finalità di marketing dirette da parte del Circuito, o degli esercenti a cui appartengono le promo e gli strumenti di fidelizzazione utilizzati, o da terze parti.
</div>
)

class Profilo extends React.Component {

	static contextType = SharedContext

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			activeView: 'main',
			privacyFromProfile: false,
			file: null,
			file_preview: null,

			nome: "",
			cognome: "",
			telefono: "",
			dataNozze: null,
			provinciaNozze: "",
			privacy: false,
			regolamento: false,
			marketing: false,
			picture:"",
			email: "",
		};
	}

	componentDidMount() {
		this.setState({ loading: true })
		this.props.bmarkenAPI.getUserLogged(() => this.props.firebase.doRefreshToken())
		.then(user => {
			let customFields = JSON.parse(user.custom_fields);
			const u = {
				nome: user.complete_name,
				cognome: user.last_name,
				telefono: user.mobile,
				dataNozze: !CheckIfDateIsGONull(new Date(customFields.wedding_date)) ? new Date(customFields.wedding_date) : null,
				provinciaNozze: customFields.wedding_province,
				privacy: customFields.privacy,
				marketing: customFields.marketing,
				regolamento: customFields.rules,
				email: user.email,
				picture: user.picture,
				profileCompleted: customFields.profile_complete
			}

			const privacyChecked = getPrivacy()
			
			if(u.provinciaNozze !== ""){
				const l = PROVINCIE.length
				for(let i=0; i<l; i++){
					if(PROVINCIE[i].value === u.provinciaNozze){
						u.provinciaNozze = PROVINCIE[i]
						break;
					}
				}
			}
			//console.log(u)

			this.setState({ 
				privacyFromProfile: privacyChecked,
				loading: false,
				nome: u.nome,
				cognome: u.cognome,
				telefono: u.telefono,
				dataNozze: u.dataNozze,
				provinciaNozze: u.provinciaNozze,
				privacy: u.privacy,
				regolamento: u.regolamento,
				marketing: u.marketing,
				email:u.email,
				picture: u.picture,
				profileCompleted: u.profileCompleted,
			})
		})
		.catch(error => {
			this.setState({loading: false })
			error = translateError(error)
			this.context.dispatchMessage(error.message, 'error')
		})
	}

	onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
	};

	handleCaptureImage = ({ target }) => {

        const file = target.files[0]
        const fileReader = new FileReader();

        fileReader.readAsDataURL(file);
        fileReader.onload = (e) => {
            this.setState({
                file_preview: e.target.result,
                file: file
            })
        };
	};
	
	handleCheckbox = (name, event) => {
		this.setState({
			[name]: event.target.checked
		})
	}

	saveProfilePicture = () => {
		if(this.state.file !== null){
			let imageURL
			this.setState({loading: true })
			this.props.bmarkenAPI.getProfilePictureUploadUrl(() => this.props.firebase.doRefreshToken())
			.then(url => {
				imageURL = url
				let formData = new FormData();
				formData.append("file", this.state.file);

				return this.props.bmarkenAPI.uploadPictureRequest(url, formData)
			})
			.then(() => {
				this.setState({picture: imageURL})
				this.setState({loading: false, activeView: 'main' })
				if(!this.state.profileCompleted){
					this.setState({profileCompleted: true})
					this.context.dispatchMessage('Ti sono stati accreditati 50 punti per aver completato il tuo profilo!', 'info')
				}
			})
			.catch(error => {
				this.setState({loading: false })
				error = translateError(error)
				this.context.dispatchMessage(error.message, 'error')
			})
		}
	}

	saveProfile = () => {
		this.setState({loading: true })
		let {nome, cognome, telefono, email, dataNozze, provinciaNozze, privacy, regolamento, picture, marketing} = this.state

		if(nome === "" || cognome === ""){
			this.setState({loading: false })
			this.context.dispatchMessage('Nome e cognome sono obbligatori', 'error')
			return
		}

		if(telefono === "" || dataNozze === null || provinciaNozze === ""){
			this.setState({loading: false })
			this.context.dispatchMessage('Numero di cellulare, Data nozze e Provincia nozze sono obbligatori', 'error')
			return
		}

		telefono = telefono.trim();
		if (telefono.startsWith("+39") || telefono.startsWith("0039")) {
			this.setState({loading: false })
			this.context.dispatchMessage('Devi immettere il numero di cellulare senza il prefisso internazionale italiano', 'error')
				  return
		}
		if (telefono.startsWith("+") || telefono.startsWith("00")) {
			this.setState({loading: false })
			this.context.dispatchMessage('Il numero di cellulare non è corretto. Attenzione i numeri con prefisso internazionale non sono accettati', 'error')
				  return
		}
		if(telefono !== ""){
			if(!validateMobileNumber(telefono)){
				this.setState({loading: false })
				this.context.dispatchMessage('Il numero di cellulare è sbagliato. Ricorda: non sono ammessi il prefisso +39, né spazi, parentesi o trattini', 'error')
				return
			}
		}

		if(provinciaNozze !== ""){
			provinciaNozze = provinciaNozze.value
		}

		dataNozze = dataNozze !== null ? moment(dataNozze).format('YYYY-MM-DD') : null

		//deve checkare termini e privacy
		if (!privacy || !regolamento || !marketing) {
			this.setState({loading: false })
			this.context.dispatchMessage('Per procedere è necessario accettare il regolamento e le norme sulla privacy e acconsentire al trattemento dei dati per finalità di marketing spuntando le tre caselle in basso', 'error')
			return
		}

		this.props.bmarkenAPI.completeSignup(null, nome, cognome, telefono, dataNozze, provinciaNozze, privacy, regolamento, marketing)
		.then(() => {
			this.setState({loading: false })
			this.context.dispatchMessage("Profilo aggiornato con successo", 'info')
			if(!this.state.privacyFromProfile){
				setPrivacy(true)
				window.location.href = ROUTES.base
			}
		})
		.catch(error => {
			this.setState({loading: false })
			error = translateError(error)
			this.context.dispatchMessage(error.message, 'error')
		})
	}
	
	profileView = () => {
		return (
			<div>
			<Grid container justify="center" alignItems="center">
			{
				this.state.picture === ""
				?(
					<div>
						<div>
							<Avatar 
								alt="foto-profilo"
								onClick={() => this.setState({activeView: 'picture', file: null, file_preview: null})}
								style={{margin: 10,width: 150,height: 150, color: '#fff'}}
							>
								<PersonIcon style={{ fontSize: 120 }}/>
							</Avatar>
						</div>
						<div>
							<Button
								onClick={() => this.setState({activeView: 'picture', file: null, file_preview: null})}
								color="primary"
								style={{fontWeight: 'bold'}}
								>
								<EditIcon  style={{ fontSize: 18 }} />
								Carica foto
							</Button>
						</div>
					</div>
				)
				:(
					<div>
						<div>
							<Avatar 
								onClick={() => this.setState({activeView: 'picture', file: null, file_preview: null})}
								alt="foto-profilo" 
								src={this.state.picture}
								style={{margin: 10,width: 120,height: 120}}
							/>
						</div>
						<div>
							<Button
								onClick={() => this.setState({activeView: 'picture', file: null, file_preview: null})}
								color="primary"
								style={{fontWeight: 'bold'}}
								>
								<EditIcon  style={{ fontSize: 18 }} />
								Carica foto
							</Button>
						</div>
					</div>
				)
			}
		</Grid>

			<div style={{marginTop: '5px', marginBottom: '20px'}}>
				Carica la tua fotografia per ottenere 50 punti bonus
			</div>

			<div>
				<div >
					<TextField
					id="outlined-name-input"
					label="Nome"
					name="nome"
					margin="normal"
					variant="outlined"
					style={fullMobileWidth}
					value={this.state.nome}
					onChange={this.onChange}
					/>
				</div>
				<div >
					<TextField
					id="outlined-surname-input"
					label="Cognome"
					name="cognome"
					margin="normal"
					variant="outlined"
					style={fullMobileWidth}
					value={this.state.cognome}
					onChange={this.onChange}
					/>
				</div>
				<div>
					<TextField
					id="outlined-phone-input"
					label="Numero di cellulare"
					name="telefono"
					margin="normal"
					variant="outlined"
					style={fullMobileWidth}
					value={this.state.telefono}
					onChange={this.onChange}
					/>
				</div>
				{/* *************** data e provincia ********************* */}
				<div style={fullMobileWidth}>
					

					<DatePicker
						disablePast={true}
						format="DD/MM/YYYY"
						label={"Data delle nozze"}
						views={["year", "month", "date"]}
						value={this.state.dataNozze}
						onChange={(data) => this.setState({dataNozze: data})}
						inputVariant="outlined"
						emptyLabel=""
						fullWidth={true}
						cancelLabel="cancella"
						/>
				</div>
				<div >
					<SelectFromOptions
						value={this.state.provinciaNozze}
						placeholder={'Digita il nome di una provincia'}
						label={'provincia in cui organizzi le nozze'}
						onChange={(e) => this.setState({provinciaNozze: e})}
						options={PROVINCIE}
					/>
				</div>
				{/* *************** privacy e regolamento ********************* */}
				<FormControlLabel
					control={
						<Checkbox
						checked={this.state.regolamento}
						onChange={(e) => this.handleCheckbox("regolamento",e)}
						value="regolamento"
						color="primary"
						/>
					}
					label={<Regolamento/>}
				/>
				<FormControlLabel
					control={
						<Checkbox
						checked={this.state.privacy}
						onChange={(e) => this.handleCheckbox("privacy",e)}
						value="privacy"
						color="primary"
						/>
					}
					label={<Privacy/>}
				/>
				<div style={{...fullMobileWidth, margin:'auto', textAlign: 'left'}}>
				<FormControlLabel
				control={
					<Checkbox
					checked={this.state.marketing}
					onChange={(e) => this.handleCheckbox("marketing",e)}
					value="merketing"
					color="primary"
					/>
				}
				label={<Marketing/>}
				/>
			</div>
			</div>
			
			<div>
				<Button onClick={() => this.saveProfile()} style={{...buttonStyle, marginTop: '15px', marginBottom: '20px'}} variant="outlined" color="primary">
				Salva
				</Button>
			</div>
		
		</div>
		)
	}

	pictureView = () => {
		return(
			<div>
				<Grid container justify="center" alignItems="center">
				{
					this.state.picture === "" && this.state.file_preview === null
					?(
						<Avatar 
							alt="foto-profilo"
							style={{margin: 10,width: 150,height: 150, color: '#fff'}}
						>
							<PersonIcon style={{ fontSize: 120 }}/>
						</Avatar>
					)
					: this.state.file_preview !== null
					? (
						<Avatar
							alt="foto-profilo" 
							src={this.state.file_preview}
							style={{margin: 10,width: 120,height: 120}}
						/>
					)
					: (
						<Avatar 
							alt="foto-profilo" 
							src={this.state.picture}
							style={{margin: 10,width: 120,height: 120}}
						/>
					)
				}
				</Grid>
				<div>
					<input
						accept="image/*"
						style={{display: 'none'}}
						id="icon-button-photo"
						onChange={this.handleCaptureImage}
						type="file"
					/>

					<Button
						type="button"
						fullWidth
						variant="outlined"
						style={{...buttonStyle, width:'100%', marginTop: '25px', marginBottom: '15px'}} 
						color="primary"
					>
						<label htmlFor="icon-button-photo">
							Seleziona Immagine
						</label>
					</Button>
				</div>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<Button disabled={this.state.file===null} onClick={() => this.saveProfilePicture()} style={{...buttonStyle, width:'45%', marginTop: '25px', marginBottom: '100px'}} variant="outlined" color="primary">
						Salva
					</Button>
					<Button onClick={() => this.setState({activeView: 'main'})} style={{...buttonStyle, width:'45%', marginTop: '25px', marginBottom: '100px'}} variant="outlined" color="secondary">
						Annulla
					</Button>
				</div>
				<div>
				</div>
			</div>
		)
	}

	viewController = (activeView) => {
		switch (activeView) {
		case 'picture':
			return this.pictureView()
		default:
			return this.profileView()
		}
	}

	render() {
		return (
			<div>
				<Topbar history={this.props.history} from={this.props.path} goBack={this.props.history.goBack}/>
				<div style={{...fullMobileWidth, ...boldCenteredText}}>
					{this.viewController(this.state.activeView)}
				</div>
				<LoadingOverlay show={this.state.loading} />

				{
					this.state.privacyFromProfile
					?(<Bottombar from={this.props.path}/>)
					:(null)
				}
				
			</div>
		)
	}
}


export default  withFirebase(withBmarkenAPI(Profilo))